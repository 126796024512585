.bbBreakdownLegend {
  margin-top: 12px;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  .bbBreakdownLegendComments {
    display: flex;
    justify-content: center;

    .bbComments {
      margin-top: 2px;
    }
  }

  .bbBreakdownLegendSentiments {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-flow: wrap;

    .bbLegendItemWrapper {
      display: flex;
      align-items: center;
    }

    div[class*="bbLegend"] {
      min-width: 16px;
      min-height: 16px;
      border-radius: var(--border-radius-sm);
    }

    .bbLegendNegative {
      margin-right: 8px;
      background-color: var(--color-red-700);
    }

    .bbLegendPositive {
      margin-right: 8px;
      background-color: var(--color-green-600);
    }

    .bbLegendUserTags {
      margin-right: 8px;
      background-color: var(--color-blue-900);
    }

    .bbLegendNeutral {
      margin-right: 8px;
      background-color: var(--color-grey-300);
    }

    .bbLegendNotApplicable {
      margin-right: 8px;
      background: linear-gradient(
        135deg,
        #dddfe6 25%,
        #f9f9fa 25%,
        #f9f9fa 50%,
        #dddfe6 50%,
        #dddfe6 75%,
        #f9f9fa 75%,
        #f9f9fa 100%
      );
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;