.bbCommentExternalActionsTrigger {
  width: 100%;
  justify-content: center;
  margin-left: 0;
  padding: 3px 10px !important;
  font-size: var(--font-size-lg) !important;

  span {
    color: var(--color-primary-1);
  }
}

.bbAssignUserTrigger {
  width: 100%;
  background: white;
  justify-content: center;
  color: var(--color-on-primary-btn);

  &:hover {
    background: var(--color-secondary-2);
    border-color: var(--color-secondary-2);
  }

  & > * {
    fill: var(--color-on-primary-btn);
    color: var(--color-on-primary-btn);
  }
}

.bbConversationActions {
  margin-bottom: 20px;

  .bbConversationActionsWrapper {
    display: flex;
    flex-direction: column;

    .bbConversationActionAssignUser {
      display: block;
      margin-bottom: 12px;

      span[class*="bbAssignUserTriggerIcon--"] {
        svg {
          fill: var(--color-primary-1);
        }
      }
    }
  }
}

.bbConversationActionsTitle {
  padding-top: 16px;
  padding-bottom: 12px;
  font-weight: bold;
  font-size: var(--font-size-lg);
}

.bbConversationActionWrapper {
  display: flex;
  flex: 1 1 0%;
  margin-bottom: 12px;
}

.bbMessageActionButtonClass {
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--color-primary-850);
  box-shadow: var(--shadow-sm-1);
  border: 1px solid var(--color-surface-5);
  border-radius: var(--border-radius-md);

  & > div {
    width: 100%;
  }

  & > * {
    fill: var(--color-primary-850);
    color: var(--color-primary-850);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;