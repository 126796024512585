.bbBatchModeWrapper {
  position: fixed;
  bottom: 16px;
  left: 20px;
  right: 20px;
  margin: auto;
  max-width: 936px;
  color: white;
  box-shadow: var(--shadow-sm-1);
  border-radius: var(--border-radius-xl);
  background: linear-gradient(to right, rgba(0, 76, 239), rgba(77, 135, 228));

  .bbBatchMode {
    display: flex;
    height: 58px;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;

    &CommentSelectionIcon {
      width: 20px;
      height: 20px;
      cursor: help;

      & > * {
        fill: white;
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &CommentSelectionMessage {
      display: flex;
      align-items: center;

      > * + * {
        margin-left: 8px;
      }

      & > * {
        fill: white;
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &ActionBtnsWrapper {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    &ActionBtns {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }

    .bbDivider {
      height: 20px;
      border-left: 1px solid var(--color-surface-5);
    }

    &ApplyBtnWrapper {
      display: flex;
      align-items: center;
      column-gap: 12px;
      border-color: white;
    }

    &ApplyBtn {
      display: flex;
      align-items: center;
      gap: 4px;
      border: none;
      font-weight: bold;
      background-color: white;
      box-shadow: var(--shadow-sm-2);
      fill: var(--color-on-primary-btn);
      color: var(--color-on-primary-btn);

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-on-primary-btn);
      }
    }

    &CloseBtn {
      cursor: pointer;

      svg {
        fill: white;
      }
    }
  }
}

.bbBatchModeSelectAll {
  display: flex;
  cursor: pointer;
  align-items: center;
  column-gap: 8px;

  &Title {
    font-weight: 600;
    user-select: none;
    font-size: var(--font-size-lg);
  }
}

.bbTagsDropdownListing {
  color: var(--color-primary-900);

  &Title {
    margin-top: 20px;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: var(--font-size-xl);
  }

  &Tags {
    max-height: 272px;
    overflow-y: scroll;
    padding-right: 4px;
  }

  &Tag {
    width: 100%;
    height: 32px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
    padding: 5.5px 10px;
    font-weight: bold;
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-lg);
    background-color: var(--color-surface-4);
  }

  &TagRemoveBtn {
    border: none;
    padding: 0;

    svg {
      width: 12px;
      height: 14px;
      fill: var(--color-primary-900);
    }
  }
}

.bbTagsDropdown {
  display: inline-flex;
}

.bbTagsDropdownMenu {
  min-width: 320px;
  overflow: visible;
  margin-bottom: 10px;
  padding: 8px;
}

.bbTagsDropdownSelectedTagsBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  min-width: 18px;
  width: auto;
  height: 18px;
  background: white;
  text-align: center;
  line-height: 150%;
  color: var(--color-on-surface);
  font-size: var(--font-size-xxs);
}

.bbTagsDropdownSelectedTagsWrapper {
  width: 100%;
}

.bbTagsDropdownSelectedTags {
  color: var(--color-on-surface);

  &Title {
    font-weight: bold;
    color: var(--color-on-surface);
    font-size: var(--font-size-2xl);
  }

  &Listing {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &TagWrapper {
    gap: 4px;
    display: flex;
    align-items: center;
    padding: 1px 6px;
    font-size: var(--font-size-sm);
    color: var(--color-primary-900);
    background: var(--color-surface-4);
    border-radius: var(--border-radius-md);
  }

  &Tag {
    line-height: 18px;
    font-size: var(--font-size-sm);
  }

  &TagIcon {
    cursor: pointer;

    svg {
      fill: var(--color-on-surface);
    }
  }
}

.bbTagsDropdownAddTitle {
  font-weight: bold;
  color: var(--color-on-surface);
  font-size: var(--font-size-2xl);
}

.bbTagsInput {
  margin-top: 12px;
  margin-bottom: 12px;
  box-shadow: var(--shadow-md-2);
}

.bbAddTagBtn {
  width: 100%;
  justify-content: center;

  &Icon {
    margin-right: 4px;
    margin-bottom: 4px;
    height: 12px;

    svg {
      width: 12px;
      height: 12px;
      fill: white;
    }
  }
}

.bbTagsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.bbBaseBatchModeAction {
  column-gap: 6px !important;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.4);

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.9);
  }

  & > * {
    fill: white;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: white;
  }
}

button.bbBatchModeButton {
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  background-color: var(--color-white);
  font-weight: bold;
  color: var(--color-on-primary-btn);
  box-shadow: var(--shadow-sm-2);

  &:active {
    border-color: white;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-primary-700);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;