.bbPostTagsList {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  line-height: 32px;
  margin-top: 10px;

  div[class*="bbTagsDropdown--"] div {
    height: 20px;
    display: flex;
  }

  &AddButton {
    padding: 5px;

    svg {
      width: 10px;
      fill: var(--color-primary-1);
    }
  }
}

.bbPostTagWrapper {
  line-height: 1;
}

.bbPostTagWrapperInternal {
  display: flex !important;

  span[class*="bbActionIconRight--"] {
    padding-right: 20px;
  }

  span[class*="bbChipIconElement-right--"] {
    right: 4px;
  }
}

.bbPostTagWrapperWithTooltip {
  cursor: help;
}

.bbPostTag {
  color: var(--color-on-surface-subtle-1);

  &ActionIcon {
    display: flex;
    align-items: center;

    svg {
      fill: currentColor;
      width: 12px;
    }
  }
}

.bbPostAITag {
  background: linear-gradient(
    270deg,
    rgba(0, 72, 229, 0.1) 0%,
    rgba(98, 90, 245, 0.1) 43.74%,
    rgba(252, 98, 229, 0.1) 116.64%
  );

  &Text {
    background: linear-gradient(270deg, #0048e5 0%, #625af5 74.65%, #8a62fc 116.64%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  svg {
    path {
      width: 12px;
    }
  }
}

.bbRestOfTagsChip {
  cursor: help;
  color: var(--color-on-surface-subtle-1);
}

.bbRestOfTheTags {
  font-weight: 500;
}

.bbLoadingIcon {
  svg {
    height: 10px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;