.bbCommentActionsContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.bbCommentLinksContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

button.bbCommentAction,
button.bbCommentAction:hover,
button.bbCommentAction:active,
button.bbCommentAction:focus {
  padding: 0 9px;
  font-weight: var(--font-weight-bold);
  border-radius: var(--border-radius-md);
  background: var(--color-white);
  align-items: center;
  display: inline-flex;
  color: var(--color-primary-1);
  font-size: var(--font-size-lg);
  border-color: var(--color-surface-5);
  box-shadow: var(--shadow-sm-2);

  &:not(:last-child) {
    margin-right: 12px;
  }

  svg {
    width: 15px;
    height: 15px;
    fill: var(--color-primary-1);

    & + span {
      margin-left: 7px;
    }
  }
}

button.bbCommentActionActive {
  color: var(--color-primary-800);
  background-color: var(--color-blue-300);
  border-color: var(--color-blue-300);

  svg {
    fill: var(--color-primary-800);

    path {
      fill: var(--color-primary-800);
    }
  }

  &:active,
  &:focus,
  &:hover {
    background-color: var(--color-primary-800);
  }
}

button.bbCommentAction-danger {
  color: var(--color-red-700);

  svg {
    fill: var(--color-red-700);
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--color-red-700);

    svg {
      fill: var(--color-red-700);
    }
  }
}

button.bbCommentAction-success {
  color: var(--color-green-600);

  svg {
    fill: var(--color-green-600);
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--color-green-600);
    background-color: var(--color-green-100);
    border-color: var(--color-green-100);
    svg {
      fill: var(--color-green-600);
    }
  }
}

button.bbCommentAction-primary {
  color: var(--color-primary-1);

  svg {
    fill: var(--color-primary-1);
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--color-primary-1);
    background-color: var(--color-blue-300);
    border-color: var(--color-blue-300);
    svg {
      fill: var(--color-primary-1);
    }
  }
}

button.bbCommentAction-disabled {
  background-color: var(--color-grey-300);
  border-color: var(--color-grey-300);
  color: var(--color-white);
  cursor: not-allowed;

  svg {
    fill: var(--color-white);

    path {
      fill: var(--color-white);
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-grey-300);
    border-color: var(--color-grey-300);
    color: var(--color-white);

    svg {
      fill: var(--color-white);
    }
  }
}

.bbCommentAction-dropdown:hover,
.bbCommentAction-dropdown:active,
.bbCommentAction-dropdown:focus {
  background: var(--color-primary-200);
}

.bbCommentActionsDropdown {
  background: var(--color-white);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md-2);

  :global {
    .ant-menu-submenu-open {
      .ant-menu-submenu-active,
      .ant-menu-submenu-title,
      .ant-menu-submenu-title:hover {
        color: var(--color-primary-1);
        background-color: var(--color-blue-150);

        svg {
          fill: var(--color-primary-1);
        }
      }
    }

    .ant-menu-submenu {
      height: 35px;
      min-width: 240px;
      margin-bottom: 0;
      font-weight: var(--font-weight-bold);
      color: var(--color-blue-900);
      font-size: var(--font-size-lg);

      svg {
        width: 16px;
        height: 16px;
        margin-right: 7px;
        fill: var(--color-grey-600);
      }
    }

    .ant-menu {
      border-radius: var(--border-radius-md);
      padding: 5px 0;

      .ant-menu-item {
        height: 35px;
        min-width: 240px;
        margin-bottom: 0;
        font-weight: var(--font-weight-bold);
        color: var(--color-blue-900);
        font-size: var(--font-size-lg);

        svg {
          width: 16px;
          height: 16px;
          margin-right: 7px;
          fill: var(--color-grey-600);
        }
      }
    }
  }
}

.bbCommentActionsDropdownSubMenuItem {
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-900);
  font-size: var(--font-size-lg);
}

.bbCommentActionsDropdownSubMenuItem {
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-900);
  font-size: var(--font-size-lg);
  height: 32px;
  line-height: 32px;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-primary-1);
    background-color: var(--color-blue-150);
  }
}

.bbActionCircleClass {
  width: 9px;
  height: 9px;
  min-width: 8px !important;
  margin-right: 4px;
  border-radius: 9999px;
}

.bbActionCircleClass-positive {
  background: var(--color-green-700);
}

.bbActionCircleClass-negative {
  background: var(--color-red-700);
}

.bbActionCircleClass-neutral {
  background: var(--color-grey-700);
}

.bbExternalActionsTrigger {
  border-color: var(--color-surface-5) !important;
  border-style: solid !important;
  border-width: 1px !important;
  box-shadow: var(--shadow-sm-1);
  padding-left: 10px !important;
  padding-right: 10px !important;

  svg {
    fill: var(--color-on-primary-btn) !important;
  }
}

.bbAssignActionTrigger {
  border-color: var(--color-surface-5) !important;
  border-style: solid !important;
  border-width: 1px !important;
  box-shadow: var(--shadow-sm-1);
  padding: 7px 10px !important;
  margin-right: 16px;

  &:hover {
    background-color: var(--color-secondary-2);
  }

  svg {
    fill: var(--color-on-primary-btn) !important;
  }
}

@media (max-width: 767px) {
  div[class*="bbCommentCardFooter--"] {
    max-width: 100%;
    overflow: auto;
  }
  button[class*="bbCommentAction--"],
  button[class*="bbExternalActionsTrigger--"] {
    width: 38px;
    height: 38px;
  }

  button[class*="bbAssignActionTrigger--"] {
    height: 38px;
    margin-left: 6px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;