@max-width: 400px;

.bbStep3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px 30px;
  // deduct footer and stepper
  height: calc(100vh - 110px - 40px);
  overflow-y: auto;
  overflow-x: hidden;

  :global(.ant-form-item-explain) {
    margin-top: 0;
  }

  &Submit {
    margin-top: 16px;
    text-align: center;
  }

  &MaxWidth {
    max-width: @max-width;
    width: 100%;
  }

  &Button {
    max-width: @max-width;
    min-width: 245px;
  }

  &Footer {
    justify-content: flex-end;

    &Button {
      &Later {
        margin-right: 20px;
        color: var(--color-grey-700);
        background-color: transparent;
        border: 1px solid var(--color-surface-5);
      }
    }
  }

  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 60px);
    height: calc(100vh - 60px);

    &Footer {
      justify-content: space-between;
    }

    div[class*="bbTabHeadersContainer--"] {
      margin-bottom: 32px;
      width: 100%;
      display: block;
    }

    h1[class*="bbAssetTabsViewTitle--"] {
      margin-right: 0;
    }

    div[class*="bbTabsContainer--"] {
      div[class*="bbTabHeadersContainerResponsive--"] {
        div[class*="bbTabHeaders--"] {
          justify-content: space-between;

          div[class*="bbTabPane--"] {
            width: 100px;

            div[class*="bbTabHeader--"] {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

.bbStep3Mobile {
  padding-top: 20px;

  .bbStep3Title {
    margin-top: 16px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;