@preview-short-width: 240px;
@preview-short-height: 426px;
@preview-long-width: 280px;

.bbPreviewYT {
  display: inline-flex;
  flex-direction: column;
  width: @preview-long-width;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    width: 100%;
  }

  &Avatar {
    flex-shrink: 0;
  }

  &Video {
    margin: 0 0 8px 0;
    border-radius: 6px;
    width: @preview-long-width;
    height: calc(@preview-long-width / (16 / 9));
    object-fit: contain;
    background-color: var(--color-black);
    &HasCover {
      object-fit: cover;
    }
  }

  &Footer {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    justify-content: space-between;
  }

  &More {
    flex-shrink: 0;
    svg {
      width: 18px;
      height: 18px;
      fill: var(--color-primary-900);
    }
  }

  &Info {
    display: flex;
    flex-direction: column;
    column-gap: 6px;
    flex-grow: 1;

    &Title {
      margin: 0;
      padding: 0;
      font-size: var(--font-size-xl);
      color: var(--color-on-surface);
    }

    &Creator {
      font-size: var(--font-size-lg);
      color: var(--color-on-surface-subtle-1);
    }
  }

  &Short {
    position: relative;
    width: @preview-short-width;
    height: @preview-short-height;
    background-color: var(--color-black);
    border-radius: var(--border-radius-xl);
    overflow: hidden;

    &UIOverlay {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      width: @preview-short-width;
      height: @preview-short-height;
      svg {
        width: @preview-short-width;
        height: @preview-short-height;
      }
    }

    &Footer {
      position: absolute;
      left: 16px;
      bottom: 16px;
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: default;

      .bbPreviewYTFooter {
        color: var(--color-white);
        display: flex;
        align-items: center;
        column-gap: 8px;
        flex-grow: 0;
        margin-bottom: 8px;
      }

      .bbPreviewYTInfoCreator {
        color: var(--color-white);
        font-size: var(--font-size-xxs);
        font-weight: var(--font-weight-bold);
      }

      &SubscribeBtn {
        padding: 0 8px;
        height: 22px;
        border-radius: 11px;
        background-color: var(--color-white);
        color: var(--color-on-surface);
        font-size: var(--font-size-xxs);
        cursor: default;
      }

      &Description {
        color: var(--color-white);
        font-size: var(--font-size-xxs);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 60px;
        width: @preview-short-width;
      }
    }

    &Video {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: @preview-short-width;
      height: @preview-short-height;
      object-fit: contain;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;