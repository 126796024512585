.bbTopTags {
  margin-top: 32px;

  .bbTopTagsEmpty {
    height: 340px;
    padding: 115px 0;
  }

  :global {
    .ant-empty {
      margin: 43px auto;
    }
  }
}

.bbTopTagsLoading {
  padding-top: 198px;
  padding-bottom: 198px;
  text-align: center;
}

.bbChartLegend {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  text-transform: capitalize;
  align-items: center;
  font-weight: 600;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    fill: var(--color-grey-600);
  }

  > div {
    display: flex;
    align-items: center;
  }

  &Item {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-weight: 400;
  }

  &Box {
    width: 16px;
    height: 16px;
    border-radius: var(--border-radius-sm);
    display: inline-block;
    margin: 0 8px 2px 20px;
  }

  &Name {
    color: var(--color-blue-900);
    font-size: var(--font-size-lg);
  }
}

@media screen and (max-width: 1280px) {
  .bbChartLegend {
    padding-left: 20px;
    padding-right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .bbChartLegend {
    flex-wrap: wrap;

    > div {
      flex-wrap: wrap;
    }

    &Item {
      margin-top: 16px;
    }

    &Item:first-child {
      .bbChartLegendBox {
        margin-left: 0;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;