div.bbPostCard {
  margin: 14px 10px;
  height: 95%;

  &Container {
    display: flex;
    min-width: 100%;
    flex-flow: column;
    min-height: 320px;

    .bbPostImage {
      position: relative;
      border-radius: var(--border-radius-md);

      &.bbPostImageMissing {
        opacity: 0.5;
      }

      &.bbPostImagePortrait {
        background-color: rgb(var(--surface-5));
        margin-bottom: 22px;

        :global {
          .ant-avatar.ant-avatar-image {
            img {
              border-radius: 16px;
              margin: 0 auto;
              width: auto;
              min-width: auto;
            }
          }
        }
      }
    }

    .bbPostImageClickable,
    .bbPostImageTextClickable {
      cursor: pointer;
    }

    .bbPostImage:not(.bbPostImageFull, .bbPostImagePortrait) {
      :global(.ant-avatar),
      img {
        max-width: 100%;
        border-radius: var(--border-radius-md);
      }
    }

    .bbPostImageFull {
      span {
        margin-left: -16px;
      }
    }

    .bbPostHeader {
      display: flex;
      position: relative;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
    }

    .bbPostLogo {
      > svg:first-child {
        width: 24px;
        height: 24px;
      }
    }

    .bbPostInfo {
      flex: 1;
      display: flex;
      line-height: 1.2;
      margin-left: 5px;
      flex-flow: column;

      overflow: hidden;
      cursor: default;

      span {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: var(--font-size-sm);
      }

      small {
        color: #7e7e7e;
        font-size: var(--font-size-xs);
      }
    }

    .bbPostMessage {
      margin: 8px 0;
      min-height: 48px;
      font-size: var(--font-size-xs);
    }

    .bbPostMessagePortrait {
      min-height: 0;
      margin: 6px 0;
    }

    .bbHighlightedLink {
      color: var(--color-blue-700);
      font-weight: var(--font-weight-bold);
    }

    .bbShowMore {
      color: var(--color-grey-500);
      text-transform: lowercase;
      margin-left: 2px;
    }

    &.bbPostCardTextImage {
      .bbPostImage {
        :global(.ant-avatar) {
          display: flex;
          flex-flow: column;
          justify-content: center;
        }

        img {
          max-height: 140px;
          border-radius: var(--border-radius-md);
        }
      }

      .bbPostImage.bbShowTextAsImage {
        flex: 1;
        opacity: 1;
        display: flex;
        padding: 16px;
        margin-top: 10px;
        flex-flow: column;
        background: var(--color-notApplicable);
        justify-content: center;
        margin-left: -15px; // card padding
        margin-right: -15px; // card padding
      }
    }

    .bbPostImageText {
      padding: 10px;
      font-size: var(--font-size-lg);
    }
  }

  .bbPostImage {
    img {
      max-width: 100%;
      min-width: 200px;
      border-radius: var(--border-radius-md);
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;