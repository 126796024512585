.bbManageReportsModal {
  padding: 20px;

  &Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &Title {
      color: var(--color-primary-900);
      font-size: var(--headline-h3-size);
      font-weight: var(--font-weight-bold);
      line-height: 29px;
    }

    &Search {
      .bbSearchWrapper {
        width: 220px;
        padding-top: 3px;
        padding-bottom: 3px;
      }

      .bbSearchIcon {
        position: relative;
        top: 3px;
        margin-right: 8px;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--color-on-surface-subtle-2);
        }
      }
    }
  }

  &List {
    height: 492px;
    overflow-y: scroll;
    padding-right: 8px;

    .bbManagedReportsSuffix {
      display: flex;
      gap: 6px;

      div[class*="bbChipIconWrapper--"] {
        span[class*="bbChip-white--"] {
          color: var(--color-grey-900);
        }

        span[class*="bbIcon--"] {
          width: 12px;
          height: 12px;
          left: 7px;
        }

        svg {
          width: 12px;
          height: 12px;
          fill: var(--color-grey-900);
        }
      }
    }

    div[class*="bbModalItemCard--"] {
      padding: 5px 12px;
    }

    div[class*="bbModalItemCardContainerBodyTitle--"] {
      font-size: var(--font-size-md);
    }

    div[class*="bbDeleteConfirmation--"] {
      margin: 8px 0;
    }
  }

  &ListLoading {
    padding-top: 210px;
    display: flex;
    justify-content: center;
  }

  &ListEmpty {
    margin-top: 190px;
  }

  &ListDeleting {
    width: 570px;
    height: 500px;
    background-color: white;
    opacity: 0.5;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding-top: 200px;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: var(--color-grey-100);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-sm);
    background-color: var(--color-grey-500);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;