.bbConfirmSignUpContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-grey-100);

  .bbLogoContainer {
    display: flex;
    margin-top: 45px;

    .bbAgencyLogoBadge {
      margin-left: 8px;
    }

    > svg {
      width: 220px;
      height: 40px;
    }
  }

  .bbConfirmSignUpWindow {
    top: 45%;
    left: 50%;
    width: 800px;
    height: 375px;
    z-index: 1000;
    padding: 16px;
    position: absolute;
    transform: translate(-50%, -50%);

    .bbConfirmSignUpHeader {
      text-align: center;
      color: var(--color-primary-1);
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-xl);
    }

    .bbConfirmSignUpTitle {
      font-size: var(--headline-h4-size);
      font-weight: var(--font-weight-bold);
      max-width: 480px;
      text-align: center;
      color: var(--color-blue-900);
      margin: 0 auto 40px auto;
    }

    .bbConfirmSignUpForm {
      max-width: 550px;
      margin: 0 auto 20px;

      :global {
        .ant-form-item {
          margin: 0;
        }

        .ant-form-item-control-input-content {
          display: flex;
          justify-content: center;
        }
      }
    }

    .bbConfirmSignUpHelpWrapper {
      margin-top: 34px;
      text-align: center;

      .bbConfirmSignUpHelpBtn {
        margin-right: 20px;
        font-weight: normal;

        svg {
          width: 14px;
          height: 14px;
          margin-right: 7px;
          top: 1px;
          position: relative;
          fill: var(--color-primary-1);
        }
      }

      a {
        color: var(--color-primary-1);
      }
    }

    .bbCodeSubmitContainer {
      display: flex;
      margin: 0 auto;
      padding: 40px;
      max-width: 470px;
      background: var(--color-white);
      align-items: center;
      flex-direction: column;
      justify-content: center;
      box-shadow: var(--shadow-sm-2);

      .bbConfirmSignUpSubmitBtn {
        width: 100%;
        display: flex;
        margin-top: 15px;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        font-size: var(--font-size-xl);

        svg {
          width: 20px;
          height: 20px;
          fill: var(--color-white);
          margin-bottom: 2px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .bbLogoContainer {
      display: none;
    }

    .bbConfirmSignUpWindow {
      width: 100%;
      height: auto;
      top: unset;
      left: unset;
      transform: unset;
      position: relative;
      padding: 0;
      padding-top: 20px;

      .bbConfirmSignUpHeader {
        font-weight: var(--font-weight-medium);
        color: var(--color-on-surface-subtle-1);
        margin-bottom: 8px;
      }

      input {
        width: 46px;
      }

      .bbConfirmSignUpTitle {
        max-width: 365px;
        margin-bottom: 20px;
      }

      .bbCodeSubmitContainer {
        padding: 40px 20px;
      }

      .bbConfirmSignUpHelpWrapper {
        margin-top: 20px;
      }

      .bbConfirmSignUpSubmitBtn {
        max-height: 40px;
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 350px) {
    .bbConfirmSignUpWindow {
      .bbCodeSubmitContainer {
        padding: 30px 10px;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;