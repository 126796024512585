.bbCSVDownloadInfo {
  z-index: 999;
  width: 408px;
  height: 285px;
  right: 20px;
  bottom: 70px;
  padding: 20px;
  position: fixed;
  border-radius: var(--border-radius-lg);
  background-color: var(--color-white);
  box-shadow: var(--shadow-md-2);

  .bbHeader {
    display: flex;
    justify-content: space-between;

    .bbHeaderMain {
      display: flex;
      flex-direction: column;

      &Title {
        display: flex;
      }

      span {
        display: flex;
        font-size: var(--headline-h5-size);
        font-weight: var(--font-weight-bold);
        color: var(--color-grey-900);
      }

      svg {
        top: 4px;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        position: relative;
      }

      .bbCheck {
        fill: var(--color-green-600);
      }

      .bbLoading {
        fill: var(--color-primary-1);

        svg {
          animation: pendingProgress 1s linear infinite;
        }
      }
    }

    .bbSubHeader {
      font-weight: 400;
      color: var(--color-grey-700);
      font-size: var(--font-size-lg);
    }

    .bbActionIcons {
      display: flex;
      justify-content: flex-end;

      .bbSmallIcon {
        cursor: pointer;
        margin-left: 25px;

        svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
          fill: var(--color-grey-700);
        }
      }
    }
  }

  .bbCSVDownloadContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .bbDisclaimer {
      width: 250px;
      font-weight: var(--font-weight-bold);
      color: var(--color-grey-900);
      font-size: var(--font-size-lg);

      button {
        padding: 0px 5px;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-lg);
      }
    }

    > svg {
      margin: 20px 0;
    }
  }
}

@keyframes pendingProgress {
  100% {
    transform: rotate(360deg);
  }
}

.bbCSVDownloadInfoMinified {
  height: auto;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;