@text-color: #13151b;
@tab-gray-background: #f3f3f6;

.bbTTPricingTable {
  .bbTTPricingTableHeader {
    display: flex;
    justify-content: center;
    &Tabs {
      display: flex;
      border-radius: var(--border-radius-lg);
      background-color: @tab-gray-background;
      height: 40px;
      margin-bottom: 20px;
    }
    &Tab {
      padding: 10px 16px;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-extrabold);
      color: @text-color;
      cursor: pointer;
      &Left {
        border-bottom-left-radius: var(--border-radius-lg);
        border-top-left-radius: var(--border-radius-lg);
      }
      &Right {
        border-bottom-right-radius: var(--border-radius-lg);
        border-top-right-radius: var(--border-radius-lg);
      }
      &Active {
        background-color: var(--color-primary-850);
        color: var(--color-white);
      }
    }
  }
  .bbTTPricingTableMain {
    display: flex;
    justify-content: center;
    &Frame {
      padding: 36px;
      border-radius: var(--border-radius-xl);
      row-gap: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: fit-content;
      &:first-of-type {
        background-color: @tab-gray-background;
      }
    }
    .bbTTChipWrapper {
      height: 20px;
      .bbTTChip {
        background-color: var(--color-primary-850);
        padding: 1px 6px;
        line-height: 18px;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-sm);
        color: var(--color-white);
        border-radius: var(--border-radius-sm);
        width: 90px;
        text-align: center;
      }
    }
    .bbTTSectionType {
      display: flex;
      flex-direction: column;
      column-gap: 8px;
      &Title {
        font-weight: var(--font-weight-extrabold);
        font-size: var(--headline-h5-size);
        color: @text-color;
      }
      &Description {
        display: flex;
        column-gap: 8px;
        align-items: center;
        color: #676f85;
        font-size: var(--font-size-xl);
      }
      &SubTitle {
        font-size: var(--font-size-lg);
      }
      &Price {
        color: @text-color;
        font-size: var(--font-size-3xl);
        font-weight: var(--font-weight-extrabold);
      }
    }
    .bbTTLink {
      background-color: var(--color-blue-700);
      border-radius: var(--border-radius-md);
      padding: 8px 16px;
      color: var(--color-white);
      font-weight: var(--font-weight-extrabold);
      font-size: var(--font-size-xl);
      width: 100%;
      display: block;
      text-align: center;
    }
    .bbTTList {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      &Title {
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-xl);
        color: @text-color;
      }
      &Items {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }
      &Item {
        display: flex;
        column-gap: 8px;
        svg {
          width: 16px;
          height: 16px;
          min-width: 16px;
          fill: var(--color-success-600);
          margin: 4px 0;
        }
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;