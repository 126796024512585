.bbSingleImageContainer {
  position: relative;

  .bbImageLink {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    margin: 0;
    border-radius: inherit;
    text-align: inherit;
    min-height: 0;
    min-width: 0;
    background-color: transparent;
    touch-action: manipulation;
    z-index: 0;
    user-select: none;
    border: 0px solid rgba(0, 0, 0, 0.4);
    list-style: none;
    outline: none;
    text-decoration: none;
    cursor: default;

    .bbMainWrapper {
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
      background-color: var(--color-black);
      overflow: hidden;
      position: relative;
      width: 100%;

      .bbTagBadgeWrapper {
        position: absolute;
        left: 12px;
        bottom: 12px;
        z-index: 1;
        display: flex;
        flex-direction: row;
        column-gap: 6px;
      }

      .bbImageDimensions {
        max-width: 282px;
        min-width: 282px;
      }

      .bbImageRootWrapper {
        overflow: hidden;
        position: relative;
        height: 0;

        .bbImageWrapper {
          position: absolute;
          top: 0;

          .bbImage {
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            object-fit: cover;
          }
        }
      }
    }

    .bbBorders {
      position: absolute;
      inset: 0;
      pointer-events: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .bbMainWrapperSupporter {
      position: absolute;
      border-radius: inherit;
      inset: 0;
      pointer-events: none;
      opacity: 0;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;