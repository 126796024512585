.bbCard {
  height: 100%;
  padding: 20px;

  &Content {
    height: 304px;
  }

  .bbReviewVolumeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
    .bbReviewVolumeTitle {
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-lg);
    }
    .bbReviewVolumeStar {
      padding: 8px;
      display: flex;
      background-color: var(--color-primary-150);
      border-radius: var(--border-radius-xxl);
    }
  }

  .bbReviewVolumeContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    justify-content: center;
    margin-top: 100px;

    > span {
      color: #13151b;
      font-size: var(--headline-h2-size);
      font-weight: var(--font-weight-extrabold);
      height: 1.25em;
    }

    div[class*="bbPerformanceCardPill"] {
      margin-left: 0;
    }
  }

  .bbReviewLegend {
    margin-left: 38px;
  }

  .bbReviewWidgetEmpty {
    margin: 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 240px;
  }

  .bbReviewWidgetLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 240px;
  }

  .bbReviewPlatform {
    padding-top: 20px;
    &Item {
      display: flex;
      align-items: center;
      margin-top: 14px;
      width: 100%;
    }

    &Name {
      display: flex;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
      gap: 8px;
      width: 160px;
      align-items: center;
      > span {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    &ChartWrapper {
      width: 100%;
      display: flex;
      align-items: center;
    }
    &ChartBar {
      height: 24px;
      max-width: 90%;
      display: flex;
      column-gap: 1px;
      &First {
        border-top-left-radius: var(--border-radius-sm);
        border-bottom-left-radius: var(--border-radius-sm);
      }
      &Last {
        border-top-right-radius: var(--border-radius-sm);
        border-bottom-right-radius: var(--border-radius-sm);
      }
    }

    &Label {
      display: flex;
      align-items: center;
    }

    &Value {
      font-weight: bold;
      margin-left: 12px;
      font-size: var(--font-size-2xl);
    }

    &Percentage {
      margin-left: 4px;
      font-size: var(--font-size-lg);
    }

    @media (max-width: var(--screen-xl-max)) {
      &Value {
        font-size: var(--font-size-xl);
      }
    }
  }
}

.bbReviewComparisonLegend {
  display: flex;
  align-items: center;
  padding-top: 40px;
  flex-wrap: wrap;

  &Item {
    display: flex;
    margin-right: 20px;
    align-items: center;
    font-size: var(--font-size-lg);

    &Mark {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      border-radius: var(--border-radius-sm);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;