.bbProfiles {
  &Title {
    display: flex;
    gap: 12px;
    align-items: center;

    &Label {
      line-height: 120%;
    }

    &Tag {
      background-color: var(--color-primary-850);
      border-radius: var(--border-radius-sm);
      text-transform: uppercase;
      color: var(--color-white);
      padding: 1px 6px;
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-bold);
    }
  }

  &Content {
    padding-top: 20px;
    display: flex;
    gap: 24px;
    flex-direction: column;
  }

  &Widgets {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
  }

  &Chart {
    &Wrapper {
      padding: 20px 24px;
      width: 350px;
    }
    div[class*="bbPieChartWidget--"] {
      flex-direction: column;
      align-items: center;
      gap: 28px;
    }
  }

  &ImpressionsTable {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    > span {
      font-size: var(--font-size-2xl);
      font-weight: var(--font-weight-extrabold);
    }
  }

  &ImpressionsTitle {
    margin-bottom: -12px;
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-extrabold);
  }

  div[class*="bbFooterRow--"] {
    margin-top: 12px;
  }

  @media (max-width: 1439px) {
    .bbProfilesWidgets {
      flex-direction: column;
      align-items: center;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;