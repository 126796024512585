.bbOverviewContainer {
  margin-top: 16px;

  .bbOverviewAcross {
    margin-top: 10px;
  }

  .bbTrendsInfo {
    font-size: var(--font-size-lg);
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    float: right;

    .bbTrendsInfoLabel {
      font-weight: bold;
      font-style: italic;
      margin-right: 16px;
    }

    .bbTrendsInfoValue {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .bbTrendsInfoSmall {
    justify-content: flex-start;
    float: left;
  }

  .bbTrendsOverview {
    font-size: var(--font-size-lg);
    display: flex;
    align-items: center;

    a {
      border-width: 0;
      margin-left: 4px;

      span {
        font-size: var(--font-size-lg);
        text-decoration: underline;
      }
    }
  }

  .bbPieChartsWrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .bbMessageTypeChartsWrapper {
    margin-bottom: 40px;
  }

  .bbTopics {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 1440px) {
    div[class*="bbSentimentCardsContainer--"] {
      p[class*="bbSentimentText--"] {
        display: block;
        margin-left: 0;
      }
    }
  }
}

.bbDownloadReportButton {
  gap: 8px;

  svg {
    fill: var(--color-on-primary-btn);
    width: 16px;
    height: 16px;
  }
}

.bbSectionDivider {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;

  &Main {
    display: flex;
    align-items: center;

    span[class*="bbChipIconElement--"] {
      width: 18px;
      height: 18px;
    }
  }

  span.bbSectionDividerChip {
    background: var(--color-secondary-2);
    color: var(--color-on-secondary);
    border-radius: 4999px;
    font-weight: var(--font-weight-bold);
  }

  &ChipIcon {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--color-on-secondary);
    }
  }

  &Description {
    font-size: var(--headline-h4-size);
    margin-left: 16px;
  }

  &MainAction,
  &MainAction:hover {
    margin-left: 16px;
    background-color: var(--color-white);
    box-shadow: var(--shadow-sm-2);

    svg {
      width: 18px;
      height: 18px;
      margin-left: 4px;
      fill: var(--color-primary-600);
    }
  }

  .bbActionButtonsWrapper {
    display: flex;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;