.bbTTTrending {
  position: relative;
  margin-top: 40px;
  gap: 32px;
  display: flex;
  flex-direction: column;

  &Header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    justify-content: space-between;
  }

  &Type {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      padding: 9.5px 16px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      background-color: var(--color-on-primary-dark);
      box-shadow: var(--shadow-sm-2);

      span {
        text-align: center;

        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-bold);
      }
    }
    svg,
    path {
      width: 16px;
      height: 16px;
      fill: var(--color-on-surface-subtle-2);
    }

    .bbTTTrendingHastags {
      border-radius: 8px 0 0 8px;
    }

    .bbTTTrendingSongs {
      border-radius: 0px 8px 8px 0px;
    }

    div.bbTTTrendingTypeActive {
      background: var(--color-primary-dark);

      * {
        color: var(--color-on-primary-dark);
        fill: var(--color-on-primary-dark);
      }
    }
  }

  &Filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  }

  &Dropdown {
    min-width: 320px;
  }

  .bbTableRow {
    .bbColumn {
      font-size: var(--font-size-lg);
      font-weight: 600;
      display: flex;
      padding: 8px 18px;
      min-height: 79px;
      align-items: center;
      color: var(--color-primary-900);
    }

    :global {
      .ant-col-0 {
        display: none;
      }
    }

    .bbHashtagColumn {
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
      color: var(--color-inverted-surface);
    }

    .bbSong {
      display: flex;
      align-items: center;
      gap: 12px;

      img {
        width: 52px;
        height: 52px;
        border-radius: var(--border-radius-lg);
      }

      &Name {
        font-weight: var(--font-weight-extrabold);
        font-size: var(--font-size-xl);
        color: var(--color-inverted-surface);
        line-height: 100%;
      }

      &Content {
        display: flex;
        flex-direction: column;
      }

      &Artist {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-lg);
        color: var(--color-on-surface-subtle-1);
      }
    }

    .bbCountryFlag {
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      padding: 1px 6px;
      white-space: nowrap;
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-sm);
      border-radius: var(--border-radius-md);
      box-shadow: var(--shadow-sm-2);
      line-height: 18px;
    }

    .bbRankColumn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > span:first-child {
        width: 20px;
      }
    }

    &Colored {
      background-color: var(--color-surface-3);
    }
    .bbNewChip {
      display: inline-flex;
      padding: 2px 8px;
      align-items: center;
      gap: 4px;
      border-radius: 999px;
      border: 1px solid var(--color-surface-5);
      margin-left: 8px;
      color: var(--color-primary-700);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-bold);
    }
  }
}

body .bbTTTrendingDropdownWrapper {
  background-color: var(--color-surface-2);
  height: 40px;
  width: 180px;
  border: none;
  box-shadow: var(--shadow-sm-2);
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;