.bbPostCreationModalWrapper {
  &Mobile {
    padding: 20px 0;
  }

  .bbPostCreationModal {
    width: calc(100% - 40px) !important;
    height: calc(100% - 40px);
    padding: 0px;

    &Mobile {
      height: auto;
    }

    &Content {
      overflow: hidden;
      height: 100%;

      &Mobile {
        height: 85vh;
      }
    }

    &Body {
      padding: 0;
      height: 100%;
    }

    .bbPostCreationFormWrapper {
      width: 100%;
      height: 100%;

      .bbPostCreationFormContent {
        display: flex;
        height: calc(100% - 72px);

        &Mobile {
          flex-direction: column;
          height: auto;
          padding-bottom: 72px;
        }

        .bbPostCreationForm {
          flex: 1;
          max-width: 50%;
          padding: 12px 24px;
          padding-right: 40px;
          overflow: hidden auto;

          &Mobile {
            max-width: 100%;
            padding-right: 24px;
          }

          .bbPostCreationBodyTitle {
            font-size: var(--headline-h5-size);
            margin-top: 8px;
            margin-bottom: 24px;
            font-weight: var(--font-weight-extrabold);
          }

          .bbPostCreationBodyDescription {
            display: flex;
            justify-content: center;

            &Empty {
              max-width: 600px;
              margin: auto;
              position: relative;

              &Mobile {
                max-width: 100%;
              }
            }
          }

          .bbPostCreationAssetTabs,
          .bbPostCreationAssetTabsHeaders {
            max-width: 600px;
            width: 600px;

            &Mobile {
              max-width: 100%;
              width: 100%;
            }
          }

          .bbPostCreationAssetTabWrapper + .bbPostCreationAssetTabWrapper {
            .bbPostCreationAssetTab {
              margin: 0 0 0 22px;
            }
          }

          .bbAssetImageAvatar {
            border: 2px solid transparent;
          }

          .bbPostCreationAssetTabActive {
            .bbAssetImageAvatar {
              border: 2px solid var(--color-primary-1);
            }
          }

          .bbPostCreationAssetTabsContent {
            position: relative;

            .ant-avatar.ant-avatar-circle.ant-avatar-image {
              box-shadow: var(--shadow-md-2);
            }
          }

          :global {
            .ant-avatar.ant-avatar-circle.ant-avatar-image {
              // overwrite antd inline styles
              width: 100% !important;
              height: 100% !important;
              box-shadow: 1px 1px 4px 0px rgba(var(--color-blue-900), 0.08);
            }
          }

          .bbAssetTextEmpty {
            margin-top: 4px;
            position: absolute;
            left: 0px;
            color: var(--color-grey-700);
            font-size: var(--font-size-lg);
            font-weight: var(--font-weight-extrabold);
            line-height: 21px;
            letter-spacing: 0.14px;
          }

          .bbAssetImageContainer {
            width: 36px;
            height: 38px;
            position: relative;

            .bbAssetImageAvatar {
              width: 36px;
              height: 36px;
              padding: 0;
              border-radius: var(--border-radius-xxl);
            }

            .bbAssetPlatformIcon {
              width: 24px;
              height: 24px;
              top: 16px;
              left: 16px;
              position: absolute;

              svg {
                width: 24px;
                height: 24px;
              }

              &YouTube {
                width: 29px;
                height: 29px;
                top: 14px;
                left: 11px;

                svg {
                  width: 29px;
                  height: 29px;
                }
              }
            }

            .bbErrorIcon {
              width: 16px;
              height: 16px;
              top: -5px;
              right: -6px;
              position: absolute;
              fill: var(--color-red-700);
            }
          }

          .bbAssetImageContainerError {
            .bbAssetImageAvatar {
              border: 2px solid var(--color-red-700);
            }
          }

          .bbAssetsLoading {
            text-align: center;
            padding: 100px;
          }

          .bbSchedulingTabs {
            width: 100% !important;

            .bbSchedulingTabWrapper {
              .bbSchedulingTab {
                font-size: var(--font-size-lg);
                padding: 10px 0;
              }
            }

            .bbSchedulingTabWrapper + .bbSchedulingTabWrapper {
              .bbSchedulingTab {
                margin-left: 22px;
              }
            }
          }

          .bbTTOptionsLoading {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
          }
        }

        .bbSchedulingSectionWrapper {
          display: flex;
          justify-content: center;

          &Mobile {
            padding-inline: 24px;
          }
        }

        .bbSchedulingSection {
          margin-bottom: 60px;
          width: 100%;
          max-width: 600px;

          .bbTTMusicDisclaimer {
            display: flex;
            align-items: center;
            margin-top: 20px;
            color: var(--color-grey-700);
            font-size: var(--font-size-md);

            svg {
              width: 16px;
              height: 16px;
              margin-right: 6px;
              fill: var(--color-grey-600);
            }

            span {
              cursor: pointer;
              margin-left: 2px;
              margin-right: 2px;
              text-decoration: underline;
            }
          }
        }

        .bbFormFieldError {
          display: flex;
          margin-top: 4px;
          font-size: var(--font-size-lg);

          & > svg {
            top: 3px;
            width: 15px;
            height: 15px;
            margin-right: 4px;
            position: relative;
          }
        }

        .bbPostCreationBody {
          display: flex;
          margin-top: 8px;

          &RadioGroup {
            margin-left: 2px;
            margin-bottom: 12px;

            :global {
              .ant-radio-inner {
                border-color: var(--color-grey-600);
                background-color: var(--color-white);
              }

              .ant-radio-checked .ant-radio-inner {
                border-color: var(--color-primary-1);
                background-color: var(--color-primary-1);
              }

              .ant-radio-checked .ant-radio-inner::after {
                background-color: var(--color-white);
              }
            }
          }

          &Section {
            width: 100%;
            flex-shrink: 0;

            :global {
              .ant-upload.ant-upload-drag {
                border-radius: var(--border-radius-md);
                background: var(--color-white);
              }

              .ant-upload.ant-upload-drag .ant-upload-btn {
                padding: 8px 0;
              }
            }

            &Title {
              font-size: 15px;
              font-weight: var(--font-weight-bold);
              margin-bottom: 12px;
              margin-top: 32px;
            }

            &Timezone {
              line-height: 24px;
              color: var(--color-grey-600);
              font-size: var(--font-size-sm);

              &:before {
                content: "Timezone:";
                font-weight: var(--font-weight-bold);
                margin-right: 4px;
              }
            }

            &Assets {
              margin-bottom: 6px;
              width: unset;

              &Empty {
                margin-bottom: 18px;
                justify-content: flex-end;
                width: unset;
              }
            }

            .bbCaptionInput {
              max-width: 99%;
              margin-left: 2px;
              font-size: var(--font-size-lg);
              padding-bottom: 62px;
              min-height: 150px;
              padding-top: 10px;
              border-radius: var(--border-radius-md);
            }
          }

          &SectionLarge {
            :global {
              .ant-upload.ant-upload-drag .ant-upload-btn {
                padding: 20px 0;
              }
            }

            div[class*="bbImageUploadContainer"] {
              svg {
                width: 35px;
                height: 40px;
                border-radius: var(--border-radius-md);
              }
            }

            span[class*="bbImageUploadText"] {
              width: 200px;
            }
          }
        }

        .bbPostCreationPreview {
          flex: 1;
          max-width: 50%;
          display: flex;
          padding-left: 18px;
          padding-right: 18px;
          flex-direction: column;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          background-color: var(--color-grey-100);
          overflow: hidden auto;

          div[class*="bbTabsContainer--"] {
            margin-top: 70px;
            margin-left: 22px;
          }

          &Mobile {
            max-width: 100%;
            padding-bottom: 18px;

            div[class*="bbTabsContainer--"] {
              margin-top: 0;
              margin-left: 0;
            }

            div[class*="bbTabHeaders--"] {
              box-shadow: none;
            }
          }
        }

        .bbEmptyState {
          padding-top: 4px;
          text-align: center;
          position: absolute;
          left: 25%;
          top: 50%;
          transform: translate(-50%, -50%);

          &Mobile {
            position: relative;
            left: 0;
            top: 0;
            transform: translate(0, 0);
          }

          &Illustration {
            margin-bottom: 26px;

            svg {
              width: 260px;
              height: 173px;
              margin: auto;
            }
          }

          &ArrowUpIcon {
            margin-left: 4px;

            svg {
              width: 19px;
              height: 19px;
              fill: var(--color-blue-900);
            }
          }

          &Text {
            white-space: pre-wrap;
            font-size: var(--headline-h5-size);
          }
        }
      }

      .bbPostCreationFooter {
        padding: 16px;
        display: flex;
        min-height: 72px;
        align-items: center;
        box-shadow: var(--shadow-lg-3);

        &Mobile {
          background-color: var(--color-white);
          position: sticky;
          bottom: 72px;

          &WithErrors {
            padding-top: 84px;
          }
        }

        .bbFormGenericError {
          height: 32px;
          padding: 6px 12px;
          border-radius: 16px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          background: var(--color-red-200);

          &Mobile {
            position: absolute;
            left: 16px;
            bottom: 68px;
            right: 16px;
            height: 52px;
          }

          &Text {
            font-size: var(--font-size-md);
            font-weight: var(--font-weight-medium);
            word-wrap: break-word;
            color: var(--color-red-900);
          }

          svg {
            width: 16px;
            height: 16px;
            fill: var(--color-red-700);
            margin-right: 4px;
          }
        }

        .bbDeleteButton {
          color: var(--color-primary-850);
          margin-right: 12px;

          .bbDeleteButtonIcon {
            display: flex;
            margin-right: 6px;

            svg {
              width: 16px;
              height: 16px;
              fill: var(--color-primary-850);
            }
          }
        }

        .bbDeleteConfirmation {
          position: absolute;
          left: 142px;
        }

        .bbFormSubmitButtonWrapper {
          display: flex;
          gap: 8px;
          justify-content: flex-end;
          right: 16px;
          position: absolute;

          .bbFormSubmitButton {
            background: linear-gradient(
              90deg,
              var(--color-gradient-1) 0%,
              var(--color-gradient-2) 100%
            );

            &Icon {
              display: flex;
              margin-right: 8px;

              svg {
                width: 20px;
                height: 20px;
                fill: var(--color-white);
              }
            }
          }
        }
      }
    }
  }

  .bbMediaError {
    color: var(--color-red-700);
    font-size: var(--font-size-lg);
    margin-top: -10px;
    margin-bottom: 10px;

    & > svg {
      top: 3px;
      width: 15px;
      height: 15px;
      min-width: 15px;
      margin-right: 4px;
      fill: var(--color-red-700);
      position: relative;
    }
  }

  .bbMediaDisclaimer {
    font-size: var(--font-size-md);
    margin-top: -10px;
    margin-bottom: 20px;
    color: var(--color-grey-700);

    svg {
      width: 15px;
      height: 15px;
      position: relative;
      top: 4px;
      margin-right: 4px;
    }
  }

  .bbPostCreationPreviewTitle {
    font-size: 15px;
    font-weight: var(--font-weight-bold);
    margin-left: 20px;

    .bbPostPreviewInfo {
      svg {
        width: 12px;
        height: 14px;
      }
    }
  }

  .bbPostPreviewEpmtyWrapper {
    display: flex;
    justify-content: center;
  }

  .bbAiPromptsWrapper {
    display: flex;
    position: relative;
  }

  .bbPublishingDisclaimer {
    max-width: 290px;
  }

  :global(.ant-form-item-has-error #asset_ids-wrapper),
  :global(.ant-form-item-has-error #message),
  :global(.ant-form-item-has-error .ant-upload-drag) {
    border: var(--color-red-600) 1px solid;
    border-radius: var(--border-radius-md);
  }

  .bbPostCreationPreviewNotes {
    padding: 12px;
    border-radius: var(--border-radius-xl);
    box-shadow: var(--shadow-sm-1);
    background-color: var(--color-surface-2);
  }
}

.bbPostCreationAccountsMenuWrapperMobile {
  left: 200px !important;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;