.bbUserTags {
  &ScrollView {
    height: 480px;
    padding: 0 28px;
  }

  &Collaborators {
    margin: 16px 0;
  }

  &Description {
    margin: 0;
    padding: 0 0 6px 0;
    color: var(--color-on-surface-subtle-1);
    font-size: var(--font-size-md);
  }

  &List {
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    flex-shrink: 0;

    > li {
      border-bottom: 1px var(--color-surface-5) solid;
      padding: 6px 0;
      height: 48px;
      box-sizing: border-box;
      display: flex;
      column-gap: 4px;
      align-items: center;
      > span:first-child {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        box-shadow: var(--shadow-sm-2);
        margin-right: 8px;
        svg {
          width: 16px;
          height: 16px;
          fill: var(--color-on-secondary);
        }
      }
      > span:nth-child(2) {
        flex-grow: 1;
        color: var(--color-primary-900);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-lg);
      }
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        svg {
          width: 16px;
          height: 16px;
          fill: var(--color-on-secondary);
          transition: fill 200ms ease;
        }
        &:hover svg {
          fill: var(--color-primary-1);
        }
      }
      &:last-child {
        border: none;
      }
    }
  }

  &Empty {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 36px;
    svg {
      margin-bottom: 24px;
    }
    h3 {
      font-size: var(--font-size-2xl);
      color: var(--color-primary-900);
      font-weight: var(--font-weight-bold);
      padding: 0;
      margin: 0 0 6px 0;
    }
    p {
      font-size: var(--font-size-lg);
      color: var(--color-on-surface-subtle-1);
      padding: 0;
      margin: 0;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;