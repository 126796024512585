.bbInbox {
  margin-top: 16px;

  &MobileFiltersButtonWrapper {
    margin-top: 15px;
    margin-left: 15px;
  }

  &BatchMode {
    opacity: 0.5;
    pointer-events: none;
  }

  &FilterRow {
    margin: 0 10px;
  }

  &ContentRow {
    margin-top: 30px;
    column-gap: 30px;
  }

  &ContentCol {
    display: flex !important;
    flex-direction: column;
    gap: 20px;
  }

  &ContentWrapper {
    flex-grow: 1;

    .bbErrorDisclaimerBox {
      margin-top: 8px;
      margin-bottom: 8px;

      svg {
        margin-bottom: -2px;
        width: 16px;
        height: 16px;
      }
    }
  }

  &CommentsGrid {
    margin: 0 auto;
    max-width: 730px;
  }

  :global {
    .ant-spin-container > div[class*="bbCommentsGrid"] {
      margin-top: 30px;
    }
  }

  div[class*="bbCommentPostPreview--"] {
    display: block;
  }

  @media (max-width: var(--screen-lg-min)) {
    div[class*="bbInboxCommentsHeader"] {
      margin-top: 30px;
    }
  }

  @media (max-width: 1360px) {
    div[class*="bbCommentPostPreviewWrapper--"] {
      display: none;
    }

    &CommentsGrid {
      max-width: 500px;
    }
  }
}

.bbErrorDisclaimer {
  margin-top: 16px;

  &Message {
    display: inline-block;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;