.bbEmailAlertAction {
  &Config {
    padding-top: 0;
  }

  &Form {
    display: inline;
  }

  &Section {
    font-size: var(--headline-h5-size);
    font-weight: var(--font-weight-bold);
    margin-top: 6px;
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    &Icon {
      background-color: var(--color-white);
      border-radius: var(--border-radius-xxl);
      padding: 8px;
      display: flex;
      align-items: flex-start;
      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-700);
      }
    }
  }

  .bbHumanReviewCheck {
    svg {
      top: -1px;
      left: -2px;
      fill: var(--color-white);
      position: relative;
    }
  }

  .bbSubscriberSelect {
    > div {
      border-radius: var(--border-radius-md) !important;
    }
    div[class*="bbSubscriberOption"] {
      padding: 0;

      span {
        font-size: var(--font-size-lg);
      }

      small {
        display: none;
      }
    }
  }

  .bbDivider {
    background-color: var(--color-surface-5);
    margin-bottom: 10px;
  }

  .bbRiskLevelSelect {
    display: flex;
    gap: 10px;

    > div {
      flex: 1;
    }

    div[class*="bbClearableTriggerGroup--"] {
      height: 32px;
      padding: 6px 12px;
    }

    div[class*="bbIndicator--"] {
      top: 3px;
    }

    .bbRiskLevelSelectWrapper {
      height: 32px;
      padding: 6px 12px;
    }
  }

  .bbEmailAlertTextAreaForm {
    width: 100%;
    margin-bottom: 0px !important;
  }

  .bbEmailAlertTextArea {
    width: 100%;
    height: 140px;
    background-color: var(--color-white);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-medium);
  }

  .bbRiskSection {
    transition: max-height 0.5s ease-in-out;
    max-height: 500px;
    overflow: hidden;
    &Hidden {
      max-height: 40px;
    }
  }
}

.bbDropdownMenu {
  div[class*="bbListItemContentLeft--"] {
    align-items: center;
  }
}

.bbDisplayBlock {
  display: block;
}

.bbIconRisk-low {
  background-color: #fff2bd;
  svg {
    fill: #eec319;
  }
}
.bbIconRisk-moderate {
  background-color: var(--color-warning-200);
  svg {
    fill: var(--color-warning-600);
  }
}
.bbIconRisk-high {
  background-color: var(--color-danger-200);
  svg {
    fill: var(--color-danger-700);
  }
}
.bbIconRisk-critical {
  background-color: var(--color-danger-700);
  svg {
    fill: var(--color-white);
  }
}
.bbIconRisk {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  border-radius: 5000px;
  margin-right: 6px;
  svg {
    width: 10px;
    height: 10px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;