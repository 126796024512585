.bbAutomatedHidingModalContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > svg {
    margin: 0 auto;
  }

  .bbUnsavedPhrase {
    margin-bottom: 20px;
  }

  .bbAutomatedHidingModalDescriptionStartHiding {
    line-height: 40px;
    font-size: var(--font-size-2xl);
    text-align: center;
  }

  .bbAutomatedHidingModalDescriptionStopHiding {
    text-align: center;
  }

  .bbAutomatedHidingModalChip {
    display: inline-block;
    background: var(--color-primary-150);
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-lg);
    padding: 0 10px;
    line-height: 32px;
  }

  .bbPlatformIcon {
    svg {
      width: 24px;
      height: 24px;
      position: relative;
      top: 5px;
      margin-right: 10px;
    }

    &-youtube {
      svg {
        width: 29px;
        height: 29px;
        top: 8px;
      }
    }
  }

  .bbAutomatedHidingModalSaveQuestion {
    margin-top: 30px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-2xl);
    color: var(--color-primary-900);
  }

  .bbTwitterDisclaimer {
    display: flex;
    background: var(--color-orange-200);
    border-radius: var(--border-radius-lg);
    color: var(--color-orange-850);
    margin-top: 20px;
    font-size: var(--font-size-sm);
    padding: 6px 12px;

    & > svg {
      fill: var(--color-orange-850);
      padding-right: 10px;
      height: 15px;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;