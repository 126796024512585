@max-width: 456px;

.bbStep2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // deduct footer and stepper
  height: calc(100vh - 110px - 40px);

  :global {
    .ant-form-item {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .ant-form-item-explain {
      margin-top: 0;
    }

    .ant-form-item .ant-form-item-label {
      padding: 0;

      label {
        color: var(--color-grey-900);
        font-size: var(--font-size-lg);
      }
    }

    input {
      max-width: 375px;
      min-width: 375px;
      border-radius: var(--border-radius-md);
      border: 1px solid var(--color-grey-300);
    }
  }

  .bbStep2Title {
    margin: 40px 0;
    font-size: var(--headline-h4-size);
    color: var(--color-blue-900);
  }

  .bbStep2Form {
    padding: 40px;
    padding-bottom: 20px;
    min-height: 362px;
    background: var(--color-white);
    margin-bottom: 80px;
    border-radius: var(--border-radius-md);
    max-width: @max-width;
    box-shadow: var(--shadow-sm-2);

    .bbStep2Submit {
      margin-top: 16px;
      text-align: center;
    }

    .bbStep2MaxWidth {
      max-width: @max-width;
      width: 100%;

      :global {
        .ant-form-item-explain.ant-form-item-explain-error {
          color: var(--color-red-700);
        }
      }
    }

    .bbStep2Agency {
      display: flex;
      margin-top: 16px;
      margin-bottom: 16px;
      align-items: center;

      .bbStep2AgencyLabel {
        margin-left: 12px;
        font-size: var(--font-size-2xl);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .bbStep2Title {
      margin-top: 0;
      margin-bottom: 20px;
    }

    :global {
      .ant-form-item {
        margin-bottom: 12px;
      }
    }

    .bbStep2Form {
      padding-bottom: 105%;
      width: 100%;

      .bbStep2MaxWidth {
        max-width: 100%;
      }

      input {
        max-width: 100%;
        min-width: 100%;
      }

      .bbStep2Agency {
        margin-top: 20px;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;