.bbCompareContainer {
  display: flex;
  margin-top: 32px;
  flex-flow: column;
  align-items: center;

  :global(.recharts-tooltip-wrapper) {
    z-index: 2000;
  }

  .bbCompareContent {
    width: 100%;
    margin-top: 32px;
    background-color: var(--color-white);
    box-shadow: var(--shadow-sm-1);

    .bbTabContentWrapper {
      margin-top: 20px;

      .bbCommentsLegend {
        display: flex;
        margin-left: 240px;
        align-items: center;
        justify-content: center;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          fill: var(--color-grey-600);
        }

        .bbComments {
          margin-top: 8px;
        }
      }

      .bbStatLegend {
        margin-left: 40%;

        .bbCommentStatLegend {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          font-size: var(--font-size-lg);

          .bbCommentStatLegendItem {
            width: 16px;
            height: 16px;
            min-width: 16px;
            border-radius: var(--border-radius-sm);
            display: inline-block;
            margin: 0 8px 0 25px;
          }

          .bbCommentStatLegendStripeBackground {
            background: linear-gradient(
              135deg,
              #dddfe6 25%,
              #f9f9fa 25%,
              #f9f9fa 50%,
              #dddfe6 50%,
              #dddfe6 75%,
              #f9f9fa 75%,
              #f9f9fa 100%
            );
          }
        }
      }
    }
  }

  .bbDownloadButtonContainer {
    display: flex;
    justify-content: flex-end;

    .bbYTNotAvailable > div {
      align-items: flex-start;
      margin-top: 10px;
      margin-right: 10px;
    }

    .bbDownloadButton {
      width: 40px;
      height: 40px;
      padding-top: 16px;
      justify-content: center;
      border: 1px solid var(--color-surface-5);

      &:hover,
      &:active,
      &:focus {
        background: var(--color-white);
      }

      svg {
        width: 15px;
        height: 15px;
        fill: var(--color-primary-1);
      }
    }
  }

  .bbNoProjectsSelected {
    width: 380px;
    display: flex;
    margin: 150px auto;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .bbNoProjectsIcon {
      width: 104px;
      height: 104px;
      padding: 28px;
      display: flex;
      background: var(--color-white);
      border-radius: 60px;
      box-shadow: var(--shadow-md-2);

      svg {
        fill: var(--color-primary-1);
      }
    }

    .bbNoProjectsText {
      margin-top: 20px;
      font-weight: bold;
      color: var(--color-blue-900);
      font-size: 22px;
    }
  }
}

.bbCompareChartLabel {
  height: 40px;
  cursor: default;
  text-anchor: start;
  font-size: var(--font-size-lg);
  color: var(--color-black);

  .bbCompareChartLabelContainer {
    overflow: hidden;

    .bbCompareChartLabelContent {
      text-align: left;

      .bbLabel {
        bottom: 6px;
        position: relative;
        display: inline-block;
        font-size: var(--font-size-sm);
      }

      .bbDetails {
        bottom: 12px;
        font-size: var(--font-size-sm);
        position: relative;
        color: var(--color-grey-700);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;