.bbNotifications {
  width: 480px;
  position: fixed !important;
  top: 60px !important;
  right: 8px !important;
  max-height: calc(100vh - 90px);
  max-width: calc(100vw - 20px); /* Adjusted for mobile devices */
  overflow: hidden !important;
  border-radius: var(--border-radius-lg) !important;
  box-shadow: var(--shadow-md-2) !important;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-700);
        margin-right: 6px;
        position: relative;
        top: 1px;
      }
    }
  }

  &Title {
    color: var(--color-inverted-surface);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-extrabold);
    line-height: 120%;
  }

  &Empty {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;

    &Img {
      padding: 26px 10px;
    }

    &Texts {
      display: flex;
      width: 320px;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      text-align: center;
      color: var(--color-inverted-surface);

      span:first-child {
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-extrabold);
        line-height: 120%;
      }
      span:last-child {
        color: var(--color-inverted-surface);
        font-size: var(--font-size-lg);
      }
    }

    &Link,
    &Link:hover {
      text-decoration: underline;
      font-weight: var(--font-weight-extrabold);
      color: var(--color-inverted-surface);
    }
  }

  &Trigger {
    cursor: pointer;
    padding: 10px;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--color-primary-850);
      }
    }

    &Unread {
      position: absolute;
      height: 16px;
      left: 10px;
      top: -4px;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px 5px;
      background-color: var(--color-danger-1);
      border-radius: 4999px;
      color: var(--color-white);
      font-size: var(--font-size-xxs);
      font-weight: var(--font-weight-extrabold);
      letter-spacing: 0.1px;
    }

    &Icon {
      position: relative;
      display: flex;
    }

    &Active {
      background-color: var(--color-primary-200);
      border-radius: var(--border-radius-md);
    }
  }

  &Divider {
    margin: 12px 0 !important;
  }

  &Tab {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 250px);
    overflow: auto;

    &Title {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-extrabold);
    }
  }

  &Section {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &Title {
      color: var(--color-inverted-surface);
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
      line-height: 120%;
    }
  }

  &Items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
  }

  &Item {
    display: flex;
    padding: 8px 12px 8px 8px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    position: relative;
    cursor: pointer;
    border-radius: var(--border-radius-md);
    margin-right: 4px;

    &:hover {
      background-color: var(--color-primary-150);

      .bbNotificationsItemBoldTextWithLink {
        text-decoration: none;
        color: var(--color-primary-700);
      }

      .bbNotificationsItemIcon-regular {
        background-color: var(--color-white);
      }

      .bbNotificationsItemIcon-reply_approved {
        background-color: var(--color-secondary-success-1);

        svg,
        path {
          fill: var(--color-success-1);
        }
      }

      .bbNotificationsItemIcon-reply_rejected {
        background-color: var(--color-secondary-danger-1);

        svg,
        path {
          fill: var(--color-danger-1);
        }
      }
    }

    &Messages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
    }

    &Unread {
      width: 8px;
      height: 8px;
      background-color: var(--color-primary-700);
      border-radius: var(--border-radius-xxl);
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    &Desc {
      display: flex;
      align-items: center;
    }

    &Text {
      color: var(--color-inverted-surface);
      font-size: var(--font-size-lg);
      max-width: 310px;
    }

    &BoldText {
      font-weight: var(--font-weight-extrabold);
    }

    &BoldTextWithLink {
      font-weight: var(--font-weight-extrabold);
      text-decoration: underline;
    }

    &Time {
      margin-left: 4px;
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
    }

    &Icon-regular {
      background-color: var(--color-primary-150);

      svg,
      path {
        fill: var(--color-primary-700);
      }
    }

    &Icon-low {
      background-color: #fff2bd;

      svg {
        fill: #eec319;
      }
    }

    &Icon-moderate {
      background-color: var(--color-warning-200);

      svg {
        fill: var(--color-warning-600);
      }
    }

    &Icon-high {
      background-color: var(--color-secondary-danger-2);

      svg {
        fill: var(--color-danger-1);
      }
    }

    &Icon-critical {
      background-color: var(--color-danger-1);

      svg {
        fill: var(--color-white);
      }
    }

    &Icon-reply_approved {
      background-color: var(--color-secondary-success-1);

      svg,
      path {
        fill: var(--color-success-1);
      }
    }

    &Icon-reply_rejected {
      background-color: var(--color-secondary-danger-1);

      svg,
      path {
        fill: var(--color-danger-1);
      }
    }

    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 5000px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &Footer {
    font-size: var(--font-size-lg);
    color: var(--color-on-surface-subtle-1);
    font-weight: var(--font-weight-medium);
    letter-spacing: 0.14px;
  }
}

.bbNotificationsDateTooltip {
  z-index: 99999 !important;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;