.bbPostMediaType {
  display: flex;
  & > * {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    fill: var(--color-on-secondary);
  }
}

.bbPostNoWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bbPostTagsListIcon {
  right: 8px !important;
  width: 12px !important;
  height: 12px !important;
  fill: var(--color-surface-5);

  svg {
    fill: var(--color-surface-5);
  }
}

.bbPostTagsListTag {
  padding-right: 24px;
}

.bbPostViewLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.bbPostViewTitleWrapper {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  font-weight: var(--font-weight-bold);
  line-height: 24px;
  color: var(--color-primary-900);
  font-size: var(--font-size-lg);

  .bbPostViewTitle {
    width: 150px;
  }

  .bbPostViewListeningChipWrapper {
    margin-bottom: 0;

    .bbPostViewListeningChip {
      width: 83px;
      height: 20px;
      border-radius: var(--border-radius-sm);
    }

    .bbPostViewListeningChipIcon {
      left: 15px !important;
    }
  }
}

.bbPostViewDetailsWrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}

.bbPostViewMoreActions {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-surface-5);

  &Hovered,
  &:hover {
    background-color: var(--color-secondary-2);
  }

  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-on-secondary);
  }
}
.bbPostViewDropdown {
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md-2);
  width: 232px;
  padding: 8px;
  &Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
  }

  &Item {
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    align-self: stretch;
    color: var(--color-inverted-surface);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-on-surface-subtle-2);
    }
  }
}

.bbPostViewWrapper {
  position: relative;
  display: flex;
  padding: 0;

  .bbPostViewImagePreview {
    margin-right: 8px;
    border-radius: 4px;

    & > * {
      color: var(--color-grey-700);
    }
  }

  .bbPostViewImagePreviewClickable {
    cursor: pointer;
  }

  .bbPostMessage {
    font-size: var(--font-size-sm);
    color: var(--color-primary-900);
    overflow: hidden;
    line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .bbPostViewInlineAuthor {
    font-weight: bold;
    padding-right: 4px;
    color: var(--color-grey-700);
    font-size: var(--font-size-md);
    border-right: 1px solid var(--color-grey-700);
  }

  .bbPostViewInlineDate {
    padding-left: 4px;
    font-weight: normal;
    font-size: var(--font-size-md);
    color: var(--color-grey-700);
    font-size: var(--font-size-sm);
  }

  .bbPostText {
    color: var(--color-primary-900);
  }

  .bbPostRightSection {
    display: flex;
    flex-direction: column;
    padding: 2px;
    font-size: var(--font-size-lg);

    &Main {
      display: flex;
    }
  }
}

.bbPostAuthorName {
  font-weight: bold;
  color: var(--color-grey-700);
  font-size: var(--font-size-sm);
}

.bbPostAuthorDate {
  font-weight: normal;
  color: var(--color-grey-700);
  font-size: var(--font-size-sm);
}

.bbPostViewTagsWrapper {
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  align-items: center;

  .bbPostViewListeningChipWrapper {
    width: auto;
    margin-bottom: 0;

    .bbPostViewListeningChip {
      width: 83px;
      height: 20px;
      margin-left: 0;
    }

    .bbPostViewListeningChipIcon {
      left: 8px !important;
    }
  }
}

.bbPostTagsListing {
  margin: 4px 0 12px;
}

.bbPostTag {
  display: inline-flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  padding: 1px 6px;
  white-space: nowrap;
  color: var(--color-on-secondary);
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm-2);
  line-height: 18px;

  &MediaType {
    background-color: var(--color-secondary-2);
    box-shadow: none;
  }
}

.bbPostTagsDropdownBtnWrapper {
  top: 1px;
  left: -2px;
  position: relative;
}

.bbPostTagWithTooltip {
  cursor: help;
}

.bbPortraitImage {
  background-color: var(--color-surface-5);

  & > * {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    min-width: min-content;
    border-radius: var(--border-radius-md);
  }
}

.bbPlatformIconSmall {
  width: 24px;
  height: 24px;
  position: relative;
  top: 3px;

  & > * {
    width: 24px;
    height: 24px;
  }
}

.bbPlatformIconLarge {
  width: 29px;
  height: 29px;
  margin-right: 8px;

  & > * {
    width: 29px;
    height: 29px;
  }
}

.bbPostID {
  display: flex;
  align-items: center;
  color: var(--color-on-surface-subtle-1);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-medium);

  * {
    margin-bottom: 0;
  }

  &Number {
    overflow: hidden;
  }
}

.bbPostViewTooltip {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  span.bbPostViewTooltipImage {
    border-radius: var(--border-radius-md);
    min-width: 40px;
  }

  &Info {
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-sm);
    color: var(--color-on-surface-subtle-1);
    max-width: 120px;

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &Name {
    font-weight: var(--font-weight-extrabold);
    color: var(--color-primary-900);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;