.bbAssetTabs {
  text-align: center;
  overflow: visible;

  .bbUserProfile {
    display: inline;
  }

  .bbAssetCardRow {
    margin-top: 30px;
    justify-content: center;
  }

  &ViewTitle {
    font-weight: var(--font-weight-bold);
    font-size: var(--headline-h5-size);
    text-align: center;
    margin-right: 40px;
  }

  &Disclaimer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-grey-700);
    font-size: var(--font-size-xl);
    max-width: 520px;
    margin: 20px auto;
    margin-top: 32px;

    &Icon {
      display: flex;

      svg {
        fill: var(--color-grey-700);
        margin-left: 8px;
        width: 16px;
        height: 16px;
        cursor: help;
      }
    }
  }
}

.bbTabPane {
  &UserImg {
    width: 32px;
    height: 32px;
    display: inline;
    margin-right: 8px;
    border-radius: var(--border-radius-xxl);
    vertical-align: text-bottom;
    & svg {
      width: 24px;
      height: 24px;
      vertical-align: text-bottom;
      padding: 2px;
      background-color: var(--color-white);
      border-radius: var(--border-radius-xxl);
    }
  }

  &UserName {
    font-weight: 400;
    margin-right: 20px;
    color: var(--color-grey-900);
    vertical-align: text-top;
  }

  div[class*="bbTiktokLoginButtonWrapper"] {
    margin-bottom: 12px;
  }
}

@media (max-width: 768px) {
  .bbUserProfileAndBtnWrapper {
    display: flex;
    margin-top: 10px;
    margin-bottom: 22px;
    align-items: center;
    justify-content: center;

    .bbUserProfile {
      display: flex;
      align-items: center;
    }
  }

  .bbAssetTabs {
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;

    &Disclaimer {
      margin: 8px auto;
    }

    .bbTabPaneUserName + div {
      margin-top: 10px;
    }

    .bbAssetCardRow {
      margin-top: 0;
    }

    &ViewTitle {
      max-width: 80px;
      margin-right: 10px;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;