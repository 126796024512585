.bbCustomPromptsList {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &Header {
    &Title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;

      &HelpIcon {
        svg {
          fill: var(--color-on-surface-subtle-2);

          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &ScrollView {
    flex: 1;
    padding: 20px;
  }

  &Name {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

body .bbCustomPromptsForm {
  padding: 0 20px;

  &Panel {
    padding: 12px;
    border-radius: var(--border-radius-lg);
    background-color: var(--color-secondary-1);
  }

  :global(.ant-form-item-has-error) {
    padding-bottom: 32px;
  }

  &Label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    width: 536px;
    &MaxCharacters {
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-sm);
      font-weight: normal;
    }
  }

  &Textarea {
    width: 100%;
    background-color: transparent;
    line-height: 1.5;
    min-height: calc(1.5em * 3 + 12px);

    &:global(.ant-input) {
      min-height: calc(1.5em * 3 + 12px);
    }

    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-surface-5);
    background-color: var(--color-white);
    padding: 6px 8px;
    font-size: var(--font-size-md);
    resize: none;
    overflow: hidden;
    display: block;
    font-weight: normal;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      border: 1px solid var(--color-surface-5);
      box-shadow: none;
    }
  }

  &Heading {
    font-size: var(--headline-h4-size);
    font-weight: var(--font-weight-bold);
    color: var(--color-on-surface);
    margin-bottom: 16px;
  }

  &Description {
    font-size: var(--font-size-sm);
    color: var(--color-on-surface-subtle-1);
    margin-bottom: 12px;

    &Static {
      color: var(--color-on-surface);
      font-size: var(--font-size-md);
    }
  }

  &Hidden {
    display: none;
  }

  &Checkbox {
    margin-bottom: 16px;

    &:global(.ant-form-item) {
      margin-bottom: 16px;
    }
    :global(.ant-form-item-row) {
      display: flex;
      flex-direction: row-reverse;
      column-gap: 8px;
      justify-content: flex-end;
      margin: 4px 0;
    }
    :global(.ant-form-item-control) {
      max-width: fit-content;
      justify-content: center;
    }
    :global(.ant-form-item-control-input-content) {
      max-width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    :global(.ant-form-item-label) {
      padding: 0;
    }
    :global(.ant-form-item-label > label) {
      font-weight: var(--font-weight-bold);
      padding: 0;
      margin-top: 4px;
      cursor: pointer;
    }
    :global(.ant-form-item-label > label .ant-form-item-optional) {
      font-weight: normal;
    }
    :global(input[type="checkbox"]:focus) {
      outline: none;
    }

    &Label {
      display: inline-flex;
      align-items: center;
      gap: 4px;

      &Icon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        svg {
          fill: var(--color-on-surface-subtle-2);
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

body {
  .bbCustomPromptsListHeader {
    padding: 20px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;