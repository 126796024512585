.bbWeekDay {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--color-notApplicable);
  border-bottom: 1px solid var(--color-notApplicable);

  &Header {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 0 4px 8px;
    background-color: var(--color-white);
    border-top: 1px solid var(--color-notApplicable);
    border-bottom: 1px solid var(--color-notApplicable);

    &Day {
      font-size: 15px;
      font-weight: var(--font-weight-medium);
      color: var(--color-grey-700);
    }

    &DaySelected {
      color: var(--color-white);
      padding: 0 8px;
      border-radius: var(--border-radius-xl);
      background-color: var(--color-primary-1);
    }

    &Date {
      font-size: var(--font-size-5xl);
      font-weight: var(--font-weight-bold);
      margin-top: -5px;
    }

    &PostNumber {
      right: 12px;
      width: 20px;
      height: 20px;
      display: flex;
      font-weight: var(--font-weight-bold);
      position: absolute;
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius-xxl);
      font-size: var(--font-size-sm);
      background-color: var(--color-primary-100);
    }
  }

  &HeaderMonday {
    padding-left: 20px;
  }

  &Body {
    flex: 1;
    min-height: 100vh;
    padding: 10px 6px;
    padding-bottom: 140px;

    .bbCreatePostBtn {
      visibility: hidden;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: var(--color-white);
      color: var(--color-blue-900);
      
      svg {
        top: 2px;
        width: 16px;
        height: 16px;
        position: relative;
        margin-right: 6px;
        fill: var(--color-blue-900);
      }
    }

    .bbHolidays {
      margin-bottom: 8px;

      .bbHolidayChip {
        padding: 4px 8px;
        margin-bottom: 4px;
        color: var(--color-primary-850);
        font-size: var(--font-size-sm);
        border-radius: var(--border-radius-md);
        background-color: var(--color-primary-200);
        font-weight: var(--font-weight-bold);
      }
    }
  }

  &BodyFuture {
    &:hover .bbCreatePostBtn {
      visibility: visible;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;