.bbProductTags {
  display: flex;
  flex-direction: column;

  &Alert {
    margin: 16px 28px;
  }

  &Loading {
    height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &SearchInput {
    padding: 16px 28px;
  }

  &Item {
    height: 51.5px;
    padding: 6px 0;
    margin: 0 28px;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 6px;
    border-bottom: 1px var(--color-surface-5) solid;
    &:last-child {
      border-bottom: none;
    }

    &Image {
      width: 40px;
      height: 40px;
      border-radius: var(--border-radius-md);
    }

    &Title {
      display: flex;
      flex-grow: 1;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
      color: var(--color-primary-900);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      padding: 0;
    }

    &Delete {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-on-secondary);
        transition: fill 200ms ease;
      }
      &:hover svg {
        fill: var(--color-primary-1);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;