.bbStep {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 200px;
  min-width: 200px;
  position: relative;

  .bbStepContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-bottom: 4px;
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    line-height: 40px;
    border-radius: 40px;
    background-color: var(--color-white);
    border: 3px solid var(--color-surface-5);
  }

  .bbStepContentTail {
    position: absolute;
    height: 3px;
    display: flex;
    width: calc(100% - 40px);
    left: calc(100% - 80px);

    .bbStepContentTailPart {
      height: 3px;
    }
  }

  .bbStepTitle {
    font-size: var(--font-size-2xl);
    font-weight: 400;
  }

  .bbStepTitleBold {
    font-weight: var(--font-weight-bold);
  }

  @media (max-width: 768px) {
    max-width: 120px;
    min-width: 120px;

    .bbStepContentTail {
      left: calc(100% - 40px);
    }
  }

  @media (max-width: var(--screen-sm-min)) {
    max-width: 100px;
    min-width: 100px;

    .bbStepContentTail {
      left: calc(100% - 30px);
    }
  }

  @media (max-width: 768px) {
    .bbStepTitle {
      display: none;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;