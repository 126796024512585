.bbSearchPanel {
  .bbTimeline {
    margin: 20px 10px 10px 10px;
  }
  .bbBreakdown {
    margin: 10px;
  }
  .bbSearchPanelLoading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: calc(50vh - 154px);
  }

  .bbSearchPanelEmpty {
    margin-top: 110px;
  }

  .bbSearchPanelContent {
    .bbColumnSentiments,
    .bbColumnAggregations {
      width: 100%;
      min-width: 310px;
    }

    .bbSearchPanelDownload {
      margin-right: 10px;
    }
  }

  .bbCardStatContent {
    width: 100%;
    height: 100%;
  }

  .bbSearchPanelEmptyContainer {
    height: 300px;
    margin: 32px 4px 4px 4px;
  }

  &Comments {
    margin: 10px;
    padding: 20px;

    &Header {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }

    &Title {
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
      color: var(--color-primary-1);
      font-size: var(--font-size-xl);
    }

    &Right {
      display: flex;

      .bbDownloadCommentsCSV {
        display: flex;
        padding: 8px 11px;
        margin-left: 20px;
        border-radius: var(--border-radius-md);
        align-items: center;
        justify-content: center;
        background-color: var(--color-white);
        border: 1px solid var(--color-surface-5);

        svg {
          width: 13px;
          height: 13px;
          fill: var(--color-primary-1);
        }

        &:hover {
          background-color: var(--color-primary-100);
        }
      }
    }
  }

  div.bbSearchPanelCommentsSort {
    width: auto;
    min-width: 160px;

    :global {
      .ant-select-selector {
        border-radius: var(--border-radius-md);
        color: var(--color-blue-900);
      }
    }
  }
}

.bbSentimentContainer {
  margin: 20px 10px 10px 10px;

  .bbSearchPanelRootCard {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    :global(.recharts-wrapper) {
      margin: auto;
    }

    .bbSearchPanelCardLoading {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    .bbSearchPanelCardEmpty {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

@media (max-width: 767px) {
  .bbSentimentContainer {
    padding: 0 !important;

    div[class*="bbCardHeader--"] {
      margin: 0;
      padding: 15px;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;