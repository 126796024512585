@comment-card-max-width: 600px;
@comment-card-min-width: 440px;
@gap: 20px;

.bbCommentsLoader {
  display: block;
  margin-top: 2rem;
}

.bbCommentsGrid {
  gap: 20px;
  display: grid;
  grid-template-columns: ~"repeat(auto-fit, minmax(min(100%, max(440px, 100% / 3)), 1fr))";
  margin: auto;
  margin-top: 60px;

  @media screen and (min-width: 500px) {
    max-width: calc(@comment-card-max-width * 2 + @gap);
    min-width: @comment-card-min-width;
  }

  .bbCommentsGridItem {
    &.bbCommentsGridItemGap7 {
      grid-row-end: span 7;
    }

    &.bbCommentsGridItemGap8 {
      grid-row-end: span 8;
    }

    &.bbCommentsGridItemGap9 {
      grid-row-end: span 9;
    }

    &.bbCommentsGridItemGap10 {
      grid-row-end: span 10;
    }

    &.bbCommentsGridItemGap11 {
      grid-row-end: span 11;
    }

    &.bbCommentsGridItemGap12 {
      grid-row-end: span 12;
    }

    &.bbCommentsGridItemGap13 {
      grid-row-end: span 13;
    }

    &.bbCommentsGridItemGap14 {
      grid-row-end: span 14;
    }

    &.bbCommentsGridItemGap15 {
      grid-row-end: span 15;
    }

    &.bbCommentsGridItemGap16 {
      grid-row-end: span 16;
    }

    &.bbCommentsGridItemGap17 {
      grid-row-end: span 17;
    }

    &.bbCommentsGridItemGap18 {
      grid-row-end: span 18;
    }

    &.bbCommentsGridItemGap19 {
      grid-row-end: span 19;
    }

    .bbCommentCardHeaderAssignUserTrigger {
      border: 1px solid var(--color-surface-5);
    }
  }

  .bbCommentsGridItemBatchMode {
    height: auto;
  }

  &ItemWithPostPreview {
    display: flex;
    align-items: center;

    & > div:first-child {
      min-width: 600px;

      @media screen and (max-width: 1300px) {
        min-width: 500px;
      }

      @media screen and (max-width: 500px) {
        min-width: auto;
      }
    }
  }
}

@media screen and (max-width: var(--screen-xs)) {
  .bbCommentsGrid {
    grid-template-columns: 1fr;
    min-width: 250px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;