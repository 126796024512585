@file-default-color: #666666;
@file-default-opacity: 0.9;

.bbPDFPreviewWrapper {
  display: flex;
  align-items: center;
  height: 380px;
  position: relative;

  .bbPDFPreviewElement {
    width: 100%;
    height: 38px;
    z-index: 10;
    position: absolute;
    display: flex;
    align-items: center;
    padding-left: 15px;
    opacity: @file-default-opacity;
    background: @file-default-color;
    color: var(--color-white);
    font-size: var(--font-size-lg);
  }

  .bbPDFPreviewHeader {
    top: 0;

    span {
      margin-right: 6px;
    }
  }

  .bbPDFPreviewFooter {
    bottom: 0;
  }

  .bbPDFNavigation {
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    z-index: 10;
    padding-top: 8px;
    padding-bottom: 8px;

    &Btn {
      position: absolute;

      &:focus {
        z-index: 20;
      }

      &Left {
        left: 8px;
      }

      &Right {
        right: 8px;
      }

      &Content {
        width: 35px;
        height: 35px;
        display: flex;
        border-radius: 35px;
        align-items: center;
        justify-content: center;
        opacity: @file-default-opacity;
        background: @file-default-color;

        svg {
          width: 22px;
          fill: var(--color-white);
        }
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;