:local(.bbCardStatContent) {
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

:local(.bbCardStatTooltip) {
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--color-white);;
  font-size: var(--font-size-lg);
  padding: 4px;
}

:local(.bbCardStatLeftContainer) {
  display: flex;
  flex-direction: column;

  :local(.bbCardStatValue) {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xl);
  }

  :local(.bbCardStatName) {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xl);
  }
}

:local(.bbCardChartContainer) {
  width: calc(100% - 280px);
  max-width: 200px;
  min-width: 80px;
  margin: 4px 0px 0 0px;
  text-align: center;

  // overriding chart inline styles
  :global(.recharts-wrapper) {
    cursor: help !important;
  }
}

:local(.bbCardStatRightContainer) {
  display: flex;
  align-items: center;

  :local(.bbCardStatGraphContainer) {
    margin-right: 16px;
    margin-left: 16px;
  }

  :local(.bbCardStatPreviousValueContainer) {
    display: flex;
    flex-direction: column;
    height: 55px;

    :local(.bbCardStatPreviousValue) {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-xl);
    }

    :local(.bbCardStatPreviousValuePercent) {
      width: 74px;
      cursor: help;
      color: var(--color-white);;
      padding-left: 4px;
      text-align: center;
      padding-right: 4px;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;