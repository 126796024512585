.bbAutomatedHidingMenu {
  margin: 0;
  padding: 20px;
  display: flex;
  min-width: 210px;
  flex-flow: column;
  position: relative;
  border-radius: var(--border-radius-md);
  align-items: flex-start;
  background-color: var(--color-white);
  box-shadow: var(--shadow-sm-2);

  h3 {
    display: flex;
    line-height: 28px;
    font-style: normal;
    font-weight: 700;
    color: var(--color-grey-900);
    font-size: var(--headline-h5-size);
  }

  &Icon {
    display: flex;
    align-self: center;

    svg {
      width: 16px;
      height: 16px;
      cursor: help;
      margin-left: 5px;
      fill: var(--color-grey-500);
    }
  }

  &Tooltip {
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    font-weight: 400;
    color: var(--color-orange-600);
    font-size: var(--font-size-lg);
  }

  .bbAutomatedHidingMenuDisclaimer {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--color-orange-200);
    color: var(--color-orange-800);
    font-size: var(--font-size-sm);
    padding: 6px 12px;
    width: 100%;
    border-radius: var(--border-radius-lg);
    margin-top: 20px;

    svg > {
      width: 24px;
      height: 24px;
      fill: var(--color-orange-800);
    }
  }

  .bbAutomatedHidingMenuItems {
    width: 100%;
    margin-top: 4px;

    .bbAutomatedHidingMenuItem {
      display: flex;
      margin: 16px 0;
      align-items: center;
      justify-content: space-between;

      .bbAutomatedHidingLabelWrapper {
        display: flex;
        align-items: center;
        column-gap: 8px;

        &Disabled {
          opacity: 0.5;
        }
      }

      &Label {
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-lg);
      }
    }
  }

  .bbPlatformsDisclaimer,
  .bbAssetsDisclaimer,
  .bbPostTypesDisclaimer {
    margin-top: 4px;
    font-size: var(--font-size-sm);
  }

  .bbAssetsFilter {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &Platforms {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
    width: 240px;

    .bbPlatformWrapper {
      width: 44px;
      height: 44px;
      margin-right: 8px;
      margin-bottom: 8px;

      .bbPlatformIconDisabled {
        cursor: help;
      }

      .bbPlatformIcon {
        > svg:first-child {
          width: 24px;
          height: 24px;
        }
      }

      .bbPlatformIconYoutube {
        > svg:first-child {
          width: 29px;
          height: 29px;
        }
      }

      span[class*="bbSelectableTag--"] {
        height: 44px;
        width: 44px;
        border-width: 2px;

        > svg:first-child {
          fill: var(--color-blue-850);
        }

        &:not(.selected) {
          > svg:first-child {
            fill: var(--color-grey-700);
          }
        }

        &:hover {
          > svg:first-child {
            fill: var(--color-primary-800);
          }
        }

        span[class*="bbSelectableTagCheckIcon--"] {
          left: 34px;

          svg {
            fill: var(--color-white);
          }
        }
      }
    }
  }

  .bbPlatformWrapperDisabledTooltip {
    cursor: help;
  }

  .bbTwitterDisclaimer {
    gap: 8px;
    width: 100%;
    display: flex;
    font-weight: var(--font-weight-bold);
    margin-top: 20px;
    padding: 6px 12px;
    border-radius: var(--border-radius-lg);
    align-items: center;
    font-size: var(--font-size-sm);
    color: var(--color-orange-800);
    background-color: var(--color-orange-200);

    svg {
      width: 24px;
      height: 24px;
      fill: var(--color-orange-800);
    }
  }

  .bbNotAnAdminDisclaimer {
    margin-top: 4px;
  }

  .bbSaveChangesButton {
    width: 100%;
    margin-top: 20px;
    justify-content: center;
  }

  .bbAutomatedHidingKeywords {
    :global {
      #keywords-dropdown-input-wrapper {
        border: none;
      }
    }

    .bbAutomatedHidingKeywordsMenu {
      width: 100%;
    }
  }
}

.bbAutomatedHidingSettingsButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  margin: 6px 0 0 0;
  color: var(--color-on-surface);
  cursor: pointer;
  background: var(--color-white);
  border: none;

  &Label {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 6px;
    height: 16px;
    font-weight: var(--font-weight-bold);
    cursor: pointer;
    font-size: var(--font-size-xl);

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-on-surface-subtle-2);
    }
  }

  &Icon {
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
      fill: currentColor;
    }
  }

  &Expanded {
    margin: 6px 0 12px;

    .bbAutomatedHidingSettingsButtonIcon {
      transform: rotate(180deg);
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;