.bbTag {
  position: absolute;
  width: 0;
  height: 0;

  &Arrow {
    position: absolute;
    top: 0;
    left: -6px;
    display: block;
    content: "";
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent var(--color-grey-900) transparent;
    transform: rotate(0deg);
  }

  &Inner {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, 6px);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    padding: 0 4px;
    border-radius: var(--border-radius-sm);
    height: 32px;
    background: var(--color-grey-900);
    opacity: 0.9;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
    white-space: nowrap;
    pointer-events: auto;
    cursor: move;
    transition: transform 150ms ease-in;

    > svg {
      width: 16px;
      height: 16px;
      fill: var(--color-white);
    }

    > button {
      width: 20px;
      height: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      svg {
        width: 14px;
        height: 14px;
        fill: var(--color-white);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;