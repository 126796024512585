.bbInfoSection {
  display: flex !important;
  background-color: #eff4fe;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  &Logo {
    position: absolute;
    top: 32px;
    left: 32px;
    z-index: 10;
    display: flex;
    gap: 14px;

    > svg {
      width: 192px;
    }
  }

  &TrustedBy {
    display: flex;
    justify-content: center;
    bottom: 32px;
    position: absolute;
  }

  &Copy {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 265px;
    height: 130px;
    position: absolute;
    text-align: center;

    &ManagedPilot {
      width: 320px;
    }

    &Header {
      font-weight: var(--font-weight-bold);
      line-height: 42px;
      color: var(--color-grey-900);
      font-size: var(--headline-h3-size);
    }

    &SubHeader {
      margin-top: 12px;
      color: var(--color-grey-700);
      font-size: var(--font-size-xl);
    }
  }

  h1,
  h4,
  h5,
  small {
    color: var(--color-white);
  }

  h1 {
    font-size: 50px;
  }

  h5 {
    font-weight: normal;
  }

  small {
    display: block;
    text-align: center;
  }

  :global(.ant-typography + h5.ant-typography) {
    margin-top: 0;
  }

  :global(.ant-input-password) {
    padding: 0;
    height: 40px;
    width: 400px;
  }
}

.bbFormXL {
  max-width: 400px;

  :global {
    .ant-form-item-label label {
      font-weight: bold;
      font-size: var(--font-size-2xl);
    }

    .ant-input,
    .ant-input-number {
      font-size: var(--font-size-2xl);
    }

    .ant-form-item-explain {
      margin-top: 0;
      margin-bottom: 0;
      font-size: var(--font-size-lg);
    }
  }
}

.buttonXL {
  height: 40px;
  width: 400px;
  display: flex;
  padding-top: 6px;
  justify-content: center;
}

.buttonGradient {
  border: 0;
  display: flex;
  padding: 8px 16px 8px 20px;
  background: linear-gradient(90deg, var(--color-gradient-1) 0%, var(--color-gradient-2) 100%);
}

.rightIcons {
  display: flex;
  width: 16px;
  fill: var(--color-primary-1);
}

:global {
  .ant-form-item {
    margin-top: 20px;

    .ant-form-item-label {
      padding-bottom: 5px;

      label {
        font-weight: 400;
        color: var(--color-grey-900);
        font-size: var(--font-size-lg);
      }
    }
  }
}

input[name="email"],
input[name="username"],
input[name="first_name"],
input[name="last_name"],
input[name="company_name"],
span[class*="InputPassword"] {
  width: 400px;

  svg {
    width: 18px;
    height: 18px;
    fill: var(--color-blue-900);
  }
}

span[class*="ant-input-password "] {
  border: 1px solid var(--color-grey-300);
  border-radius: var(--border-radius-md);
  height: 40px;
}

.bbFormSM {
  input[name="email"],
  input[name="username"],
  input[name="first_name"],
  input[name="last_name"],
  input[name="company_name"],
  span[class*="InputPassword"] {
    width: 100%;
  }
}

.buttonSM {
  width: 100%;
}

@media (max-width: 768px) {
  .bbInfoSection {
    input[name="email"],
    input[name="username"],
    input[name="first_name"],
    input[name="last_name"],
    input[name="company_name"],
    span[class*="InputPassword"] {
      width: stretch;
      width: -moz-available;
      width: -webkit-fill-available;
    }

    .buttonXL {
      width: stretch;
      width: -moz-available;
      width: -webkit-fill-available;
    }

    div[class*="bbInfoSectionLogo"] {
      justify-content: center;
      top: 20px;
      left: 0;
      right: 0;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;