.bbDisclaimer {
  margin-top: 16px;
}

.bbDisclaimerActionBtn {
  margin-left: 4px;
  border: none;
  box-shadow: none;
  font-size: var(--font-size-lg);
  color: var(--color-on-primary-btn);

  &:focus {
    box-shadow: none;
  }
}

.bbMissingAssetsDislaimerAction,
.bbPaymentFailedAction {
  margin-left: 12px;
  border-radius: 6px;
  background-color: white;
  padding: 5px 14px;
  font-weight: bold;
  color: var(--color-primary-700);
}

.bbPaymentFailedIcon {
  display: flex;
  fill: white;

  svg {
    fill: white;
  }
}

.bbPendingAssetsDisclaimerIcon {
  display: flex;

  svg {
    animation: spin 1s linear infinite;
    fill: var(--color-blue-600);
  }
}

.bbReconnectAssetsDisclaimerAction {
  font-weight: 900;
  color: white;
  margin-left: 12px;
  font-size: var(--font-size-lg);
  text-decoration: underline;
}

.bbPendingAssetsDisclaimerAction {
  margin-left: 12px;
  text-decoration: underline;
  color: var(--color-primary-700);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;