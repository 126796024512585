.bbPost {
  padding: 8px;
  margin-bottom: 8px;
  position: relative;
  border-radius: var(--border-radius-md);
  background-color: var(--color-white);
  box-shadow: var(--shadow-sm-1);

  &Available:hover {
    cursor: pointer;
    box-shadow: var(--shadow-md-2);
  }

  &Picture {
    width: 100%;
    height: auto;
    border-radius: var(--border-radius-sm);
  }

  &CaptionCropped {
    font-size: var(--font-size-md);
    margin-top: 4px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-blue-900);
  }

  &Caption {
    font-size: var(--font-size-md);
    margin-top: 24px;
    color: var(--color-blue-900);
    word-break: break-word;
  }

  .bbPlatformIcon {
    width: 18px;
    height: 18px;
    top: 6px;
    left: 6px;
    position: absolute;
    cursor: help;
  }

  &Time {
    padding: 1px 6px;
    border-radius: var(--border-radius-xl);
    background-color: var(--color-grey-100);
    box-shadow: var(--shadow-sm-1);
    font-size: var(--font-size-xs);
    display: inline-block;
    position: absolute;
    top: 6px;
    right: 6px;
  }

  &MediaType {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    border-radius: var(--border-radius-xxl);
    background-color: var(--color-grey-100);
    font-size: var(--font-size-xs);
    position: absolute;
    top: 6px;
    right: 6px;
    box-shadow: var(--shadow-sm-1);

    svg {
      width: 12px;
      height: 12px;
      fill: var(--color-grey-600);
    }
  }

  .bbSentimentBarWrapper {
    margin-top: 10px;
    cursor: help;
  }

  &Metrics {
    display: flex;
    font-size: var(--font-size-xxs);
    margin-top: 8px;
    line-height: 15px;
    color: var(--color-blue-900);
    justify-content: space-around;

    svg {
      width: 12px;
      height: 12px;
      fill: var(--color-blue-900);
      margin-right: 4px;
    }

    .bbPostComments,
    .bbPostLikes,
    .bbPostShares {
      display: flex;
      align-items: center;
    }
  }

  .bbPostTime {
    top: 8px;
    right: 12px;

    svg {
      width: 12px;
      height: 12px;
      margin-right: 2px;
      fill: var(--color-grey-600);
      position: relative;
      top: 2px;
    }
  }

  .bbPostRepublishingLoading {
    left: 50%;
    top: 46%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &Draft {
    background-color: var(--color-surface-5);

    .bbPostDraftBadge {
      padding: 1px 6px;
      color: var(--color-white);
      border-radius: var(--border-radius-xl);
      background-color: var(--color-grey-600);
      box-shadow: var(--shadow-sm-1);
      font-size: var(--font-size-xs);
      display: inline-block;
      position: absolute;
      top: 6px;
      right: 6px;

      svg {
        width: 12px;
        height: 12px;
        fill: var(--color-white);
        margin-right: 4px;
        position: relative;
        top: 2px;
      }
    }

    .bbPostCaption,
    .bbPostCaptionCropped {
      color: var(--color-grey-700);
    }

    .bbGradientOverlay {
      opacity: 0.2;
    }

    .bbPostPictureWrapper {
      img {
        opacity: 0.5;
      }
    }

    .bbPostVideoWrapper {
      video {
        opacity: 0.5;
      }
    }
  }
}

.bbPostWithPreview {
  .bbPostPictureWrapper {
    max-height: 134px;
    overflow: hidden;
    border-radius: var(--border-radius-sm);
    display: flex;
    align-items: center;
    position: relative;
  }

  .bbPostVideoWrapper {
    max-height: 134px;
    overflow: hidden;
    border-radius: var(--border-radius-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .bbPostVideo {
      width: 100%;
      height: auto;
      border-radius: var(--border-radius-sm);
    }
  }

  .bbGradientOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    opacity: 0.4;
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  }

  .bbPlatformIcon {
    top: 12px;
    left: 12px;
    cursor: help;
  }

  .bbPostTime {
    top: 12px;
    right: 36px;
    background-color: var(--color-white);

    svg {
      width: 12px;
      height: 12px;
      margin-right: 2px;
      fill: var(--color-grey-600);
      position: relative;
      top: 2px;
    }
  }

  .bbPostMediaType {
    top: 12px;
    right: 12px;
    background-color: var(--color-white);
  }

  .bbPostDraftBadge {
    top: 12px;
    right: 36px;
  }
}

.bbPostWithError {
  cursor: pointer;
  border: 2px solid var(--color-red-700);

  .bbTextPostCaptionWrapper {
    display: flex;
    justify-content: space-between;

    .bbPostError {
      margin-top: 24px;
      position: relative;
      bottom: unset;
      right: unset;
    }
  }

  .bbPostCaption,
  .bbPostCaptionCropped {
    color: var(--color-red-700);
  }

  .bbGradientOverlay {
    opacity: 0.2;
  }

  .bbPostPictureWrapper {
    img {
      opacity: 0.5;
    }
  }

  .bbPostVideoWrapper {
    video {
      opacity: 0.5;
    }
  }

  .bbPostError {
    width: 20px;
    height: 20px;
    right: 6px;
    bottom: 6px;
    position: absolute;
    background-color: var(--color-red-700);
    display: flex;
    justify-content: center;
    border-radius: 20px;

    svg {
      width: 12px;
      height: 12px;
      fill: var(--color-white);
      position: absolute;
      bottom: 4.5px;
    }
  }
}

.bbErrorTooltip {
  font-size: var(--font-size-sm);

  .bbErrorMessage {
    font-size: var(--font-size-xxs);
  }

  .bbTooltipDivider {
    width: 100%;
    height: 1px;
    margin-top: 6px;
    margin-bottom: 6px;
    background-color: var(--color-grey-700);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;