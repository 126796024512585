.bbStoryPreview {
  height: 476px;
  margin-top: 20px;
  margin-left: 10px;
  display: inline-flex;
  background: var(--color-black);
  border-radius: var(--border-radius-xl);

  .bbStoryWrapper {
    height: 416px;
    max-width: 240px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-xl);
    background: var(--color-black);
    position: relative;
    overflow: hidden;

    video {
      max-width: 240px;
      min-width: 240px;
      max-height: 426px;
    }

    .bbGradientOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.12) 0%,
        rgba(0, 0, 0, 0) 12.5%,
        rgba(0, 0, 0, 0) 78.99%,
        rgba(0, 0, 0, 0.3) 93.31%
      );
    }
  }

  .bbTopBar {
    position: absolute;
    top: 12px;
    left: 12px;

    .bbStoryProgressBorder {
      width: 216px;
      border-radius: var(--border-radius-md);
      background: rgba(255, 255, 255, 0.5);

      .bbStoryProgressFill {
        width: 60%;
        height: 2px;
        border-radius: var(--border-radius-md);
        background-color: var(--color-white);
      }
    }

    .bbAssetImageWrapper {
      display: flex;
      margin-top: 9px;
      align-items: center;

      .bbAssetImage {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background: var(--color-grey-300);

        img {
          min-width: 20px;
          max-width: 20px;
          min-height: 20px;
          max-height: 20px;
          border-radius: 16px;
        }
      }

      .bbPageName {
        color: var(--color-white);
        font-size: 9px;
        margin-left: 7px;
        margin-right: 7px;
      }

      .bbPostedTimeLabel {
        color: var(--color-white);
        opacity: 0.7;
        font-size: 9px;
      }
    }

    .bbCloseIcon {
      svg {
        width: 15px;
        height: 15px;
        fill: var(--color-white);
        position: absolute;
        right: 0;
        top: 13px;
      }
    }
  }

  .bbBottomBar {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    .bbSendMessageBox {
      height: 26px;
      padding: 13px;
      padding-right: 70px;
      border-radius: 20px;
      color: var(--color-white);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid var(--color-grey-700);
      font-size: var(--font-size-sm);
      white-space: nowrap;
    }

    .bbActionIcon {
      width: 18px;
      height: 18px;
      margin-left: 12px;

      svg {
        fill: var(--color-white);
        width: 19px;
        height: 17px;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;