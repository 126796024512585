.bbYouTubeOptions {
  &InlineAlert {
    margin-bottom: 12px;
  }

  &InlineWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    :global(.ant-row.ant-form-item) {
      flex: 1;
    }
  }

  &Checkbox {
    :global(.ant-form-item-row) {
      display: flex;
      flex-direction: row-reverse;
      column-gap: 8px;
      justify-content: flex-end;
      margin: 4px 0;
    }
    :global(.ant-form-item-control) {
      max-width: fit-content;
      justify-content: center;
    }
    :global(.ant-form-item-control-input-content) {
      max-width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    :global(.ant-form-item-label > label) {
      font-weight: var(--font-weight-bold);
      margin-top: 4px;
    }
    :global(.ant-form-item-label > label .ant-form-item-optional) {
      font-weight: normal;
    }
    :global(input[type="checkbox"]:focus) {
      outline: none;
    }
  }

  :global(.ant-form-item) {
    margin: 0 0 20px 0;
    flex: 1;
    flex-wrap: nowrap;
  }

  :global(.ant-form-item-label) {
    padding: 0 0 2px;
  }
  :global(.ant-form-item-control-input) {
    min-height: auto;
  }

  :global(.ant-form-item-explain-error) {
    font-size: var(--font-size-md);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;