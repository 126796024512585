.bbXPostPreviewWrapper {
  display: flex;
  justify-content: center;
  .bbXPostPreview {
    max-height: 620px;
    padding: 0 12px;

    .bbImagePreviewWrapper {
      position: relative;
      margin-left: 50px;
      margin-right: 14px;
      border-radius: 13px;
      overflow: hidden;
      border: 1px solid #d1d9dd;
    }

    .bbXPostPreviewFooter {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 12px 14px 0 50px;
    }

    div[class*="bbPostCard--"] {
      height: auto;
      width: 420px;
      max-width: 420px;
      padding: 12px 0;

      div[class*="bbPostInfo"] {
        flex-flow: row;

        span {
          margin-right: 4px;
          font-size: var(--font-size-lg);
        }

        small {
          margin-right: 4px;
          font-size: var(--font-size-sm);
        }
      }

      div[class*="bbPostHeader"] {
        position: absolute;
        padding: 0 12px;
      }

      p[class*="bbPostMessage"] {
        padding: 0 12px;
        min-height: auto;
        margin-top: 12px;
        margin-left: 35px;
        padding: 10px 12px 0 12px;
        font-size: var(--font-size-lg);
      }
    }

    div[class*="bbPostCardContainer"] {
      min-height: auto;
      padding-bottom: 0;
    }

    .bbVideoPlayerPreview {
      max-height: 356px;
      position: relative;
      background-color: var(--color-black);

      video {
        max-height: 356px;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }

    .bbLinkPreview {
      &Meta {
        padding: 12px;
        line-height: 15px;
        font-size: var(--font-size-xxs);
        background-color: var(--color-grey-100);

        &Url {
          margin-left: 50px;
          color: #676f85;
          font-size: var(--font-size-md);
          height: 20px;
        }

        &Title {
          position: absolute;
          bottom: 10px;
          left: 10px;
          color: var(--color-white);
          background-color: #3a3a3b;
          padding: 2px 4px;
          font-size: var(--font-size-sm);
          max-width: 330px;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          overflow: hidden;
          border-radius: var(--border-radius-sm);
        }
      }
    }

    .bbImgWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background-color: #f3f3f6;
      min-height: 183px;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }

      svg {
        width: 28px;
        height: 28px;
        fill: #858ea6;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;