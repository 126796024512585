.bbLayoutContentWithAIModal {
  width: 270px;
}

.bbTrialSubscription {
  display: flex;
  align-items: center;
}

.bbTrialCounters {
  margin-right: 20px;
  margin-left: 12px;
  cursor: help;
}

.bbUpgradePlanButton {
  margin-left: 4px;
  margin-right: 4px;
  line-height: 20px;
}

.bbUpgradePlanButtonTT {
  color: var(--color-primary-700);

  &:active {
    color: var(--color-primary-700);
  }
}

.bbUpgradePlanButtonIcon {
  display: flex;

  svg {
    margin-right: 4px;
    width: 16px;
    height: 16px;
    fill: var(--color-primary-700);
  }
}

.bbKBStonlyWidget {
  padding: 10px;
  border-radius: var(--border-radius-md);
  display: flex;
  cursor: pointer;

  &:hover {
    background: var(--color-primary-200);
  }

  > svg {
    width: 20px;
    height: 20px;
    fill: var(--color-primary-850);
  }
}

.bbSubscriptionUpgrade {
  width: 100%;

  &CircleWrapper {
    display: flex;
    align-items: center;
  }

  &TrialEndsText {
    margin-left: 8px;
  }

  &Btn {
    margin-top: 8px;
  }
}

.bbMobileFiltersModal {
  overflow: hidden !important;

  &Root {
    max-width: 100vw !important;
    padding: 0 !important;
    top: 20px !important;
  }
}

.upgrade-plan {
  height: 90px !important;
  cursor: default;
  margin-top: -10px;
  color: var(--color-on-surface);
  font-size: var(--font-size-lg);

  &:hover {
    color: var(--color-on-surface);
  }

  button {
    width: 100%;
    justify-content: center;
  }
}

.bbMobileFiltersModalRoot {
  max-width: 100vw !important;
  padding: 0 !important;
  top: 20px !important;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;