.bbBreakdownChartTooltip {
  text-align: left;
  padding: 12px 0px;
  background-color: var(--color-white);
  border: 1px solid var(--color-surface-5);
  box-shadow: var(--shadow-md-1);

  .bbItemName {
    text-align: left;
    margin: 8px 12px;
    font-weight: bold;
  }

  .bbStatsContainer {
    display: flex;

    .bbStatContainer {
      display: flex;
      margin: 0 16px;
      align-items: center;

      .bbStatSquare {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        border-radius: var(--border-radius-sm);
      }

      .bbStatItem {
        display: flex;
        flex-direction: column;

        .bbStatName {
          font-weight: bold;
        }

        .bbBreakdownLegendSentiments {
          display: flex;
          margin-top: 5px;
          align-items: center;
          justify-content: center;

          div[class*="bbLegend"] {
            width: 16px;
            height: 16px;
            border-radius: var(--border-radius-sm);
            display: inline-block;
            margin: 0 8px 0 25px;
          }

          .bbLegendNegative {
            background-color: var(--color-red-700);
          }

          .bbLegendPositive {
            background-color: var(--color-green-600);
          }

          .bbLegendUserTags {
            background-color: var(--color-blue-900);
          }

          .bbLegendNeutral {
            background-color: var(--color-grey-300);
          }
        }
      }

      svg[data-icon="chart-bar"] {
        transform: rotate(90deg) scaleX(-1);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;