@reel-preview-max-width: 240px;
@reel-preview-actions-padding: 16px;

.bbReelPreview {
  margin-top: 20px;
  margin-left: 10px;
  display: inline-flex;

  .bbReelWrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: @reel-preview-max-width;
    max-height: 426px;
    min-height: 426px;
    border-radius: var(--border-radius-xl);
    background: var(--color-black);
    position: relative;
    overflow: hidden;

    > video,
    > img {
      max-width: @reel-preview-max-width;
      min-width: @reel-preview-max-width;
      max-height: 426px;
    }

    > img {
      height: 100%;
      object-fit: cover;
    }

    .bbGradientOverlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.12) 0%,
        rgba(0, 0, 0, 0) 12.5%,
        rgba(0, 0, 0, 0) 78.99%,
        rgba(0, 0, 0, 0.3) 93.31%
      );
      svg {
        fill: var(--color-white);
        width: 108px;
        height: 116px;
      }
    }
  }

  .bbRightVerticalActions {
    position: absolute;
    right: 18px;
    top: 0;

    svg {
      fill: var(--color-white);
      stroke: var(--color-white);
    }

    .bbPhotoIcon {
      margin-top: 16px;
      margin-bottom: 190px;

      svg {
        width: 18px;
        height: 16px;
      }
    }

    .bbLikeAction {
      margin-bottom: 14px;

      svg {
        width: 18px;
        height: 16px;
      }
    }

    .bbCommentAction {
      margin-bottom: 14px;

      svg {
        width: 18px;
        height: 16px;
      }
    }

    .bbShareAction {
      margin-bottom: 14px;

      svg {
        width: 17px;
        height: 16px;
      }
    }

    .bbMoreAction {
      margin-bottom: 14px;

      svg {
        transform: rotate(90deg);
      }
    }

    .bbAssetImage {
      width: 18px;
      height: 18px;
      border-radius: var(--border-radius-sm);
      border: 1.5px solid white;
      display: flex;
      overflow: hidden;
      position: relative;
      left: -1px;
      top: -1px;

      img {
        border-radius: 0;
        min-width: 16.5px;
        max-width: 16.5px;
        min-height: 16.5px;
        max-height: 16.5px; // width - border-radius
      }
    }
  }

  .bbBottomHorizontalActions {
    position: absolute;
    text-align: left;
    left: @reel-preview-actions-padding;
    bottom: @reel-preview-actions-padding;
    max-width: calc(@reel-preview-max-width - 2 * @reel-preview-actions-padding);

    .bbAssetImage {
      display: flex;
      align-items: center;
      font-size: var(--font-size-xs);
      font-weight: var(--font-weight-bold);
      color: var(--color-white);

      img {
        margin-right: 6px;
      }
    }

    .bbCaption {
      color: var(--color-white);
      font-size: var(--font-size-xxs);
      text-overflow: ellipsis;
      margin-top: 8px;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
    }

    .bbAudioWrapper {
      overflow: hidden;
      white-space: nowrap;

      .bbAudio,
      .bbLocation {
        display: inline;
        padding: 2px 8px;
        border-radius: var(--border-radius-xl);
        background: rgba(19, 21, 27, 0.5);
        color: var(--color-white);
        font-size: var(--font-size-xxs);
        margin-top: 8px;
        margin-right: 4px;

        svg {
          top: 1px;
          margin-right: 4px;
          position: relative;
          width: 8px;
          height: 8px;
          fill: white;
        }
      }
    }

    .bbAudioAndLocationWrapper {
      width: 88%;
      -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;