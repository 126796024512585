.bbSignInContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  .bbSignInForm {
    min-height: 340px;
    max-width: 400px;

    label[for="signIn_password"] {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;

      span {
        flex: 1;
      }
    }

    .bbSignUpQ {
      color: var(--color-grey-700);
    }

    .bbSignUpQ,
    .bbSignUpBtn {
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
      font-size: var(--font-size-2xl);
    }

    .bbSignUpBtn:hover {
      text-decoration: underline;
    }

    .bbForgotPasswordLink:hover {
      background: var(--color-white);
      text-decoration: underline;
    }
  }

  .bbSignUpSection {
    text-align: center;
  }

  .bbSignInButton {
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 20px;
    background: linear-gradient(90deg, var(--color-gradient-1), 0%, var(--color-gradient-2) 100%);
    border-radius: var(--border-radius-md);
    border: 0;
  }
}

@media (max-width: 768px) {
  .bbSignInContainer {
    align-items: unset;

    .bbSignInForm {
      width: 100%;
    }

    div[class*="bbFormHeader"] {
      margin-top: 70px;
      margin-bottom: 20px;
    }

    .bbEmailInput {
      & input {
        width: 100%;
      }
    }

    .bbSignInBtn {
      width: 100%;
    }

    .bbSignInForm {
      :global {
        .ant-form-item .ant-form-item-label {
          padding-bottom: 0;
        }
      }
    }
  }

  div[class*="bbInfoSectionLogo"] {
    justify-content: center;
    top: 20px;
    left: 0;
    right: 0;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;