@story-post-height: 118px;

.bbListeningChipWrapper {
  cursor: help;
}

.bbCommentPostPreview {
  width: 120px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-sm-2);
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &Image {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-sm);
    max-height: 80px;
    overflow: hidden;
    position: relative;

    &Avatar {
      border-radius: var(--border-radius-sm);
      // overriding antd inline styles
      height: 80px !important;
      width: 100% !important;
      color: var(--color-grey-700) !important;
    }

    img {
      border-radius: var(--border-radius-sm);
      height: auto;
      width: 100%;
    }

    &Story {
      max-height: @story-post-height;
      width: 100%;
      background-color: var(--color-surface-5);

      img {
        width: auto;
        margin: 0 auto;
        height: @story-post-height;
      }
    }
  }

  &Info {
    padding: 8px;

    .bbPostInsights {
      display: flex;
      align-items: center;

      .bbPostInsightsItem {
        display: flex;
        align-items: center;
        margin-right: 13px;

        svg {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          fill: var(--color-on-surface-subtle-2);
        }

        &Count {
          color: var(--color-on-surface);
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-bold);
        }
      }
    }
  }

  &Header {
    font-size: var(--font-size-xs);

    .bbAssetName {
      margin-left: 4px;
      font-weight: var(--font-weight-bold);
    }

    .bbPostInfo {
      font-size: var(--font-size-xxs);
      color: var(--color-grey-700);
      margin-top: 2px;
      cursor: default;
    }
  }

  .bbPostMessage {
    margin-top: 2px;
    overflow-wrap: break-word;
    color: var(--color-blue-900);
    font-size: var(--font-size-xxs);
  }

  &Wrapper {
    display: block;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;