.bbLoading {
  svg {
    width: 12px;
    height: 12px;
  }
}

.bbCommentTagWrapper {
  cursor: help;
}

.bbCommentTagChipActionWrapper {
  bottom: 3px;
}

.bbTagLabel {
  display: block;
}

.bbChipCount {
  margin-right: 4px;
}

.bbPremiumTag {
  margin-left: 8px;

  svg {
    top: 6px;
    width: 22px;
    height: 22px;
    margin-right: 8px;
    position: relative;
  }
}

.bbCommentTag {
  padding-left: 1.5rem !important;
}

.bbCommentTag-universally_harmful {
  color: white;
  background-color: var(--color-danger-700);
}

.bbCommentTag-sensitive {
  color: var(--color-danger-800);
  background-color: var(--color-danger-100);
}

.bbCommentTag-customer_engagement {
  color: var(--color-blue-900);
  background-color: var(--color-blue-200);
}

.bbCommentTag-alerts {
  fill: white;
  color: white;
  background-color: var(--color-warning-1);
}

.bbCommentTag-non_harmful {
  color: var(--color-grey-700);
  background-color: var(--color-grey-100);
}

.bbCommentTagIcon:has(.bbCommentTagIconWrapper) {
  top: 45% !important;
  left: 0.5rem !important;

  .bbCommentTagIconWrapper {
    width: 100%;
  }
}

.bbCommentTagIcon-universally_harmful {
  &:first-child {
    fill: white;
  }
}

.bbCommentTagIcon-sensitive {
  &:first-child {
    fill: var(--color-danger-800);
  }
}

.bbCommentTagIcon-customer_engagement {
  &:first-child {
    fill: var(--color-blue-900);
  }
}

.bbCommentTagIcon-alerts {
  &:first-child {
    fill: white;
  }
}

.bbCommentTagIcon-non_harmful {
  &:first-child {
    fill: var(--color-grey-700);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;