.bbForgotPasswordContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  :global {
    .ant-form-item-explain {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .bbForgotPasswordForm {
    max-width: 550px;

    .bbResetPassword {
      width: 100%;
    }
  }

  .bbForgotPasswordHelpWrapper {
    text-align: center;

    .bbForgotPasswordHelpBtn {
      margin-right: 20px;
      font-weight: normal;

      svg {
        width: 14px;
        height: 14px;
        margin-right: 7px;
        top: 1px;
        position: relative;
        fill: var(--color-primary-1);
      }
    }

    a {
      color: var(--color-primary-1);
    }
  }

  .bbLoginSection {
    text-align: center;
    max-width: unset;

    span,
    button {
      font-size: var(--font-size-xl);
    }

    button {
      padding-left: 2px;
      padding-right: 2px;

      &:hover {
        background: transparent;
      }
    }
  }

  .bbRightStep1Button {
    color: var(--color-grey-900);
  }

  .bbForgotPasswordInput {
    & input {
      width: 100%;
    }
  }

  .bbForgotPasswordButton {
    width: 100%;
  }

  div[class*="bbPasswordChecker--"] {
    width: 100%;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    justify-content: flex-start;

    .bbForgotPasswordForm {
      width: 100%;
      max-width: unset;
      padding: 0 20px;
    }

    .bbForgotPasswordInput {
      & input {
        width: 100%;
      }
    }

    .bbForgotPasswordButton {
      width: 100%;
    }

    .bbLoginSection {
      max-width: unset;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;