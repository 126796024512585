.bbAstraChat {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  padding: 28px 64px;
  text-align: center;
  background: linear-gradient(
    270deg,
    rgba(0, 72, 229, 0.1) 0%,
    rgba(98, 90, 245, 0.1) 43.74%,
    rgba(252, 98, 229, 0.1) 116.64%
  );
  border-radius: var(--border-radius-sm);
  box-sizing: border-box;

  &Avatar {
    position: relative;
    width: 40px;
    height: 40px;
    flex-shrink: 0;

    &Container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    &Image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    &Badge {
      position: absolute;
      top: -6px;
      right: -6px;
      width: 20px;
      height: 20px;
      background: var(--color-white);
      border-radius: 50%;
      box-shadow: var(--shadow-sm-2);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  @media (max-width: 1350px) {
    flex-direction: row;
    padding: 12px;
    justify-content: space-between;

    &AvatarContainer {
      flex-direction: row;
    }
  }

  &Button {
    border: 1px var(--color-surface-5) solid;
  }

  &Title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--color-black);
    margin: 0;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;