.bbExpansionPanel {
  background-color: var(--color-white);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-sm-1);
  padding: 18px 22px;
  display: grid;
  grid-template-rows: auto 0fr;
  will-change: grid-template-rows;
  transition: grid-template-rows 0.2s ease;
  margin: 0;

  &Group {
    display: grid;
    grid-auto-flow: row;
    gap: 12px;
  }

  &Title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xl);
  }

  &Badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    border-radius: 11px;
    background-color: var(--color-secondary-2);
    color: var(--color-on-secondary);
    font-size: var(--font-size-sm);
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    padding: 0 12px;

    &Danger {
      background-color: var(--color-secondary-danger-2);
      color: var(--color-on-secondary-danger);
    }
  }

  &Content {
    padding: 1px;

    p {
      font-size: var(--font-size-lg);
    }

    :global(.ant-form-item .ant-form-item-label label) {
      font-size: var(--font-size-md);
    }
  }
}

body .bbExpansionPanelOptional {
  color: var(--color-on-surface-subtle-1);
  font-weight: normal;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;