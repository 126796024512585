.bbVideoPreview {
  margin-top: 20px;
  margin-left: 10px;
  display: inline-flex;

  .bbVideoWrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 240px;
    max-height: 426px;
    min-height: 426px;
    border-radius: var(--border-radius-xl);
    background: var(--color-black);
    position: relative;
    overflow: hidden;

    video {
      max-width: 240px;
      min-width: 240px;
      max-height: 426px;
    }

    .bbGradientOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.12) 0%,
        rgba(0, 0, 0, 0) 12.5%,
        rgba(0, 0, 0, 0) 78.99%,
        rgba(0, 0, 0, 0.3) 93.31%
      );
    }
  }

  .bbRightVerticalActions {
    position: absolute;
    right: 8px;
    bottom: 0;

    svg {
      fill: var(--color-white);
      stroke: var(--color-white);
    }

    .bbAssetImage {
      display: flex;
      margin-bottom: 20px;
      position: relative;

      img {
        border-radius: 26px;
        width: 26px;
        height: 26px; // width - border-radius
        border: 1px solid var(--color-white);
      }

      .bbPlusIcon {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: var(--color-red-600);
        position: absolute;
        bottom: -6px;
        left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 6px;
          height: 6px;
          fill: var(--color-white);
        }
      }
    }

    .bbLikeAction {
      margin-bottom: 15px;
      display: flex;
      justify-content: center;

      svg {
        width: 25px;
        height: 24px;
      }
    }

    .bbCommentAction {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;

      svg {
        width: 27px;
        height: 23px;
      }
    }

    .bbSaveAction {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .bbShareAction {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;

      svg {
        width: 26px;
        height: 22px;
      }
    }

    .bbDiscIcon {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;

      svg {
        width: 29px;
        height: 28px;
      }
    }
  }

  .bbBottomHorizontalActions {
    position: absolute;
    text-align: left;
    bottom: 2px;
    left: 8px;

    .bbPageName {
      width: 190px;
      font-size: var(--font-size-xs);
      color: var(--color-white);
      font-weight: var(--font-weight-bold);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .bbCaption {
      color: var(--color-white);
      font-size: var(--font-size-xxs);
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .bbAudio {
      display: inline;
      color: var(--color-white);
      font-size: var(--font-size-xxs);
      position: relative;
      bottom: 4px;

      svg {
        top: 1px;
        margin-right: 6px;
        position: relative;
        width: 8px;
        height: 8px;
        fill: var(--color-white)
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;