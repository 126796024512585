.bbPerformanceCard {
  &PillAmount-positive {
    color: var(--color-positive);
  }

  &PillAmount-negative {
    color: var(--color-red-700);
  }

  &PillAmount-neutral {
    color: var(--color-blue-900);
  }

  &Pill {
    gap: 4px;
    max-height: 24px;
    display: inline-flex;
    align-items: center;
    border-radius: 99px;
    margin-left: 8px;
    padding: 1px 8px;
    font-weight: bold;
    font-size: var(--font-size-xs);
    border: 1px solid var(--color-surface-5);

    &Content {
      display: flex;
      align-items: center;
    }

    .bbChangeArrowWrapper {
      margin-right: 4px;
    }

    .bbPercentageValue {
      font-size: var(--font-size-sm);
    }
  }

  &Icon {
    display: flex;
    flex-shrink: 0;
    border-radius: 99px;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0;

    svg {
      height: 16px;
      width: 16px;
    }

    &-total {
      background-color: var(--color-primary-100);
    }

    &-positive {
      background-color: #e4f4eb;
    }

    &-negative {
      background-color: #f9e4e4;
    }

    &-neutral {
      background-color: #f3f3f6;
    }

    &-replies_issued {
      background-color: var(--color-primary-100);
    }

    &-conversations_hidden {
      background-color: var(--color-primary-100);
    }
  }

  &Title {
    font-weight: bold;
    font-size: var(--font-size-lg);
    color: var(--color-grey-700);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Amount {
    display: inline;
    font-weight: bold;
    color: var(--color-grey-900);
    line-height: 1;
  }

  &Header {
    display: flex;
    justify-content: space-between;
  }

  &HeaderTitleWrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &Body {
    display: flex;
  }

  &Empty {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &Loading {
    &-sm {
      text-align: center;
      padding-top: 22px;
      padding-bottom: 22px;
    }

    &-md {
      text-align: center;
      padding-top: 42px;
      padding-bottom: 42px;
    }
  }
}

.bbPerformanceCardClickable {
  cursor: pointer;
}

.bbPerformanceCard-sm {
  height: 110px;
}

.bbChangeArrow {
  svg {
    fill: var(--color-positive);
    position: relative;
    height: 14px;
    width: 14px;
    top: 2px;
  }
}

.bbChangeArrowNegative {
  svg {
    fill: var(--color-red-700);
  }
}

.bbHelpIcon {
  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-grey-600);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;