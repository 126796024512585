.bbTTSignupStep1Wrapper {
  .bbTTSignupStep1 {
    margin-top: 40px;

    &Title {
      text-align: center;
      margin-bottom: 16px;
      color: var(--color-grey-900);
      font-size: var(--headline-h4-size);
      font-weight: var(--font-weight-extrabold);
      letter-spacing: 0.24px;
    }

    &Subtitle {
      text-align: center;
      margin-bottom: 30px;
      color: var(--color-grey-900);
      font-size: var(--font-size-xl);
      letter-spacing: 0.16px;
    }

    &Card {
      width: 486px;
      margin: 0 auto;
      padding: 40px;

      div[class*="bbTermsConditions"] {
        :global {
          .ant-row.ant-form-item {
            margin: 0;
          }
        }
      }
    }

    &Email {
      margin-top: 0;
    }

    &Disclaimer {
      line-height: 150%;
      margin-top: 24px;
      letter-spacing: 0.12px;
      color: var(--color-grey-700);
      font-size: var(--font-size-sm);
    }

    div[class*="bbTermsConditionsText"] {
      text-align: left;
      margin: 0;
      max-width: 345px;
    }
  }

  div[class*="bbStepFooter"] {
    justify-content: flex-end;
  }
}

.bbContainerLoading {
  text-align: center;
  padding-top: 100%;
  color: var(--color-blue-700);

  span,
  div,
  svg {
    color: var(--color-blue-700);
  }
}

.bbStepDefaultFinishIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    fill: var(--color-grey-700);
  }
}

.bbTTSignupWrapper {
  footer[class*="bbAppFooter"] {
    left: 0;
    bottom: 0;
    width: 100%;
    position: fixed;
    box-shadow: 0px -1px 4px 0px rgba(7, 20, 49, 0.12);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;