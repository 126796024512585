.bbColumnSticky {
  position: sticky !important;
  left: 0;
  z-index: 1;
  background-color: var(--color-white);
}

.bbColumnStickyMain {
  position: sticky !important;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: var(--color-white);
}

.bbPostsWrapper {
  margin-top: 32px;

  .bbPostsTableContainer {
    max-height: 60vh;
    overflow-y: auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .bbPostsTableContainer::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    -webkit-appearance: none;
    display: unset;
  }

  .bbPostsTableContainer::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-xl);
    background-color: var(--color-surface-8);
    border: 2px solid var(--color-white);
  }

  .bbPostsTableContainer::-webkit-scrollbar-track {
    background: var(--color-white);
    border-radius: var(--border-radius-xl);
  }

  div.bbPostsTable {
    position: relative;
    min-width: 2430px;
    font-size: var(--font-size-md);

    .bbColumnPrefix {
      svg {
        position: relative;
        top: 2px;
        width: 16px;
        height: 16px;
        fill: var(--color-on-surface-subtle-2);
      }
    }

    &Row {
      display: flex;

      .bbColumn {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        color: var(--color-on-surface);
        border-bottom: 1px solid var(--color-surface-5);
        border-right: 1px solid var(--color-surface-5);

        .bbPostDate {
          display: flex;
          align-items: center;
          gap: 6px;

          &HelpIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            svg {
              flex-shrink: 0;
              width: 16px;
              height: 16px;
              fill: var(--color-on-surface-subtle-1);
            }
          }
        }

        .bbNssScore {
          padding: 1px 6px;
          font-size: var(--font-size-sm);
          font-weight: var(--font-weight-bold);
          border-radius: var(--border-radius-sm);
          color: var(--color-on-inverted-surface);
        }

        .bbNssScore-positive {
          background-color: var(--color-success-1);
        }

        .bbNssScore-negative {
          background-color: var(--color-danger-1);
        }

        .bbNssScore-neutral {
          background-color: var(--color-on-surface-subtle-1);
        }

        .bbWhiteChip {
          padding: 1px 6px;
          margin-left: 6px;
          font-size: var(--font-size-sm);
          font-weight: var(--font-weight-bold);
          border-radius: var(--border-radius-sm);
          color: var(--color-on-surface);
          box-shadow: var(--shadow-sm-2);
          background-color: var(--color-white);
        }

        .bbExploreBtn {
          padding: 8.5px;

          svg {
            fill: var(--color-white);
          }
        }

        span[class*="bbRestOfTagsChip--"] {
          color: var(--color-on-surface);
        }

        .bbMetricNotAvailable {
          color: var(--color-on-surface-subtle-1);
          background: transparent;
          font-size: var(--font-size-md);
          font-weight: 400;
        }
      }

      .bbPostTagsColumn {
        flex-wrap: wrap;
      }

      .bbMetricNotAvailable {
        padding: 8px 0 8px 12px;
        color: var(--color-on-surface-subtle-1);
        background: transparent;
        font-size: var(--font-size-md);
        font-weight: 400;
      }

      .bbColumnEmpty {
        font-size: var(--font-size-lg);
        font-weight: 400;
        color: var(--color-on-surface-subtle-1);
      }

      .bbMoreBtn {
        padding: 8px;
        border: 1px solid var(--color-surface-5);

        svg {
          width: 16px;
          height: 16px;
          fill: var(--color-primary-1);
        }

        &:hover {
          background-color: var(--color-secondary-2);

          svg {
            fill: var(--color-on-secondary);
          }
        }
      }

      .bbColumnFormat {
        .bbPostFormat {
          display: flex;
          padding: 1px 6px;
          border-radius: var(--border-radius-sm);
          color: var(--color-on-secondary);
          font-size: var(--font-size-sm);
          font-weight: var(--font-weight-bold);
          line-height: 18px;
          align-items: center;

          svg {
            top: 2px;
            width: 12px;
            height: 12px;
            margin-right: 4px;
            position: relative;
            fill: var(--color-on-secondary);
          }
        }

        .bbPostFormat-image {
          background: var(--color-secondary-2);
        }

        .bbPostFormat-video {
          background: #e7fcd2;
        }

        .bbPostFormat-multiimage {
          background: #ddd2fc;
        }

        .bbPostFormat-other {
          background: var(--color-surface-5);
        }
      }

      .bbColPost {
        padding: 16px;
        color: var(--color-blue-900);
        position: relative;
        border-right: 1px solid var(--color-surface-5);
        border-bottom: 1px solid var(--color-surface-5);
      }

      &Colored {
        .bbColumn,
        .bbColPost {
          background-color: var(--color-grey-50);
        }
      }

      &White {
        background-color: var(--color-white);
      }
    }

    .bbPlatformIconSmall {
      width: 24px;
      height: 24px;
      margin-right: 14px;

      & > * {
        width: 24px;
        height: 24px;
      }
    }

    .bbPlatformIconLarge {
      width: 29px;
      height: 29px;
      margin-right: 14px;

      & > * {
        width: 29px;
        height: 29px;
      }
    }

    .bbPostNoWrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span.bbPostTagsListIcon {
      right: 6px !important;
      width: 12px !important;
      height: 12px !important;
      fill: var(--color-on-surface);

      svg {
        fill: var(--color-on-surface);
      }
    }

    :global {
      .ant-col-1\.5 {
        flex: 0 0 6.25%;
        max-width: 6.25%;
      }
      .ant-col-1\.25 {
        flex: 0 0 5.2%;
        max-width: 5.2%;
      }
    }
  }

  :global {
    .ant-col-1\.5 {
      flex: 0 0 6.25%;
      max-width: 6.25%;
    }
    .ant-col-1\.25 {
      flex: 0 0 5.2%;
      max-width: 5.2%;
    }
  }

  @media (max-width: 1439px) {
    span[class*="bbPageSizeTitle--"] {
      display: none;
    }
  }
}

.bbPostTagsDropdownBtnWrapper {
  top: 1px;
  left: 2px;
  position: relative;

  button[class*="bbTriggerBtn--"] {
    padding: 4px 6px;
    background-color: var(--color-surface-2);

    svg {
      fill: var(--color-on-surface);
    }
  }
}

span.bbPostTagsListTag {
  color: var(--color-on-surface);
  padding-right: 20px !important;
}

.bbPostViewWrapper {
  position: relative;
  display: flex;
  padding: 0;
  justify-content: space-between;

  .bbPostViewImagePreview {
    margin-right: 12px;
    border-radius: 6px;

    & > * {
      color: var(--color-grey-700);
    }
  }

  .bbPostViewImagePreviewClickable {
    cursor: pointer;
  }

  .bbPostMessage {
    font-size: var(--font-size-sm);
    color: var(--color-primary-900);
    overflow: hidden;
    line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .bbPostAuthor {
    font-weight: bold;
    padding-right: 4px;
    line-height: 18px;
    color: var(--color-primary-900);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bold);
  }

  .bbPostText {
    line-height: 19px;
    font-size: var(--font-size-md);
    color: var(--color-on-surface-subtle-1);
  }

  .bbPostRightSection {
    display: flex;
    align-items: center;

    .bbActionBtnColored {
      background-color: var(--color-grey-50);
    }

    .bbActionBtnWhite {
      background-color: var(--color-white);
    }
  }

  .bbPostLeftSection {
    display: flex;
    align-items: center;

    .bbPostTextSection {
      max-width: 140px;
      display: flex;
      flex-direction: column;
    }
  }
}

.bbPostActionsDropdown {
  background: var(--color-white);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md-2);

  :global {
    .ant-menu {
      padding: 8px;
      background: var(--color-white);
      border-radius: var(--border-radius-md);

      .ant-menu-item {
        display: flex;
        min-width: 184px;
        padding: 4px 8px;
        margin-bottom: 0 !important;
        align-items: center;
        line-height: 19.5px;
        background: var(--color-white);
        color: var(--color-on-surface);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-bold);

        .ant-menu-title-content {
          margin-left: 0 !important;
        }

        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          fill: var(--color-on-surface-subtle-2);
        }
      }
    }
  }
}

div.bbPostsTablePrefix {
  .bbPostsTablePrefixTitle {
    padding: 16px;
    border-right: 1px solid var(--color-surface-5);
    border-bottom: 1px solid var(--color-surface-5);
    font-size: var(--font-size-lg);
    background-color: var(--color-secondary-1);
    font-weight: var(--font-weight-bold);

    .bbPostsTablePrefixHelpIcon {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  div.bbColumn {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    background-color: var(--color-secondary-1);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;