.bbAssetTab {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  row-gap: 8px;

  svg {
    width: 29px;
    height: 29px;
  }

  &IconWrapper {
    background-color: var(--color-white);
    border-radius: 5000px;
    height: 60px;
    min-width: 60px;
    padding: 16px;
    display: flex;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-sm-2);
  }

  &Active {
    box-shadow: var(--shadow-md-2);
    outline: 2px solid var(--color-primary-1);
  }

  &LabelWrapper {
    line-height: 1.1;
    margin-left: 5px;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-on-surface-subtle-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:not(:last-child)::after {
      content: "+";
      margin: 0 8px 0 4px;
      color: var(--color-blue-900);
    }
  }

  &Label {
    text-align: center;
    font-weight: var(--font-weight-bold);
  }

  &Description {
    display: block;
    font-weight: var(--font-weight-bold);
    color: var(--color-on-surface-subtle-1);
    font-size: var(--font-size-sm);
  }

  @media (max-width: var(--screen-xs-min)) {
    svg + svg {
      margin-left: 5px;
    }
  }
}

.bbAssetTab-youtube {
  svg {
    width: 29px;
    height: 29px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;