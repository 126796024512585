.bbEmailAlertThresholdContainer {
  max-height: 650px;
  overflow: auto;

  &.bbEmailAlertThresholdContainerNoOverflow {
    overflow: hidden;
  }

  .bbEmailAlertThresholdFilters {
    padding: 16px 0;
    overflow: hidden;

    .bbAppFiltersFormWrapper > div {
      padding: 0 20px;
    }

    &Title {
      font-weight: bold;
      color: var(--color-on-surface);
      font-size: var(--headline-h5-size);
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
    }

    :global {
      #trigger {
        width: 100%;
      }
    }
  }

  .bbEmailAlertThresholdConfig {
    padding-top: 0;

    .bbEmailAlertThresholdForm {
      display: inline;

      &Label {
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-bold);
      }

      .bbNotifyTabs {
        div[class*="bbTabHeader--"] {
          margin-left: auto;
        }

        div[class*="bbTabPane--"] {
          flex: 1;
        }

        .bbScheduleText {
          font-size: var(--font-size-lg);
          font-weight: var(--font-weight-bold);
        }

        .bbDynamicTab {
          &Warning {
            svg {
              top: 3px;
              width: 16px;
              height: 16px;
              position: relative;
              margin-left: 10px;
            }
          }
        }

        .bbFormInputNumber {
          padding: 4px;
          border: none;
        }
      }

      .bbNotificationInfoAlert {
        border-radius: var(--border-radius-md);
        margin-bottom: 17px;
        background-color: var(--color-grey-100);

        svg {
          fill: var(--color-blue-900);
        }
      }

      div[class*="bbTabHeadersContainer--"] {
        margin-bottom: 32px;
        width: 100%;
        display: block;
      }

      div[class*="bbTabPane--"] {
        div[class*="bbTabHeader--"] {
          display: flex;
          padding: 5.5px;
          font-weight: var(--font-weight-bold);
          justify-content: center;
          font-size: var(--font-size-lg);
          background-color: var(--color-white);

          div {
            color: var(--color-blue-900);
          }

          svg {
            fill: var(--color-blue-900);
          }
        }

        &:first-child {
          div[class*="bbTabHeader--"] {
            border-radius: 8px 0 0 8px;
          }
        }

        &:nth-child(2) {
          div[class*="bbTabHeader--"] {
            border-radius: 0 8px 8px 0;
          }
        }

        div[class*="bbTabHeaderActive--"] {
          color: var(--color-white);
          background-color: var(--color-primary-850);

          div {
            color: var(--color-white);
          }

          svg {
            fill: var(--color-white);
          }
        }
      }

      .bbNotificationFrequencyInfo {
        color: var(--color-primary-850);
        background-color: var(--color-primary-200);

        span {
          background-color: var(--color-primary-200);
        }

        svg {
          fill: var(--color-primary-850);
        }
      }

      .bbNotificationFrequencyTime {
        margin-top: 15px;

        div[class*="bbSwitchWrapper--"] {
          box-shadow: var(--shadow-sm-2);
          height: 32px;
          align-items: center;
          font-weight: var(--font-weight-bold);
          span {
            font-size: var(--font-size-lg);
          }
        }

        &Hour {
          display: flex;
          gap: 12px;
        }

        &Inputs {
          display: flex;
          gap: 20px;
          align-items: flex-end;
        }

        &Phrase {
          display: flex;
          align-items: flex-start;
          gap: 20px;

          > span {
            color: var(--color-primary-900);
            font-size: var(--font-size-lg);
            font-weight: var(--font-weight-bold);
          }
        }
      }

      .bbNotificationFrequencyDays {
        display: flex;
        margin: 17px 0;
        border-radius: var(--border-radius-md);
        align-items: center;
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-bold);
        gap: 50px;
        color: var(--color-primary-900);

        :global(.ant-form-item) {
          margin: 0;
        }
      }

      .bbMeridiem {
        margin-right: 4px;
        label[class*="bbSwitchButton--"] {
          background-color: var(--color-white);
        }
        input[class*="bbSwitch--"] {
          &:checked + label {
            background-color: var(--color-primary-850);
          }
        }
      }
    }

    :global(.ant-form-item) {
      display: inline-flex;
    }

    .bbFormItemThreshold,
    .bbFormItemRangeNumber,
    .bbFormItemNotificationHour {
      display: inline-flex;
      margin-top: -4px;
      width: 40px;
      margin-right: 10px;
      border-bottom: 2px var(--color-primary-1) solid;

      :global(.ant-input-number) {
        width: 50px;
        background-color: transparent;
      }
    }

    .bbFormRangeType {
      width: 95px;
      border-bottom: 2px var(--color-primary-1) solid;
      height: 32px;
      margin-top: -2px;

      &SelectWrapper {
        border: none;
      }

      :global(.ant-select-selector) {
        width: 95px;
      }

      :global(.ant-select) {
        color: var(--color-primary-1);
      }
    }

    .bbFormItemNotificationHour {
      width: 70px;
      height: 32px;
      margin-top: -2px;
      border-bottom: 2px var(--color-primary-1) solid;

      &SelectWrapper {
        border: none;
      }

      :global(.ant-select-selector) {
        width: 60px;
      }

      :global(.ant-select) {
        color: var(--color-primary-1);
      }
    }

    .bbFormItemNotificationHour {
      margin: 0px;
    }

    .bbFormItemNotificationHour.ant-form-item-has-error {
      border-bottom: 2px var(--color-red-700) solid;

      .ant-select-selector {
        border: none;
      }
    }

    .bbFormItemName {
      margin-top: 12px;
    }
  }

  .bbEmailAlertThresholdOverwrite {
    padding: 16px;
  }

  .bbDivider {
    background-color: var(--color-surface-5);
    margin-bottom: 10px;
  }

  .bbEmailAlertThresholdError {
    margin-top: -10px;
  }
  .bbTimezone {
    background-color: var(--color-surface-2);
    height: 32px;
    border: none;
    box-shadow: var(--shadow-sm-2);
    width: 195px;
    &Label {
      margin-bottom: 4px;
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-medium);
      color: var(--color-inverted-surface);
    }
  }
}

@keyframes stretchy {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;