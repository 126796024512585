.bbReportModal {
  padding: 0 20px 0 8px;
  max-height: calc(100vh - 300px);

  :global(.ant-form-item) {
    margin-bottom: 20px;
  }

  &Sections {
    :global(.ant-form-item-label) label {
      font-size: var(--headline-h5-size);
      font-weight: var(--font-weight-extrabold);
      margin-bottom: 8px;
    }
  }

  &Repeat {
    label {
      font-size: var(--font-size-xl) !important;
      font-weight: var(--font-weight-extrabold) !important;
    }
  }

  &RadioGroup {
    gap: 32px;

    span {
      font-size: var(--font-size-lg);
    }
    &Description {
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-lg);
      margin-top: 12px;
      display: inline-block;
    }
  }

  &TimeSection {
    border-radius: var(--border-radius-lg);
    background-color: var(--color-surface-1);
    padding: 20px;
    display: flex;
    gap: 32px;
    justify-content: space-between;

    &Hour {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 5px;
      > span {
        color: var(--color-grey-900);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-lg);
      }
      &Content {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

    &Timezone {
      display: flex;
      width: 195px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }

    .bbMeridiem {
      margin-bottom: 0;
      label[class*="bbSwitchButton--"] {
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-lg);
        min-width: 46px !important;
        padding: 2px 12px;
        background-color: var(--color-white);
      }

      div[class*="bbSwitchWrapper--"] {
        box-shadow: var(--shadow-sm-2);
      }
    }
    .bbTimeZone {
      background-color: var(--color-white);
      height: 32px;
      border: none;
      box-shadow: var(--shadow-sm-2);
      &Label {
        margin-bottom: 0;
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-medium);
      }

      &Wrapper {
        margin-bottom: 0;
      }
    }
  }
  .bbDivider {
    margin-top: 32px;
  }

  .bbFormItemHour {
    width: 60px;
    height: 32px;
    border-bottom: 2px var(--color-primary-1) solid;
    margin-bottom: 0;

    :global(.ant-select-selector) {
      width: 60px;
    }

    :global(.ant-select) {
      color: var(--color-primary-1);
    }

    &Select {
      width: 20px;

      &Wrapper {
        border: none;
      }
    }
  }

  .bbFiltersModalReview {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    padding: 0;
    margin: 0 0 12px 0;
  }

  .bbSubscriberSelect {
    > div {
      border-radius: 6px !important;
    }
    div[class*="bbSubscriberOption"] {
      padding: 0;

      span {
        font-size: var(--font-size-lg);
      }

      small {
        display: none;
      }
    }
  }
}

.bbReportModalBack {
  margin-right: 0;
  border: none;
  padding: 7px;

  svg {
    width: 16px;
    height: 100%;

    path {
      fill: var(--color-primary-1);
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;