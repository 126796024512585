.bbCalendarWrapper {
  display: flex;
  flex-direction: column;

  .bbCalendar {
    display: flex;
    flex: 1;
  }
}

.bbCalendarHeaderWrapper {
  padding: 28px 20px;
  background: var(--color-white);

  .bbCalendarHeader {
    align-items: center;
    display: flex;
    background: white;
    justify-content: space-between;
    padding-top: 28px;

    &LeftSide {
      display: flex;
      align-items: center;

      .bbTodayBtn {
        padding-left: 14px;
        padding-right: 14px;
        margin-right: 20px;
      }

      .bbTimezone {
        display: flex;
        margin-left: 20px;
        color: var(--color-grey-700);
        font-size: var(--font-size-sm);
        align-items: center;
        cursor: help;

        svg {
          width: 12px;
          height: 12px;
          margin-right: 4px;
          fill: var(--color-grey-700);
        }
      }
    }
  }

  .bbCreatePostBtnIcon {
    width: 12px;
    height: 12px;
    fill: var(--color-white);
    position: relative;
    bottom: 2px;
    margin-right: 6px;
  }

  .bbCreatePostBtnIconMobile {
    bottom: 4px;
    margin: 3px;
  }

  .bbCurrentTimespan {
    margin-left: 20px;
    font-weight: bold;
    margin-bottom: 0;
    font-size: var(--headline-h5-size);
  }

  .bbCalendarHeaderAction {
    cursor: pointer;
    margin-right: 4px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      color: var(--color-primary-850);
    }
  }
}

.bbMonth {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.bbWeek {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(7, minmax(154px, 1fr));
  overflow-x: auto;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;