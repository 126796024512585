.bbLinkZendeskUsersList {
  &Content {
    height: 300px;
    width: 100%;
    overflow: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  &List {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  &Item {
    border-bottom: 1px solid var(--color-surface-5);
    margin-top: 10px;
    margin-right: 20px;
    padding-bottom: 10px;

    &Content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &TextsWrapper {
      display: flex;
      flex-direction: column;
    }

    &Name {
      color: var(--color-grey-900);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
    }

    &Email {
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
    }

    &LinkedWrapper {
      display: flex;
      align-items: center;
    }

    &LinkedFlag {
      display: flex;
      align-items: center;
      color: var(--color-green-500);
      font-weight: var(--font-weight-bold);

      svg {
        height: 16px;
        margin-right: 6px;
        fill: var(--color-green-500);
      }
    }

    &UnlinkButton {
      height: 32px;
      margin-left: 20px;

      svg {
        height: 16px;
        fill: var(--color-red-700);
      }
    }

    &LinkButton {
      height: 32px;

      svg {
        height: 16px;
        fill: var(--color-primary-700);
        margin-right: 6px;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;