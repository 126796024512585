.bbAssetCard {
  height: 100%;
  padding: 20px;

  div[class*="bbAssetTabCardHeader"] + span[class*="bbAlert"] {
    margin-top: 20px;
  }

  &Search {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 3px;
    padding-bottom: 3px;

    .bbSearchIcon {
      position: relative;
      top: 3px;
      margin-right: 8px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-on-surface-subtle-2);
      }
    }
  }

  .bbAssetCardList {
    padding: 0;
    max-height: 350px;
    overflow-y: auto;

    :global {
      li.ant-list-item {
        border-bottom: 1px solid var(--color-white);
      }
    }

    &Item {
      cursor: pointer;
      border: none;
      padding: 4px 16px;

      &Tooltip {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
      }

      &Content {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
      }

      &Label {
        text-align: left;
        font-weight: var(--font-weight-bold);
        color: var(--color-blue-900);
        font-size: var(--font-size-lg);
      }

      &Description {
        color: var(--color-grey-700);
        font-size: var(--font-size-sm);
      }

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-1);
        margin-left: 10px;
      }

      &Selected,
      &:hover {
        background-color: var(--color-primary-100);
      }

      &Disabled {
        background-color: var(--color-grey-50);
        cursor: not-allowed;

        .bbAssetCardListItemLabel {
          color: var(--color-grey-700);
        }

        svg {
          fill: var(--color-grey-700);
        }
      }
    }
  }

  &Alert {
    margin-top: 12px;
    border-radius: 6px;
    font-size: var(--font-size-sm);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;