.bbPDFReport {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 24px 48px;
  background-color: var(--color-surface-1);
  width: 1200px;
  margin: 0 auto;

  * {
    box-shadow: none !important;
  }

  &Body {
    background-color: var(--color-surface-1) !important;
    padding: 24px 0 !important;
  }

  &Loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    gap: 24px;
    max-width: 680px;
    margin: 0 auto 24px auto;
    color: var(--color-on-surface-subtle-1);
    font-size: var(--font-size-xl);
    text-align: center;

    &Title {
      font-size: var(--headline-h1-size);
      font-weight: var(--font-weight-extrabold);
      color: var(--color-on-surface);
      margin: 0;
      padding: 0;
    }
  }

  &Header {
    position: relative;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #004cef 0%, #4d87e4 100%);
    background-color: #004cef;
    border-radius: var(--border-radius-xl);
    color: var(--color-white);
    padding: 24px;
    font-size: 14px;
    margin-bottom: 40px;

    &Title {
      color: var(--color-white);
      font-size: 20px;
      font-weight: var(--font-weight-extrabold);
      margin: 0;
      padding: 0;
    }

    &Logo {
      position: absolute;
      left: calc(50% - 40px);
      top: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: var(--color-white);
      border: 6px solid var(--color-surface-1);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &BBLogo {
      position: absolute;
      left: calc(100% - 135px);
      top: 80px;
      width: 141px;
      height: 46px;
      border-radius: 5px 0 12px 0;
      background: var(--color-surface-1);
      border: 6px solid var(--color-surface-1);
      overflow: hidden;
      display: flex;

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &BBLogoInner {
      border-radius: inherit;
      overflow: hidden;
      background: var(--color-white);
      padding: 7px 10px;
    }
  }

  &Section {
    page-break-inside: avoid;
    break-inside: avoid;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &Title {
      font-size: 18px;
      font-weight: var(--font-weight-normal);
      padding: 0;
      margin: 0;
    }
  }

  &NewPage {
    margin-top: 28px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;