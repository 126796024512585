.bbTopPostTags {
  margin: 48px 0 24px;
  container-type: inline-size;

  &Title {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    margin: 0;
  }

  &Subtitle {
    font-size: 14px;
    color: var(--color-on-surface-subtle-1);
    margin-bottom: 24px;
  }

  &Panel {
    background-color: var(--white);
    box-shadow: var(--shadow-sm-2);
    border-radius: var(--border-radius-xl);
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    overflow: hidden;
    max-height: 595px;

    &ScrollView {
      scrollbar-width: thin;
      scrollbar-color: var(--color-surface-5) transparent;
      overflow: auto;
      max-height: 595px;
      height: 100%;
    }

    &Metrics {
      width: 50%;
      max-width: 600px;
      flex-shrink: 0;
    }

    &Container {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      width: 100%;
    }

    &EmptyState {
      padding: 24px;
    }
  }

  &Info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &Header {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: flex-start;
    font-weight: var(--font-weight-bold);
  }

  &Table {
    &Container {
      position: relative;
    }

    &ScrollView {
      max-height: 530px;
      padding: 0 24px 24px 24px;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: var(--color-surface-5) transparent;
    }

    &Title {
      font-size: var(--font-size-2xl);
      font-weight: var(--font-weight-bold);
      display: flex;
      align-items: center;
      gap: 6px;
      margin: 24px 24px 0;
    }

    &Native {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      position: relative;

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: var(--white);
      }

      th {
        font-weight: var(--font-weight-bold);
        padding: 8px 8px 16px 8px;
        font-size: var(--font-size-md);

        .bbTopPostTagsHeader {
          justify-content: flex-end;
        }

        &:first-child {
          padding-left: 16px;
          text-align: right;

          .bbTopPostTagsHeader {
            justify-content: flex-start;
          }
        }

        &:last-child {
          padding-right: 16px;
          text-align: right;
        }

        &:nth-child(2),
        &:nth-child(3) {
          text-align: right;
        }
      }
    }

    &Row {
      height: 48px;
      font-size: var(--font-size-lg);

      &:nth-child(even) {
        background-color: var(--color-surface-3);
      }

      td {
        padding: 12px 8px;
        vertical-align: middle;

        &:first-child {
          padding-left: 16px;
        }

        &:last-child {
          padding-right: 16px;
          text-align: right;
        }

        &:nth-child(2),
        &:nth-child(3) {
          text-align: right;
        }
      }
    }

    &Chip {
      cursor: default;
    }
  }
}

.bbInformationIcon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
  flex-shrink: 0;
  svg {
    fill: var(--color-on-surface-subtle-2);
    width: 100%;
    height: 100%;
  }
}

.bbTopPostTagsEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;

  &Title {
    font-size: var(--headline-h4-size);
    font-weight: var(--font-weight-bold);
    color: var(--color-on-surface);
    margin: 0;
  }

  &Description {
    font-size: var(--font-size-lg);
    color: var(--color-on-surface-subtle-1);
    margin: 0;
    max-width: 600px;
  }

  &Subtitle {
    font-size: var(--font-size-lg);
    color: var(--color-on-surface-subtle-1);
    margin: 0;
  }

  &ActionCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    gap: 24px;
    margin-bottom: 24px;
  }

  &ActionCard {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
    min-width: 260px;
    max-width: 320px;

    &Icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--color-primary-150);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      svg {
        width: 20px;
        height: 20px;
        fill: var(--color-primary-700);
      }
    }

    &Content {
      text-align: left;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
    }

    &Title {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
      color: var(--color-on-surface);
      margin: 0 0 8px 0;
    }

    &Description {
      font-size: var(--font-size-lg);
      color: var(--color-on-surface-subtle-1);
      margin: 0 0 16px 0;
      line-height: 1.5;
    }

    &Button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      white-space: nowrap;
      gap: 8px;
      border: 1px solid var(--color-surface-5);
      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-700);
      }

      &Primary {
        border: none;
        svg {
          fill: var(--color-white);
        }
      }
    }
  }
}

body .bbTopPostTagsEmptyCta {
  display: flex;
  flex-direction: column;
  align-items: center;

  &Button {
    width: 100%;
    white-space: nowrap;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-white);
      margin-right: 8px;
    }
  }
}

@container (max-width: 1000px) {
  .bbTopPostTagsPanelContainer {
    flex-direction: column;
  }

  .bbTopPostTagsPanel {
    width: 100%;
    max-width: 100%;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;