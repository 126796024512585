.bbEngage {
  margin-bottom: 40px;

  &Header {
    display: flex;
    margin: 30px 0 20px 0;

    > * {
      margin: 0;
      font-weight: var(--font-weight-normal);
    }

    span[class*="bbSectionDividerChip"] {
      margin-right: 20px;
      background-color: var(--color-blue-850);
      border-radius: 5000px;
      color: var(--color-white);
      text-transform: uppercase;

      svg {
        fill: var(--color-white);
      }
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &Row {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    &LeftCol {
      width: 355px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &RightCol {
      width: 765px;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;