.bbTopPostTagsChart {
  width: 100%;
  padding: 0 24px;
  position: relative;

  &Container {
    overflow: hidden;
  }

  &Label {
    width: 140px;
    height: 32px;
    overflow: visible;
    cursor: default;
  }

  &LabelContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &LabelContent {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: transform 0.15s ease;

    &:hover {
      transform: translateY(-1px);
    }
  }

  &MetricsTable {
    display: grid;
    grid-template-columns: 1fr repeat(3, 110px);
    align-items: center;
    margin-bottom: 16px;
  }

  &MetricsHeader {
    font-size: 12px;
    font-weight: 600;
    color: #6b778c;
    padding: 8px;
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }

  &MetricsValue {
    font-size: 12px;
    text-align: right;
    padding: 8px;
  }

  &PostCountLabel {
    margin: 0;
    font-size: 12px;
  }

  &PostCountDot {
    cursor: pointer;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    transition: all 0.2s ease;

    &:hover {
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
      stroke-width: 3px;
    }
  }

  &Tooltip {
    background-color: var(--color-white);
    padding: 16px;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-lg-2);
    width: 405px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &Title {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-md);
    }

    &Images {
      display: flex;
      gap: 12px;
      flex-wrap: nowrap;
      overflow-x: hidden;
    }

    &ImageWrapper {
      width: 60px;
      height: 60px;
      border-radius: var(--border-radius-sm);
      overflow: hidden;
      flex-shrink: 0;
    }

    &Image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &MoreCount {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-xs);
      flex-shrink: 0;
    }
  }

  &TooltipColorDot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
  }

  &CustomAxisLabel {
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    font-size: var(--font-size-md);
  }

  &AxisLabelCircle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--color-primary-1);
    border: 2px solid var(--color-white);
    margin-right: 6px;
    box-shadow: var(--shadow-sm-3);
  }

  &AxisLabelText {
    color: rgba(0, 0, 0, 0.8);
  }

  &CustomCommentsLabel {
    position: absolute;
    bottom: 48px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    font-size: var(--font-size-md);
  }

  &CommentsIcon {
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 16px;
      height: 16px;
      fill: var(--color-on-surface-subtle-2);
    }
  }

  &CommentsLabelText {
    color: rgba(0, 0, 0, 0.8);
  }

  :global {
    .recharts-tooltip-wrapper {
      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;

      &.visible {
        opacity: 1;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;