.bbAppFiltersWrapperDesktop {
  width: 270px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-right: 6px;

  &WithBottomReached {
    height: calc(100vh - 50px);
  }

  &WithAIModal {
    opacity: 0;
    transition: opacity 0.5s;
  }
}

.bbAppFiltersWrapperDesktopCollapsed {
  width: 20px;
}

.bbAppFiltersWrapperMobile {
  position: relative;
  padding-bottom: 64px;
  height: 100vh;
}

.bbAppFilterFooterButtons {
  position: absolute;
  bottom: 6px;
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  border-bottom-left-radius: var(--border-radius-xl);
  border-bottom-right-radius: var(--border-radius-xl);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);

  &WithBottomReached {
    bottom: 50px;
  }

  .bbFiltersResetBtn {
    padding-left: 14px;
    padding-right: 14px;
  }

  .bbFiltersApplyBtn {
    margin-left: 12px;
    padding-left: 34px;
    padding-right: 34px;
  }
}

.bbAppFilterFooterButtonsMobile {
  display: flex;
  height: 64px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  z-index: 9999;
  pointer-events: none;

  button {
    pointer-events: auto;
  }
}

.bbAppFiltersForm {
  overflow-y: auto;
  flex-grow: 1;
  margin: 78px 6px 6px 0;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 100px;
  box-shadow: var(--shadow-sm-1);
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.bbAppFiltersWrapper {
  .bbAppFiltersForm {
    .bbToggleFiltersPanelBtn {
      position: absolute;
      display: flex;
      height: 28px;
      width: 28px;
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 28px;
      background-color: var(--color-white);
      padding: 0;
      box-shadow: var(--shadow-md-2);

      svg {
        width: 14px;
        height: 14px;
        fill: var(--color-blue-900);
      }
    }
  }
}

.bbSavedFilterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .bbSavedFilterTitle {
    font-weight: bold;
    font-size: var(--headline-h5-size);
  }

  .bbSavedFilterActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .bbFilterCloseIconWrapper {
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
        fill: var(--color-grey-600);
        cursor: pointer;
      }
    }
  }
}

.bbAppFiltersFormMobileWrapper > div:first-child {
  margin-bottom: 204px;
}

.bbAppFiltersFormWrapper > div {
  padding: 0 20px;
}

.bbSavedFilterSelect {
  padding: 7px !important;
  color: var(--color-on-secondary);
  margin-left: 15px;

  svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }

  &Active {
    background-color: var(--color-secondary-2) !important;
    border-color: var(--color-secondary-2);
    &:hover {
      background-color: var(--color-secondary-2);
    }
  }
}

@media (max-width: 767px) {
  .bbAppFiltersForm {
    position: relative;
    margin: 0 auto;
    border-radius: 6px;
    background-color: var(--color-white);
    padding-top: 16px;
    padding-bottom: 0;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;