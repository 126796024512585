.bbNumberSelectInputWrapper {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius-md);
  padding: 5px 6px;
  box-shadow: 0 0 0 1px var(--color-surface-5);

  button.bbDecrementButton,
  button.bbIncrementButton {
    width: 20px;
    height: 20px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    background: var(--color-surface-2);
    color: var(--color-primary-1);
    border-radius: 50%;
    font-size: 22px;
    font-weight: normal;
    padding: 1px 6px;

    &:disabled {
      cursor: not-allowed;
      color: var(--color-surface-2);
      background-color: #c2c6d2;
    }
  }

  .bbNumberSelectInput {
    width: 80px;
    height: 20px;
    text-align: center;
    color: var(--color-on-surface);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    line-height: 150%;
    background: transparent;
    border: none;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    margin: 0;

    &:focus {
      outline: none;
      border: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;