.bbTTDiscloseContent {
  margin-bottom: 15px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-grey-400);

  &Error {
    border: 2px solid var(--color-red-700);
  }

  &Title {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    color: var(--color-blue-900);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    border-bottom: 1px solid var(--color-grey-400);

    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      fill: var(--color-grey-600);
    }

    &Mobile {
      display: flex;
      align-items: center;

      svg {
        margin-left: 0;
      }
    }
  }

  &Body {
    padding: 12px 16px;
    color: var(--color-blue-900);

    &Mobile {
      padding: 0;
    }

    .bbInteractionAbilitySection {
      display: flex;
      margin-bottom: 15px;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--color-grey-400);

      &Mobile {
        flex-direction: column;
      }

      .bbInteractionAbilityLabel {
        margin-left: 6px;
        margin-right: 40px;
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-bold);
      }

      .bbInteractionAbilityLabelDisabled {
        cursor: not-allowed;
        color: var(--color-grey-400);
      }

      :global {
        .ant-form-item-control-input-content > span {
          display: flex;
          align-items: center;
        }

        .ant-form-item input[type="checkbox"] {
          width: 16px;
          height: 16px;
        }
      }
    }

    .bbDicloseContentSection {
      &Title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        &Label {
          display: flex;
          align-items: center;

          &Text {
            font-size: var(--font-size-md);
            font-weight: var(--font-weight-bold);
            margin-right: 4px;
          }
        }

        .bbDicloseContentInfoIcon {
          display: flex;
          justify-content: center;
          cursor: help;

          svg {
            width: 16px;
            height: 16px;
            fill: var(--color-grey-700);
          }
        }
      }

      .bbDicloseContentPromoteOwn {
        margin-bottom: 12px;
      }

      &Checkbox {
        margin-bottom: 2px;

        &Error {
          border: 2px solid var(--color-red-700);
        }

        &Label {
          margin-left: 6px;
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-bold);
        }

        &Description {
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-medium);
          color: var(--color-grey-700);
        }
      }

      &Error.bbFormFieldError {
        margin-top: 12px;
      }

      &Info {
        width: 100%;
        height: 32px;
        margin-top: 16px;
        padding: 6px 12px;
        border-radius: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        background: var(--color-blue-150);

        &Text {
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-medium);
          word-wrap: break-word;
          color: var(--color-primary-850);
        }

        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          fill: var(--color-primary-1);
        }
      }

      :global {
        .ant-form-item-control-input-content > div,
        span {
          display: flex;
          align-items: center;
        }

        .ant-form-item input[type="checkbox"] {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .bbFormFieldError {
    display: flex;
    margin-top: 4px;
    font-size: var(--font-size-lg);
    color: var(--color-red-700);

    & > svg {
      top: 3px;
      width: 15px;
      height: 15px;
      margin-right: 4px;
      position: relative;
    }
  }
}

.bbDicloseContentPromoteOthersTooltip > ul {
  padding-left: 14px;
  list-style-type: disc;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;