.bbCompareHeader {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .bbCompareLeft {
    display: flex;
    flex-wrap: wrap;

    .bbHeaderSelectField {
      display: flex;
      flex-direction: column;

      .bbProjectsSelect {
        width: 400px;
        border: none !important;
        box-shadow: var(--shadow-sm-2);
        background-color: var(--color-white);

        :global {
          .ant-select-selection-item svg {
            display: none;
          }

          .ant-select-selector {
            border: none !important;
            border-radius: var(--border-radius-md);
          }
        }
      }

      .bbProjectsSelectLabel {
        margin-bottom: 4px;
        color: var(--color-blue-900);
        font-size: var(--font-size-lg);
      }

      :global {
        .ant-form-item-no-colon {
          font-size: var(--font-size-lg);
        }

        .ant-form-item-control-input {
          width: 220px;
        }

        span.ant-select-selection-placeholder {
          color: var(--color-grey-700);
          font-weight: normal;
        }
      }

      div[class*="bbDataRangeSelect"] {
        :global {
          .ant-select-selector {
            border: none !important;
            box-shadow: var(--shadow-sm-2);
          }
        }
      }
    }

    .bbHeaderSelectField:first-child {
      margin-right: 25px;
      padding-right: 25px;
      border-right: 1px solid var(--color-surface-5);
    }
  }

  .bbCompareRight {
    &CreateBtn {
      margin-top: 24px;

      &Icon {
        margin-right: 8px;

        svg {
          width: 12px;
          height: 12px;
          fill: white;
        }
      }
    }
  }
}

.bbProjectsSelectOption {
  line-height: 3;
  font-weight: 400;
  min-width: 200px;
  color: var(--color-blue-900);
  font-size: var(--font-size-lg);

  svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    font-size: var(--font-size-2xl);
    color: var(--color-grey-600);
  }

  :global {
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;