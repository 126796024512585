.bbPost {
  display: flex;

  &Left {
    width: 480px;
    padding: 20px;

    &Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      .bbTitle {
        display: flex;
        align-items: center;

        .bbPlatform {
          height: 30px;
          margin-right: 8px;

          & svg {
            width: 30px;
            height: 30px;
          }

          &-youtube {
            svg {
              width: 29px;
              height: 29px;
            }
          }
        }

        .bbAuthor {
          line-height: 21px;
          font-size: var(--font-size-lg);
          font-weight: var(--font-weight-bold);
        }
      }

      .bbSubtitle {
        color: var(--color-grey-700);
        font-size: var(--font-size-sm);
        line-height: 18px;
      }
    }

    .bbMessage {
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-medium);
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      margin-bottom: 12px;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .bbPreview {
      max-height: 200px;
      margin-bottom: 12px;
      border-radius: var(--border-radius-sm);
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;

      .bbPicture,
      .bbVideo {
        width: 100%;
        height: auto;
        max-height: 280px;
      }
    }

    .bbPostIdWrapper {
      margin-bottom: 12px;
      font-size: 13px;

      .bbPostId {
        margin-left: 12px;
        font-weight: var(--font-weight-bold);
      }
    }

    &Actions {
      display: flex;
      margin-top: 15px;

      button {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: var(--font-size-md);

        &:first-child {
          margin-right: 12px;
        }

        svg {
          width: 16px;
          margin-right: 5px;
        }
      }

      button[class*="bbButton-primary--"] {
        svg {
          fill: var(--color-white);
        }
      }

      button[class*="bbButton-secondary--"] {
        svg {
          fill: var(--color-primary-1);
        }
      }
    }
  }

  &Right {
    width: 320px;
    position: relative;
    padding: 57px 20px 0px 20px;
    background-color: var(--color-surface-4);

    &AccordionsWrapper {
      overflow: auto;
      max-height: calc(723px - 57px);
    }

    &Accordion {
      padding: 0;

      div[class*="bbAccordionHeader--"] {
        padding-top: 12px;
        margin-bottom: 12px;

        svg {
          fill: var(--color-on-surface-subtle-2);

          path {
            fill: var(--color-on-surface-subtle-2);
          }
        }

        span[class*="bbBadge--"] {
          background-color: var(--color-surface-4);
          box-shadow: none;
          font-size: var(--font-size-sm);
          margin-left: 8px;
        }
      }

      div[class*="bbAccordionBodyContent--"] {
        padding: 0 12px 12px 12px;
      }

      &PostDetails {
        margin-top: 0;

        .bbMediaTypeWrapper {
          display: flex;
          align-items: center;

          .bbMediaTypeIcon {
            display: flex;
            margin-right: 4px;

            svg {
              width: 12px;
              height: 12px;
              fill: var(--color-grey-700);
            }
          }

          .bbMediaTypeLabel {
            color: var(--color-grey-700);
            font-size: var(--font-size-sm);
          }

          span[class*="bbChip--"]:first-child {
            margin-right: 4px;
          }
        }

        .bbPostDetailsInfoLabel {
          margin-top: 10px;
          font-weight: var(--font-weight-extrabold);
          font-size: var(--font-size-lg);
        }

        .bbPostDetailsInfoValue {
          font-size: var(--font-size-sm);
        }
      }
    }

    .bbPostTagsListingWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .bbPostDetailsInfoPostTagsLabelWrapper {
        display: flex;
      }

      .bbPostDetailsInfoPostTagsLabel {
        font-weight: var(--font-weight-extrabold);
        font-size: var(--font-size-lg);
      }

      .bbPostTagsDropdownTrigger {
        height: 26px;
        width: 26px;
        box-shadow: none;
        position: relative;
        border: 1px solid var(--color-surface-5);
        padding: 0;
        display: flex;
        justify-content: center;

        &:hover {
          border-color: transparent;
          color: var(--color-on-secondary);
          background: var(--color-secondary-2);

          svg {
            fill: var(--color-on-secondary);
          }
        }

        svg {
          width: 14px;
          height: 14px;
          fill: var(--color-primary-1);
        }
      }
    }

    &ImageAnalysis {
      &Label {
        display: flex;
        align-items: center;
        font-weight: var(--font-weight-extrabold);
        font-size: var(--font-size-sm);
        color: var(--color-on-surface);
      }

      &Value {
        font-size: var(--font-size-sm);
        color: var(--color-on-surface);
      }
    }
  }

  &CloseModal {
    position: absolute;
    top: 20px;
    right: 20px;

    svg {
      width: 20px;
      fill: var(--color-grey-600);

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.bbPostInfoIcon {
  height: 14px;
  margin-left: 4px;

  svg {
    margin: 0;
    width: 14px;
    fill: var(--color-on-surface-subtle-2);
  }

  &:hover {
    cursor: pointer;
  }
}

.bbPostModal {
  max-height: 723px;

  &Body {
    padding: 0 !important;
    border-radius: var(--border-radius-lg);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;