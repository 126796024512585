.bbEngagementCardsContainer {
  margin-bottom: 20px;
}

.bbEngagementCardsRow {
  display: flex;
  gap: 20px;
}

.bbEngagementCardsWrapper {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bbEngagementCardLoading {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bbEngagementTrendWrapper {
  width: 100%;
}

.bbEngagementCardTooltipIcon {
  vertical-align: middle;

  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-grey-700);
    margin-left: 4px;
  }
}

.bbEngagementCardAIIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-md);
  height: 24px;
  gap: 4px;
  padding: 0 6px;
  color: var(--color-primary-850);
  font-size: var(--font-size-sm);
  background-color: var(--color-primary-150);

  svg {
    width: 12px;
    height: 12px;
    fill: var(--color-primary-850);
  }
}

.bbEngagementTrendsProgressWrapper {
  width: 33.33%;
}

.bbEngagementProgressCard {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &Header {
    display: flex;
    justify-content: space-between;
  }

  &Title {
    margin-top: auto;
    font-weight: bold;
    color: var(--color-grey-700);
    font-size: var(--font-size-lg);
  }

  &Body {
    display: flex;
    text-align: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    &LeftIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background-color: var(--color-primary-150);
      border-radius: var(--border-radius-xxl);

      svg {
        width: 18px;
        height: 22px;
        fill: var(--color-primary-850);
      }
    }

    &Text {
      display: flex;
      margin-left: 0.75rem;
      font-size: var(--font-size-xl);

      p,
      strong {
        margin-bottom: 0;
        font-size: var(--font-size-xl);
      }
    }

    &RightIcon {
      display: flex;
      margin-left: auto;
      width: 25px;

      svg {
        fill: var(--color-primary-1);
      }
    }
  }

  &Footer {
    height: 50px;

    &Percentage {
      margin-top: 6px;
      font-weight: bold;
      color: var(--color-primary-1);
    }
  }
}

.bbEngagementProgressCardBody {
  &:hover {
    .EngagementProgressCardBodyLeftIcon {
      background-color: var(--color-primary-1);

      svg {
        fill: var(--color-white);
      }
    }

    .EngagementProgressCardBodyText {
      color: var(--color-primary-1);
    }
  }
}

@media screen and (max-width: 1280px) {
  .bbEngagementTrendsProgressWrapper {
    width: 50%;
  }

  .bbEngagementCardsWrapper {
    width: 100%;
    flex-direction: row;
  }

  .bbEngagementTrendWrapper {
    width: 50%;
  }
}

@media screen and (max-width: 1100px) {
  .bbEngagementTrendsProgressWrapper {
    width: 100%;
  }

  .bbEngagementTrendWrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .bbEngagementCardsWrapper {
    width: 100%;
    display: block;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;