.bbInboxHeader {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 20px;

  &Title {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: var(--headline-h5-size);
    font-weight: 400;
    text-transform: none;
    color: var(--color-blue-900);
    font-weight: var(--font-weight-bold);
    &Info {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      cursor: help;
      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-grey-600);
      }
    }
    &Prefix {
      font-weight: var(--font-weight-medium);
      &::after {
        content: ">";
        color: var(--color-grey-600);
        margin-left: 5px;
      }
    }
  }
  &TabDescription {
    display: block;
    width: 100%;
    color: var(--color-grey-700);
    font-size: var(--font-size-xl);
  }
  &FilterWrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    width: 100%;
    column-gap: 20px;
    &Actions {
      flex-grow: 1;
      display: flex;
      gap: 20px;
    }
    .bbBatchModeBtnWrapper {
      display: inline-flex;
      flex-direction: row;
      gap: 20px;
    }
    .bbInboxHeaderSortWrapper {
      box-shadow: var(--shadow-sm-2);
    }
  }
  &InfoMessage {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: var(--color-grey-600);
    svg {
      width: 18px;
      height: 18px;
      fill: var(--color-grey-600);
      margin-left: 8px;
    }
  }
  &InfoAlert {
    margin-top: 34px;
    border-radius: var(--border-radius-md);
    span[class*="bbAlertMessage"] {
      padding-top: 2.5px;
      padding-bottom: 2.5px;
      font-size: var(--font-size-xl);
      svg {
        fill: var(--color-blue-600);
        animation: pendingProgress 1s linear infinite;
      }
    }
  }
}

@keyframes pendingProgress {
  100% {
    transform: rotate(360deg);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;