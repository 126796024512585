.bbAIHighlights {
  background: linear-gradient(270deg, #0048e5 0%, #625af5 74.65%, #8a62fc 116.64%);

  border-radius: var(--border-radius-xl);
  color: white;
  position: relative;
  margin-bottom: 20px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-width: 300px;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 16px;
    cursor: pointer;

    @media (max-width: 1350px) {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &Title {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: var(--headline-h4-size);
      font-weight: var(--font-weight-bold);
      height: auto;
      min-height: 38px;
      flex-wrap: wrap;

      > svg:first-child {
        width: 24px;
        height: 24px;
        fill: var(--color-white);
        margin-right: 6px;
      }

      &Tooltip {
        cursor: help;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--color-white);
        }
      }

      &Subtext {
        font-size: var(--font-size-md);
        color: var(--color-surface-3);
        font-weight: normal;
        line-height: 16px;
      }

      &DateRange {
        margin-left: 6px;

        @media (max-width: 1350px) {
          width: 100%;
          margin-left: 0;
          margin-top: 4px;
          order: 1;
        }
      }
    }

    &LastUpdated {
      display: inline-flex;
      align-items: center;
      color: rgba(241, 245, 255, 0.7);
      font-size: var(--font-size-md);
    }

    &Actions {
      display: flex;
      gap: 12px;
      align-self: flex-start;
      margin-top: 4px;

      &CollapseToggle {
        background-color: transparent;
        svg {
          transition: transform 0.2s ease-in-out;
        }
        &Active {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &Content {
    display: flex;
    padding: 12px;
    background-color: var(--color-white);
    border-radius: var(--border-radius-md);
    flex-direction: row;
    gap: 24px;
    justify-content: center;

    @media (max-width: 1350px) {
      flex-direction: column;
    }

    &Result {
      flex: 1;
      color: var(--color-grey-800);
      font-size: var(--font-size-md);
      line-height: 2;
    }

    &Loading {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 30px;
      width: 100%;
      text-align: center;
      gap: 20px;

      h4 {
        margin: 0;
        text-align: left;
        color: var(--color-on-surface);
        font-size: var(--headline-h4-size);
        font-weight: var(--font-weight-bold);
      }
    }

    &Error {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      text-align: center;
      width: 100%;
      max-width: 1400px;

      @media (max-width: 1350px) {
        flex-direction: column;
        align-items: center;
      }

      &Image {
        width: 180px;
        height: 120px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &Text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;

        h4 {
          font-size: var(--headline-h4-size);
          font-weight: var(--font-weight-bold);
          margin: 0;
          color: var(--color-on-surface);
        }

        p {
          color: var(--color-on-surface-subtle-1);
          font-size: var(--font-size-md);
          margin: 0;
        }
      }
    }
  }
}

body {
  .bbAIHighlightsHeaderActionsButton {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-md);
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    svg {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      fill: var(--color-white);
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;