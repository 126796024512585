.WorkflowItemCard {
  background: linear-gradient(
    180deg,
    var(--color-primary-dark) 0,
    var(--color-primary-dark) 60px,
    rgba(255, 255, 255, 0) 60px,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: var(--border-radius-xl);
  margin: 14px 0;

  &:first-child {
    margin-top: 0;
  }

  &Title {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: var(--color-white);
    font-weight: normal;
    font-size: var(--font-size-lg);
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.08em;
    cursor: default;

    &Text {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 4px;
      background-color: var(--color-white);
      padding: 2px 12px;
      color: var(--color-primary-dark);
      border-radius: 20px;
      margin-top: 6px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-dark);
      }
    }
  }

  &Content {
    background-color: var(--color-surface-4);
    border-radius: var(--border-radius-xl);
    position: relative;
    min-height: 60px;
    padding: 20px;
    color: var(--color-on-surface);
  }

  &Arrow {
    display: flex;
    justify-content: center;
    position: relative;
    top: -5px;
    z-index: 1;

    .WorkflowItemCardAddActionBtn {
      top: 30px;
      position: absolute;
      width: 32px;
      height: 32px;
      padding: 8px;
      box-shadow: var(--shadow-sm-2);
      border-radius: 50%;
      background: var(--color-on-primary);
      z-index: 2;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-on-secondary);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;