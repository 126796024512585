.bbLinkPreviewWrapper {
  display: flex;
  justify-content: center;
  .bbLinkPreview {
    max-height: 460px;
    max-width: 316px;
    padding: 0 12px;

    .bbImagePreviewWrapper {
      position: relative;
    }

    &Footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;
    }

    &Meta {
      padding: 12px;
      line-height: 15px;
      font-size: var(--font-size-xxs);
      background-color: var(--color-grey-100);

      &Url {
        text-transform: uppercase;
        color: var(--color-grey-700);
      }

      &Title {
        color: var(--color-grey-900);
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-bold);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        max-height: 3em;
      }

      &Description {
        color: var(--color-grey-700);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        max-height: 3em;
      }
    }

    .bbImgWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    div[class*="bbPostCard"] {
      height: auto;
      padding: 6px 0;

      div[class*="bbPostInfo"] {
        span {
          font-size: var(--font-size-sm);
        }

        small {
          font-size: var(--font-size-xs);
        }
      }

      div[class*="bbPostHeader"] {
        padding: 0 12px;
      }

      p[class*="bbPostMessage"] {
        padding: 0 12px;
        min-height: auto;
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-bold);
      }
    }

    div[class*="bbPostCardContainer"] {
      min-height: auto;
      padding-bottom: 0;
    }
  }
}

.bbInlineLinkPreviewWrapper {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 12px;
  margin-top: -8px;

  .bbImgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .bbInlineLinkPreview {
    width: 100%;
    position: relative;
    line-height: 15px;
    font-size: var(--font-size-lg);
    background-color: var(--color-grey-100);
    line-height: 21px;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &Url {
      text-transform: uppercase;
      color: var(--color-grey-700);
    }

    &Title {
      line-height: 24px;
      color: var(--color-grey-900);
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-bold);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      max-height: 3em;
    }

    &Description {
      color: var(--color-grey-700);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      max-height: 3em;
    }

    .bbDescriptionWithImage {
      width: 228px;
    }
  }

  .bbCloseIcon {
    width: 16px;
    height: 16px;
    fill: var(--color-grey-600);
    position: absolute;
    right: 16px;
    top: 8px;
    cursor: pointer;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;