.bbImageContainer {
  position: absolute;
  overflow: hidden;

  .bbImageLink {
    flex-shrink: 0;
    flex-direction: row;
    text-align: inherit;
    inset: 0;
    min-width: 0;
    border-radius: inherit;
    flex-basis: auto;
    margin: 0;
    padding-top: 0;
    padding-left: 0;
    align-items: stretch;
    background-color: transparent;
    touch-action: manipulation;
    z-index: 0;
    user-select: none;
    border: 0px solid rgba(0, 0, 0, 0.5);
    list-style: none;
    outline: none;
    text-decoration: none;
    cursor: default;

    .bbNumberOfExcessImages {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      inset: 0;
      pointer-events: none;

      .bbExcessNumberText {
        color: var(--color-white);
        font-weight: var(--font-weight-bold);
        text-align: center;
        font-size: var(--font-size-lg);
      }
    }
  }

  .bbImageFlows {
    overflow: hidden;

    .bbWrapper {
      height: 0;
      overflow: hidden;
      position: relative;

      .bbRoot {
        position: absolute;
        top: 0;

        .bbImage {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .bbPlayButton {
          width: 54px;
          height: 54px;
          position: absolute;
          left: calc(50% - 27px);
          svg {
            width: 54px;
            height: 54px;
            fill: var(--color-white);
          }
        }
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;