.bbTagsManagementView {
  &TabTitle {
    display: flex;
    align-items: center;
    font-weight: var(--font-weight-extrabold);

    &Badge {
      margin-left: 5px;
      background: var(--color-white);
    }
  }

  &Header {
    display: flex;
    padding: 12px 0;
    justify-content: center;

    &Title {
      display: flex;
      align-items: flex-center;
      font-size: var(--headline-h4-size);
      font-weight: var(--font-weight-bold);
      color: var(--color-blue-900);
    }

    &Actions {
      margin-left: auto;
      display: flex;
      gap: 10px;
      max-height: 32px;

      &SearchInput {
        &Wrapper {
          width: 220px;
          box-shadow: var(--shadow-sm-2);
        }

        svg {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          fill: var(--color-on-surface-subtle-2);
        }
      }

      &CreateBtn {
        white-space: nowrap;

        & > svg {
          width: 16px;
          margin-right: 8px;
          fill: var(--color-white);
        }
      }
    }
  }

  &List {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 64px;

    span[class*="bbBadge--"] {
      background-color: var(--color-surface-4);
      box-shadow: none;
    }

    &Loading {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    &Empty {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 400px;
      margin: 12px auto;

      svg {
        margin-bottom: 12px;
      }

      h3 {
        font-size: var(--font-size-2xl);
        color: var(--color-primary-900);
        font-weight: var(--font-weight-bold);
        padding: 0;
        margin: 0 0 6px 0;
      }

      p {
        font-size: var(--font-size-lg);
        color: var(--color-on-surface-subtle-1);
        padding: 0;
        margin: 0;
        margin-bottom: 12px;
      }

      &Button {
        display: flex;
        align-items: center;

        svg {
          width: 16px;
          margin-right: 8px;
          margin-bottom: 0;
          fill: var(--color-white);
        }
      }
    }

    div[class*="bbModalItemCard--"] {
      background: var(--color-white);

      &:hover {
        cursor: pointer;

        div[class*="bbModalItemCardContainerBodyTitle--"] {
          color: var(--color-primary-1);
        }
      }
    }
  }
}

.bbTagsManagementModal {
  height: calc(100% - 40px);

  &Content {
    height: 100%;
    overflow: auto;
  }
  &Body {
    padding: 0 24px 16px;

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      > div {
        margin-bottom: 0 !important;
      }

      :global(.ant-form-item-has-error) {
        div[class*="bbInputWrapper--"] {
          border: 1px solid var(--color-danger-1);
        }
      }

      div[class*="bbDropdownDisabled--"] {
        * {
          pointer-events: none;
        }
      }
    }
  }

  &FormItem {
    [role="alert"] {
      color: var(--color-danger-1);
    }
  }

  &Footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 12px;
    }
  }
}

.bbTagArrowIcon {
  cursor: pointer;
  height: 16px;
  transition: transform 0.3s ease-in-out;

  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-on-secondary);
  }
  &Collapsed {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
    svg {
      position: relative;
      bottom: 2px;
    }
  }
}

.bbSubTag {
  margin-left: 32px;

  &Wrapper {
    max-height: 400px;
    transition: max-height 0.3s ease-in-out;
    overflow: auto;

    &Collapsed {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
    }
  }
}

.bbAllTags {
  &Collapsed {
    font-size: var(--font-size-md);
    white-space: nowrap;
    display: flex;
    gap: 6px;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
    }
  }
}

.bbParentTagSection {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: var(--border-radius-lg);
  background: var(--color-surface-4);

  > div[class*="ant-form-item"] {
    margin-bottom: 0px;
    margin-top: 0px;
    width: 100%;
  }

  &Toggle {
    display: flex;
    gap: 12px;
    align-items: center;

    > span {
      color: var(--color-inverted-surface);
      font-size: 16px;
      font-weight: var(--font-weight-extrabold);
      line-height: 120%;
      letter-spacing: 0.16px;
    }
  }
}

.bbQuickAuto {
  &Section {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: var(--border-radius-md);
    background: var(--color-surface-2);
    box-shadow: var(--shadow-sm-2);

    div[class*="ant-form-item"] {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  &Filters {
    width: 100%;

    &Container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &Alert {
      display: flex;
      gap: 4px;
      padding: 2px 6px;
      align-items: center;
      align-self: stretch;
      color: var(--color-primary-dark);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-bold);
      letter-spacing: 0.12px;
      border-radius: var(--border-radius-sm);
      background: var(--color-primary-150);

      svg {
        width: 12px;
        height: 12px;
        min-width: 12px;
        fill: var(--color-primary-dark);
      }
    }

    &Error {
      color: var(--color-danger-1);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-medium);
      letter-spacing: 0.13px;
    }

    p[class*="bbFilterGroupLabel--"] {
      display: none;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;