.bbPasswordChecker {
  width: 360px;
  display: flex;
  flex-flow: row wrap;

  &Item {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;
    margin-right: 4px;
    line-height: 1.4;
    padding: 2px 6px;
    border-radius: var(--border-radius-sm);
    color: var(--color-green-700);
    font-size: var(--font-size-sm);
    background-color: var(--color-green-100);

    &Tooltip {
      fill: var(--color-green-700);
      height: 12px;
      margin-left: 5px;
    }

    > svg:not(.bbPasswordCheckerItemTooltip) {
      fill: none;
      width: 12px;
      height: 8px;
      margin-right: 5px;
      stroke: var(--color-green-700);
    }

    &Missing {
      color: var(--color-grey-700);
      background-color: var(--color-grey-100);

      .bbPasswordCheckerItemTooltip {
        fill: var(--color-grey-700);
      }
    }

    &Invalid {
      color: var(--color-white);
      background-color: var(--color-red-600);

      .bbPasswordCheckerItemTooltip {
        fill: var(--color-white);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;