@titleColor: #13151b;
@grayColor: #676f85;
@archive-background: #d2ebee;
@alert-background: #e36b001a;
@archive-color: #1d9aaa;

.bbManagedSummary {
  min-width: 250px;

  &Wrapper {
    row-gap: 26px;
  }
  &Card {
    height: 100%;
    &Container {
      display: flex;
      flex-direction: column;
      padding: 5px;
    }
    &Header {
      font-size: var(--font-size-lg);
      display: flex;
      justify-content: space-between;
    }

    &Texts {
      line-height: 110%;
      display: flex;
      flex-direction: column;
    }

    &Title {
      color: @titleColor;
      font-weight: var(--font-weight-bold);
    }
    &Desc {
      color: @grayColor;
    }

    &Icon {
      &tags {
        background-color: #eee4ff;
        svg {
          fill: #7723ff;
        }
      }
      &replies,
      &total_conversations {
        background-color: var(--color-blue-150);
        svg {
          fill: var(--color-blue-850);
        }
      }
      &hidden_conversations {
        background-color: var(--color-danger-100);
        svg {
          fill: var(--color-danger-700);
        }
      }
      &other_workflows {
        background-color: var(--color-surface-1);
        svg {
          fill: var(--color-on-surface-subtle-1);
        }
      }
      &alerts {
        background-color: @alert-background;
        svg {
          fill: var(--color-warning-600);
        }
      }
      &archived_automatically {
        background-color: @archive-background;
        svg {
          fill: @archive-color;
        }
      }
      &Wrapper {
        width: 32px;
        height: 32px;
        border-radius: var(--border-radius-xxl);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    &Footer {
      display: flex;
      gap: 8px;
      margin-top: 14px;
      align-items: center;
      min-height: 44px;
      flex-wrap: wrap;

      h3 {
        font-size: var(--headline-h3-size);
        font-weight: var(--font-weight-bold);
        margin: 0;
      }

      &WithAction {
        justify-content: space-between;
      }
    }

    &Loading {
      text-align: center;
    }

    &Disabled {
      display: flex;
      padding: 3px 8px;
      align-items: center;
      gap: 4px;
      border-radius: var(--border-radius-sm);
      background-color: #f3f3f6;
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
      color: @grayColor;
      margin: auto;

      svg {
        width: 14px;
        height: 14px;
        fill: @grayColor;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;