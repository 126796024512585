.bbGenerateAIButton {
  gap: 8px;
  background: linear-gradient(270deg, #0048e5 0%, #625af5 74.65%, #8a62fc 116.64%);
  border: none !important;

  &Disabled,
  &Disabled:hover {
    background: linear-gradient(
      90deg,
      rgba(0, 109, 217, 0.5) 0%,
      rgba(0, 73, 190, 0.5) 100%
    ) !important;
    cursor: not-allowed;
  }
  svg {
    fill: var(--color-white);
    width: 16px;
    height: 16px;
  }
}

.bbGenerateAIModal {
  &Content {
    display: flex;
    height: 379px;
    border-bottom: 1px solid #e2ecff;
  }

  &Pic {
    width: 371px;
  }

  &Texts {
    padding: 20px;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-medium);
    color: var(--color-black);

    > p {
      margin-bottom: 0px;
    }

    li {
      margin-top: 20px;
    }
  }

  &Agreement {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);

    a {
      text-decoration: underline;
      color: var(--color-primary-700);
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;