.bbAIIcon {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 48px;

  z-index: 1000;

  > img {
    width: 52px;
    height: 52px;
    border: 2px solid var(--color-white);
    border-radius: var(--border-radius-xxl);
    outline: 4px solid #0048e5;
    box-shadow: var(--shadow-lg-2);
  }

  &Indicator {
    display: inline-flex;
    padding: 8px;
    align-items: center;
    gap: 2px;
    border-radius: 5000px;
    background: var(--color-white);
    box-shadow: var(--shadow-md-3);
    position: absolute;
    top: -10px;
    right: -10px;

    > div {
      width: 5px;
      height: 5px;
      background-color: var(--color-primary-700);
      border-radius: var(--border-radius-xxl);
    }
  }

  &WithFilters {
    right: 300px;
  }
}

.bbAIModalWrapper {
  overflow: hidden !important;
  bottom: 6px !important;
  top: unset !important;
  left: unset !important;
  right: 8px !important;
  box-shadow: var(--shadow-lg-3);
  border-radius: var(--border-radius-xl);
  height: calc(100vh - 86px) !important;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &Collapsed {
    z-index: -1 !important;
  }
}

.bbAIModalRoot {
  top: 0 !important;
  width: fit-content !important;
  padding: 0 !important;
  height: 100%;
}

.bbAIModal {
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > .bbAIModalBody {
    padding: 0 !important;
    height: 100%;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
    overflow: auto;
  }

  &Content {
    width: 270px;
    height: 100%;
    max-width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;