.bbUserTagInput {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  background-color: var(--color-white);
  border-radius: var(--border-radius-md);
  padding: 6px;
  width: 260px;
  box-sizing: border-box;
  box-shadow: var(--shadow-md-2);
  opacity: 0;
  transform: scale(0.95);
  animation: growIn 0.2s ease forwards;

  &:before {
    position: absolute;
    top: -10px;
    left: 122px;
    display: block;
    content: "";
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent var(--color-white) transparent;
    transform: rotate(0deg);
  }

  &Input {
    margin-bottom: 6px;
    &Prefix {
      color: var(--color-surface-8);
      margin-right: 4px;
    }
  }
}

@keyframes growIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;