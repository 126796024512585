:global(.ant-upload-list.ant-upload-list-picture-card) {
  display: flex;
  flex-wrap: wrap;
}

:global(.ant-upload.ant-upload-select.ant-upload-select-picture-card) {
  order: 1;
  width: 100%;
  border-radius: var(--border-radius-md);
  border: 1px dashed var(--color-grey-500);

  &:hover {
    border-style: solid;
  }
}

:global(.ant-upload-list-picture-card .ant-upload-list-item-actions) {
  top: 0;
  left: 100%;
}

:global(.ant-upload-list) {
  margin-top: 4px;
}

:global(.ant-upload-list-picture-card-container) {
  width: unset;
  height: unset;
  order: 2;
}

.bbFileUploadContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;

  & > svg {
    width: 16px;
    height: 18px;
  }
}

.bbFileUploadList {
  display: flex;
  list-style: none;
  column-gap: 8px;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0;
}

.bbFileUploadItem {
  position: relative;
  margin-top: 20px;

  :global {
    .ant-upload-list-item-actions,
    .ant-upload-list-item-card-actions-btn {
      opacity: 1;
    }
  }

  &Remove {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    z-index: 1;
    border-radius: var(--border-radius-xxl);
    background-color: var(--color-white);
    box-shadow: var(--shadow-sm-2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 3px;
    border: none;

    & > svg {
      width: 12px;
      height: 12px;
    }
  }

  &Video {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & > svg {
      width: 54px;
      height: 58px;
      fill: white;
    }
  }

  &Document {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: var(--font-size-xs);

    & > svg {
      width: 16px;
      height: 18px;
      fill: var(--color-primary-1);
    }
  }

  &Inner {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 82px;
    height: 82px;
    border-radius: var(--border-radius-md);
    background-color: var(--color-grey-100);

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }
}

.bbFileUploadText {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  color: var(--color-black);
  text-align: left;

  & > span {
    color: var(--color-primary-1);
    margin-left: 5px;
  }
}

.bbUploadFileTagBadge {
  position: absolute;
  z-index: 999;
  left: 6px;
  top: 6px;
  width: 20px;
  height: 20px;
  cursor: help;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;