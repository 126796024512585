.bbEngageWrapper {
  span[class*="bbSectionDividerChip"] {
    background-color: var(--color-blue-850);
    border-radius: 5000px;
    color: var(--color-white);
    text-transform: uppercase;

    svg {
      fill: var(--color-white);
    }
  }

  .bbEngageSection {
    margin-top: 20px;
  }

  .bbReplySelect {
    margin-right: 16px;
    box-shadow: var(--shadow-sm-2);
  }

  .bbUpgradeEngage {
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 10;

    &Modal {
      position: absolute;
      width: 588px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      padding: 40px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      border-radius: var(--border-radius-xl);
      background-color: var(--color-white);
      box-shadow: var(--shadow-lg-2);

      &Title {
        font-size: var(--headline-h5-size);
        font-weight: var(--font-weight-extrabold);
        line-height: 120%;
      }

      &Button {
        border-radius: var(--border-radius-s);
        padding: 8px 12px;
        background-color: #f3f3f6;
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-bold);
        display: flex;
        gap: 8px;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
          fill: #676f85;
        }
      }
    }

    &IconWrapper {
      padding: 20px;
      background-color: var(--color-blue-150);
      border-radius: var(--border-radius-xxl);
      height: 72px;

      svg {
        fill: var(--color-blue-850);
        width: 32px;
        height: 32px;
      }
    }
  }

  .bbEngageHeaderButton {
    color: var(--color-primary-1);
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: var(--font-size-md);
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
      transform: rotate(90deg);
    }
  }

  .bbEngageCommentsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    &Button {
      color: var(--color-primary-1);
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: var(--font-size-md);
      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-1);
        transform: rotate(90deg);
      }
    }
  }
  .bbEngageCommentsWrapper {
    div[class*="bbCommentsGrid--"] {
      margin-top: 0px;
    }
    div[class*="bbCommentsGridItem--"] {
      display: flex;
      justify-content: center;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;