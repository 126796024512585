.bbFormHeader {
  margin-top: 50px;
  margin-bottom: 40px;
  text-align: center;

  .bbContentTitle {
    font-weight: var(--font-weight-bold);
    color: var(--color-blue-900);
    letter-spacing: 0.5px;
  }

  .bbContentPreTitle {
    font-weight: normal;
    color: var(--color-grey-700);

    & + .bbContentTitle {
      margin: 0;
    }
  }

  .bbContentCTA {
    color: var(--color-grey-700);
    font-weight: normal;
  }

  :global(.ant-typography + h5.ant-typography) {
    margin: 0;
  }

  @media (max-width: 768px) {
    .bbContentTitle {
      font-size: var(--font-size-4xl);
    }

    .bbContentCTA {
      font-size: var(--font-size-2xl);
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;