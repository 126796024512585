div.bbReplyWorkflowAction {
  :global {
    div.ant-form-item {
      margin-bottom: 8px;
    }
  }

  &Title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;

    &Icon {
      width: 32px;
      height: 32px;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-white);
      border-radius: var(--border-radius-xxl);

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-1);
      }
    }

    &Text {
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
    }
  }

  &Inline {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    :global {
      div.ant-form-item {
        margin-bottom: 0px;
      }

      div.ant-form-item-label {
        padding: 0 0 4px;

        label {
          font-size: var(--font-size-sm);
          color: var(--color-on-surface);
          font-weight: var(--font-weight-medium);
        }
      }

      .ant-form-item-has-error {
        .bbNumberSelectInputWrapper {
          box-shadow: 0 0 0 1px var(--color-danger-1);
        }
      }
    }

    .bbReplyPrivatelySelectWrapper {
      width: 100%;
    }

    .bbWaitInputContainer {
      color: var(--color-on-surface);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-medium);

      span[class*="bbFormErrorMessage--"] {
        min-width: 300px;
        position: absolute;
      }
    }

    .bbWaitInputWrapper {
      :global {
        .ant-form-item-has-error {
          margin-bottom: 20px;
        }
      }
    }
  }

  ul {
    padding-left: 20px;
  }

  div[class*="bbHelp--"] {
    top: -4px;
    position: relative;

    svg {
      width: 14px;
      height: 14px;
      fill: var(--color-on-surface-subtle-2);
    }
  }

  .bbCheckbox {
    width: 16px;
    height: 16px;
  }

  .bbCheckboxLabel {
    top: -4px;
    margin-left: 8px;
    position: relative;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
  }

  .bbAdvancedOptions {
    :global {
      div.ant-form-item {
        margin-bottom: 4px;
      }
    }

    &Title {
      padding-top: 20px;
      margin-top: 20px;
      margin-bottom: 12px;
      color: var(--color-on-surface);
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
      border-top: 1px solid var(--color-surface-5);
    }
  }

  .bbReplyWorkflowToggleWrapper {
    display: flex;
    justify-content: space-between;

    .bbReplyWorkflowActionTitle {
      margin-bottom: 0;
    }
  }

  .bbWorkflowsDivider {
    margin: 20px 0;
  }

  .bbReplyWorkflowAction {
    margin-top: 20px;
  }
}

.bbReplyPubliclyDisclaimer {
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 20px;
  padding: 2px 6px;
  border-radius: var(--border-radius-sm);
  background: var(--color-surface-2);
  font-size: var(--font-size-sm);
  color: var(--color-primary-dark);

  svg {
    width: 12px;
    height: 12px;
    fill: var(--color-primary-dark);
  }
}

.bbReplyPrivatelyDisclaimer {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 2px 6px;
  border-radius: var(--border-radius-sm);
  background: var(--color-secondary-1);
  font-size: var(--font-size-sm);
  color: var(--color-primary-dark);

  svg {
    width: 12px;
    height: 12px;
    cursor: help;
    fill: var(--color-primary-dark);
  }
}

.bbReplyPubliclySelectLabel {
  color: var(--color-on-surface);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: 4px;
}

.bbReplyPubliclyLimits {
  color: var(--color-on-surface);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-extrabold);

  .bbReplyPubliclyLimit {
    color: var(--color-on-surface);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);

    &WithTooltip {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    svg {
      fill: var(--color-on-surface-subtle-2);
      cursor: help;
    }
  }
}

div.bbReplyPrivatelyLimitScope {
  margin-top: -40px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 12px;
  color: var(--color-on-surface);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: 150%;
  letter-spacing: 0.13px;

  :global {
    div.ant-form-item {
      margin-bottom: 0;
    }
  }

  .bbReplyPrivatelyLimitScopeRadioGroup {
    display: flex;
    gap: 12px;
  }
}

div.bbReplyPrivatelyLimitScopeError {
  margin-top: -10px;
}

.bbReplyPrivatelyLimits {
  color: var(--color-on-surface);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-extrabold);
  border-radius: var(--border-radius-lg);
  background: var(--color-surface-2);
  box-shadow: var(--shadow-sm-2);
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  line-height: 120%;
  letter-spacing: 0.16px;

  .bbReplyPrivatelyLimit {
    color: var(--color-on-surface);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    line-height: 150%;
    letter-spacing: 0.13px;
    margin-bottom: 6px;

    .bbReplyPrivatelyLimitWrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      :global {
        div#action_user_private_reply_interval_help {
          position: absolute;
          left: -400px;
          width: 300px;
        }
      }

      div[class*="bbWaitInputContainer--"] {
        margin-bottom: 0;
      }

      input[class*="bbNumberSelectInput--"] {
        width: 40px;
      }

      button,
      button:disabled {
        background: transparent;
        box-shadow: none;
        border: none;
      }

      button:disabled {
        color: var(--color-primary-1);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;