.bbTaggedCommentsCard {
  height: 100%;
}

.bbTaggedCommentsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 20px 0;
}

.bbTaggedComments {
  padding: 4px;

  &Title {
    font-weight: var(--font-weight-bold);
    margin-bottom: 3px;
    font-size: var(--font-size-2xl);
  }
  
  &Subtitle {
    margin-bottom: 12px;
    color: var(--color-grey-500);
    font-size: var(--font-size-lg);
  }

  &Wrapper {
    display: flex;
    align-items: center;
    margin-top: 6px;
  }

  &Header {
    display: flex;
    justify-content: space-between;

    &Icon {
      top: 4px;
      right: 0;
      width: 32px;
      height: 32px;
      display: flex;
      position: relative;
      border-radius: 32px;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &Icon-neutral {
      background-color: var(--color-blue-100);

      svg {
        fill: var(--color-blue-850);
      }
    }

    &Icon-danger {
      background-color: var(--color-red-100);

      svg {
        fill: var(--color-red-700);
      }
    }

    &Value {
      font-weight: var(--font-weight-bold);
      font-size: var(--headline-h3-size);
    }

    &Percentage {
      font-size: var(--font-size-2xl);
      margin-left: 4px;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;